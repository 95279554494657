/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:1dc388e8-071d-4fdb-97eb-5eb462a404d9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_MASJKmTxA",
    "aws_user_pools_web_client_id": "21m9cqspe3luql75453emsmuu0",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://gnaoyta52fehlfldt2pc5vilq4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_user_files_s3_bucket": "questtrain-data163311-main",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
