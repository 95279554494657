import React, { useEffect } from 'react';
import CardMedia from '@material-ui/core/CardMedia';

import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RouteHistoryProps } from 'RouterProps';
import Link from '@material-ui/core/Link';
import logoWhite from 'assets/QuestTrainLogoWhite.png';
import { useGeneralStyles } from 'GeneralStyle';
import { analyticsCapture } from 'Analytics';
import Button from '@material-ui/core/Button';
import { Engine, EngineSlider } from 'Routes/EngineRoute';
import { useQuery } from 'Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingBottom: '5%',
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      color: theme.palette.common.white,
    },
    subtitle: {
      textAlign: 'center',
      color: theme.palette.common.white,
    },
    centered: {
      textAlign: 'center',
      alignItems: 'center',
      justify: 'center',
    },
    responsiveBar: {
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
    },
  })
);

interface LandingPageProps {
  username?: string;
}

export function LandingPage(props: LandingPageProps & RouteHistoryProps) {
  const { username, history } = props;

  const query = useQuery();
  const rawTab = query.get('tab');

  let tab = EngineSlider.Find;
  switch (rawTab) {
    case EngineSlider.Add:
      tab = EngineSlider.Add;
      break;
    default:
      break;
  }

  useEffect(() => {
    analyticsCapture('View Homepage');
  }, []);

  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  return (
    <div>
      <Grid container spacing={2} justify="center" className={classes.centered}>
        <CardMedia className={classes.root}>
          <Grid
            container
            justify="center"
            spacing={2}
            direction="column"
            className={generalClasses.centeredWithTopMargin}
          >
            <Grid item>
              <img src={logoWhite} alt="logoWhite" style={{ height: 64 }} />
            </Grid>
            <Grid item>
              <Typography className={classes.title} variant="h3">
                QuestTrain
              </Typography>
            </Grid>
            {username ? (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ backgroundColor: 'white', borderColor: 'white' }}
                  onClick={() => {
                    history.push('/my/profile');
                  }}
                >
                  My Account
                </Button>
              </Grid>
            ) : undefined}
          </Grid>
        </CardMedia>
      </Grid>
      <br />
      <br />
      <Grid
        container
        spacing={2}
        justify="center"
        direction="column"
        alignContent="center"
        alignItems="center"
        className={classes.centered}
      >
        <Grid item className={classes.responsiveBar}>
          <Typography variant="subtitle1">
            QuestTrain is a network for evidence-based learning. Ask questions and
            request data, or fulfill other requests with clean datasets. Let&apos;s make
            critical thinking cool.&nbsp;
            <Link
              href="https://blog.questtrain.com/background/2021/03/10/why-create-questtrain.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Link>
            &nbsp;or explore below ...
          </Typography>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Engine
            history={history}
            trainCarInputs={[]}
            defaultTopicNames={[]}
            defaultSlider={tab}
            username={username}
          />
        </Grid>
        <Grid item>
          <br />
        </Grid>
      </Grid>
    </div>
  );
}
