/* eslint-disable react/jsx-curly-newline */
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { RouteHistoryProps } from 'RouterProps';
import {
  CarType,
  SearchQuestionResult,
  SearchQuestionResultQuestTrain,
  SearchResult,
  useRandomSampleManagedLazyQuery,
  useListQuestTrainsManagedLazyQuery,
  ElasticSearchIndexEntityType,
  RandomSampleQueryVariables,
  TrainCarInput,
} from 'data/types';
import { statusGateway } from 'Reportable';
import { useGeneralStyles } from 'GeneralStyle';
import { QuestionCard, QuestTrainView } from 'ViewQuestion';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { analyticsCapture } from 'Analytics';
import { removeNullAndUndefined } from 'Utils';
import { DataCard } from 'DataCard';
import FilterList from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Divider from '@material-ui/core/Divider';
import { TopicFilter } from 'SearchFilter';

export function BrowseEngineRoute(props: RouteHistoryProps) {
  const { history } = props;

  const classes = useGeneralStyles();

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.centeredWithTopMargin}
    >
      <Grid item>
        <BrowseEngine trainCarInputs={[]} history={history} />
      </Grid>
    </Grid>
  );
}

interface QuestTrainResultsProps {
  questTrains: Array<SearchQuestionResultQuestTrain>;
}

export function QuestTrainResults(props: QuestTrainResultsProps & RouteHistoryProps) {
  const { questTrains, history } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {questTrains.map((questTrain) => (
        <Grid item key={`questTrain-${questTrain.id}`} style={{ maxWidth: '100%' }}>
          <Card elevation={2}>
            <CardContent>
              <QuestTrainView
                id={questTrain.id}
                cars={questTrain.questions}
                carSpecs={questTrain.carSpecs}
                history={history}
                defaultSummary
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export function QuestTrainShuffle(props: RouteHistoryProps) {
  const { history } = props;

  const limit = 10;

  const [
    listQuestTrains,
    { data, error, loading },
  ] = useListQuestTrainsManagedLazyQuery();

  useEffect(() => {
    const listInput = { variables: { limit } };
    listQuestTrains(listInput);
  }, [listQuestTrains]);

  const questTrains = data?.listQuestTrains?.items;

  useEffect(() => {
    if (questTrains?.length === 0) {
      const listInput = { variables: { limit } };
      listQuestTrains(listInput);
    }
  }, [questTrains, listQuestTrains]);

  function onShuffle() {
    const nextToken = data?.listQuestTrains?.nextToken;
    const listInput = { variables: { limit, nextToken } };
    listQuestTrains(listInput);
    analyticsCapture('QuestTrain Shuffle');
  }

  return (
    statusGateway({ error, loading }) || (
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
      >
        {questTrains
          ? questTrains.map((questTrain) =>
              questTrain?.questions?.items ? (
                <Grid
                  item
                  key={`questTrain-${questTrain.id}`}
                  style={{ maxWidth: '100%' }}
                >
                  <Card elevation={2}>
                    <CardContent>
                      <QuestTrainView
                        id={questTrain.id}
                        cars={removeNullAndUndefined(
                          questTrain.questions.items.map((edge) =>
                            edge?.question
                              ? {
                                  id: edge.question.id,
                                  text: edge.question.text,
                                  ownerUsername: edge.question.ownerEdge.user.username,
                                  updatedAt: edge.question.updatedAt,
                                }
                              : undefined
                          )
                        )}
                        carSpecs={questTrain.carSpecs || undefined}
                        history={history}
                        defaultSummary
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ) : undefined
            )
          : undefined}
        <Grid item>
          <Tooltip title="Sample some more QuestTrains">
            <Button variant="contained" color="primary" onClick={() => onShuffle()}>
              See More
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    )
  );
}

interface QuestionResultsProps {
  questions: Array<SearchQuestionResult>;
  trainCarInputs: Array<TrainCarInput>;
}

export function QuestionResults(props: QuestionResultsProps & RouteHistoryProps) {
  const { questions, trainCarInputs, history } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {questions.map((question) => (
        <Grid item style={{ width: '100%' }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item key={`result-${question.id}`}>
              <QuestionCard
                key={question.id}
                question={question}
                trainCarInputs={trainCarInputs}
                history={history}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      {questions.length === 0 ? 'No questions found.' : undefined}
    </Grid>
  );
}
interface EntityResultsProps {
  entities: Array<SearchResult>;
  trainCarInputs: Array<TrainCarInput>;
}

export function EntityResults(props: EntityResultsProps & RouteHistoryProps) {
  const { entities, trainCarInputs, history } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {entities.map((entity) => (
        <Grid item style={{ width: '100%' }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            {entity.carType === CarType.QuestionType && entity.question ? (
              <Grid item key={`result-${entity.question.id}`}>
                <QuestionCard
                  key={entity.question.id}
                  question={entity.question}
                  trainCarInputs={trainCarInputs}
                  history={history}
                />
              </Grid>
            ) : undefined}
            {entity.carType === CarType.DataType && entity.data ? (
              <Grid item key={`result-${entity.data.id}`}>
                <DataCard
                  data={entity.data}
                  trainCarInputs={trainCarInputs}
                  history={history}
                  key={entity.data.id}
                />
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>
      ))}
      {entities.length === 0 ? <Grid item>No results found.</Grid> : undefined}
    </Grid>
  );
}

interface QuestionShuffleProps {
  trainCarInputs: Array<TrainCarInput>;
}

function QuestionShuffle(props: QuestionShuffleProps & RouteHistoryProps) {
  const { trainCarInputs, history } = props;

  const classes = useGeneralStyles();

  const [filterShow, setFilterShow] = useState(true);
  const [filterChecked, setFilterChecked] = useState({
    [ElasticSearchIndexEntityType.Question]: true,
    [ElasticSearchIndexEntityType.Data]: true,
    [ElasticSearchIndexEntityType.DataRequest]: true,
  });
  const [topicNames, setTopicNames] = useState<Array<string>>([]);

  const limit = 10;

  const [randomSample, { data, error, loading }] = useRandomSampleManagedLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    async function fetchData() {
      const filter: Array<ElasticSearchIndexEntityType> = [];
      Object.entries(filterChecked).forEach((entry: any) => {
        if (entry[1]) {
          filter.push(entry[0]);
        }
      });
      let randomSampleInput: RandomSampleQueryVariables = {
        limit,
        filter: { topicNames },
        from: undefined,
      };
      if (filter.length === 0) {
        return;
      }
      if (filter.length < 3) {
        randomSampleInput = {
          ...randomSampleInput,
          filter: { ...randomSampleInput.filter, entityTypes: filter },
        };
      }
      await randomSample({ variables: randomSampleInput });
    }

    fetchData();
  }, [filterChecked, topicNames, randomSample]);

  const entities = data?.randomSample.results;
  const from = data?.randomSample.from;

  async function onShuffle() {
    const filter: Array<ElasticSearchIndexEntityType> = [];
    Object.entries(filterChecked).forEach((entry: any) => {
      if (entry[1]) {
        filter.push(entry[0]);
      }
    });
    const randomSampleInput: RandomSampleQueryVariables = {
      limit,
      filter: { topicNames },
      from,
    };
    if (filter.length === 0) {
      return;
    }
    if (filter.length < 3) {
      randomSampleInput.filter = { ...randomSampleInput.filter, entityTypes: filter };
    }
    analyticsCapture('Question Shuffle');
    await randomSample({ variables: randomSampleInput });
  }

  function handleCheck(name: ElasticSearchIndexEntityType, checked: boolean) {
    setFilterChecked({ ...filterChecked, [name]: checked });
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item>
        <Tooltip title="Filter results">
          <ToggleButton
            selected={filterShow}
            onClick={() => setFilterShow(!filterShow)}
          >
            <FilterList />
          </ToggleButton>
        </Tooltip>
      </Grid>
      {filterShow ? (
        <Grid item style={{ width: '90%' }}>
          <TopicFilter
            selectedTopicNames={topicNames}
            setSelectedTopicNames={setTopicNames}
          />
        </Grid>
      ) : undefined}
      {filterShow ? (
        <Grid item>
          <FormControl>
            <FormGroup row>
              <Tooltip title="Include questions">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterChecked.Question}
                      onChange={(event) =>
                        handleCheck(
                          ElasticSearchIndexEntityType.Question,
                          event.target.checked
                        )
                      }
                      name={ElasticSearchIndexEntityType.Question}
                    />
                  }
                  label={ElasticSearchIndexEntityType.Question}
                />
              </Tooltip>
              <Tooltip title="Include data">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterChecked.Data}
                      onChange={(event) =>
                        handleCheck(
                          ElasticSearchIndexEntityType.Data,
                          event.target.checked
                        )
                      }
                      name={ElasticSearchIndexEntityType.Data}
                    />
                  }
                  label={ElasticSearchIndexEntityType.Data}
                />
              </Tooltip>
              <Tooltip title="Include data requests">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterChecked.DataRequest}
                      onChange={(event) =>
                        handleCheck(
                          ElasticSearchIndexEntityType.DataRequest,
                          event.target.checked
                        )
                      }
                      name={ElasticSearchIndexEntityType.DataRequest}
                    />
                  }
                  label={ElasticSearchIndexEntityType.DataRequest}
                />
              </Tooltip>
            </FormGroup>
          </FormControl>
        </Grid>
      ) : undefined}
      <Grid item style={{ width: '25%' }}>
        <Divider className={classes.divider} />
      </Grid>
      {statusGateway({ error, loading }) ? (
        <Grid item>{statusGateway({ error, loading })}</Grid>
      ) : (
        undefined ||
        (entities ? (
          <Grid item style={{ width: '100%' }}>
            <EntityResults
              entities={entities}
              trainCarInputs={trainCarInputs}
              history={history}
            />
          </Grid>
        ) : undefined)
      )}
      <Grid item hidden={loading || !!error || !from}>
        <Tooltip title="Sample some more">
          <Button variant="contained" color="primary" onClick={() => onShuffle()}>
            See More
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

interface BrowseProps {
  trainCarInputs: Array<TrainCarInput>;
}

export function BrowseEngine(props: BrowseProps & RouteHistoryProps) {
  const { trainCarInputs, history } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item style={{ width: '100%' }}>
        <QuestionShuffle trainCarInputs={trainCarInputs} history={history} />
      </Grid>
    </Grid>
  );
}
