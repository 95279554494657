import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { useGetTopTopicsQuery } from 'data/types';
import React from 'react';

interface TopicFilterProps {
  selectedTopicNames: Array<string>;
  setSelectedTopicNames: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export function TopicFilter(props: TopicFilterProps) {
  const { selectedTopicNames, setSelectedTopicNames } = props;

  const { data: dataTopics } = useGetTopTopicsQuery({ variables: { limit: 12 } });

  const displayedTopics = dataTopics?.getTopTopics;

  function handleClick(topicName: string) {
    let updatedSelectedTopicNames = [...selectedTopicNames];
    const foundIndex = updatedSelectedTopicNames.indexOf(topicName);
    if (foundIndex > -1) {
      updatedSelectedTopicNames.splice(foundIndex, 1);
    } else {
      updatedSelectedTopicNames = [...updatedSelectedTopicNames, topicName];
    }
    setSelectedTopicNames(updatedSelectedTopicNames);
  }

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      {displayedTopics?.length ? <Grid item>Popular Topics:</Grid> : undefined}
      {displayedTopics?.map((topic) => (
        <Grid item>
          <Tooltip title={`View results related to '${topic.name}'`}>
            <Chip
              label={topic.name}
              clickable
              variant={
                selectedTopicNames?.includes(topic.name) ? 'default' : 'outlined'
              }
              onClick={() => handleClick(topic.name)}
              color="primary"
            />
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}
