import posthog from 'posthog-js';

const isLocal = window.location.href.includes('localhost:');

if (!isLocal) {
  posthog.init('Me-DWX5oXqZ5yQQQMvGPk0xjasnVcQrvzO5q3KI-QM0', {
    api_host: 'https://questtrain-analytics.herokuapp.com',
    disable_cookie: true,
  });
}

export function analyticsCapture(
  event_name: string,
  properties?: posthog.Properties | undefined
) {
  if (!isLocal) {
    posthog.capture(event_name, properties);
  }
}
