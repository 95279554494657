import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function capitalizeFirst(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function useFormFields<T>(initialState: T) {
  const [fields, setValues] = useState(initialState);

  const response = {
    fields,
    setFields: (event: React.FocusEvent<HTMLInputElement>) => {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
    setFieldsNamed: (name: string, value?: string) => {
      setValues({
        ...fields,
        [name]: value,
      });
    },
  };

  return response;
}

export function removeNullAndUndefined<T>(arr: Array<T | null | undefined>) {
  const filtered: Array<T> = [];
  arr.forEach((item) => {
    if (item) {
      filtered.push(item);
    }
  });
  return filtered;
}
