import React, { useState, useEffect } from 'react';
import Star from '@material-ui/icons/Star';
import StarOutline from '@material-ui/icons/StarOutline';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { LinkResult, UpdateDataFollowerMutationVariables } from 'data/types';

interface FollowerToggleProps {
  followedID: string;
  defaultFollows?: boolean;
  updateFollower: (props: { variables: UpdateDataFollowerMutationVariables }) => any;
  linkResult?: LinkResult;
}

export function FollowerToggle(props: FollowerToggleProps) {
  const { followedID, defaultFollows, updateFollower, linkResult } = props;

  const [starFilled, setStarFilled] = useState(false);

  useEffect(() => {
    if (linkResult) {
      setStarFilled(linkResult.afterLinked);
    }
  }, [linkResult]);

  useEffect(() => {
    setStarFilled(!!defaultFollows);
  }, [defaultFollows]);

  function onStar() {
    const newStarStatus = !starFilled;
    updateFollower({
      variables: { followedID, linked: newStarStatus },
    });
    setStarFilled(newStarStatus);
  }

  return (
    <Grid item>
      <Tooltip title={starFilled ? 'Following' : 'Follow'}>
        <IconButton onClick={() => onStar()}>
          {starFilled ? <Star color="primary" /> : <StarOutline />}
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
