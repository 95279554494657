import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import React, { useEffect, useState } from 'react';
import { RouteHistoryProps } from 'RouterProps';
import { Storage } from 'aws-amplify';
import LinearProgress from '@material-ui/core/LinearProgress';
import { v4 } from 'uuid';
import Button from '@material-ui/core/Button';
import {
  CarType,
  ColumnType,
  CreateDataManagedInput,
  ProcessTrainCarsMutationVariables,
  ReferenceInput,
  TrainCarInput,
  useProcessTrainCarsMutation,
  useUpdateDataManagedMutation,
  GetDataManagedResult,
} from 'data/types';
import { statusGateway } from 'Reportable';
import { useGeneralStyles } from 'GeneralStyle';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { EditTopics } from 'Topics';
import { normalizeQuery } from './EngineRoute';

export function AddDataRoute(props: RouteHistoryProps) {
  const { history } = props;

  const classes = useGeneralStyles();

  return (
    <div className={classes.centeredWithTopMargin}>
      <AddData trainCarInputs={[]} defaultTopicNames={[]} history={history} />
    </div>
  );
}

interface SpecifySchemaProps {
  trainCarInputs: Array<TrainCarInput>;
  defaultTopicNames: Array<string>;
  preloadedData?: GetDataManagedResult;
}

enum ExampleDataCell {
  STRING = 'example',
  NUMBER = '7',
  BOOLEAN = 'true',
}

function SpecifySchema(props: SpecifySchemaProps & RouteHistoryProps) {
  const { trainCarInputs, defaultTopicNames = [], preloadedData, history } = props;

  const [header, setHeader] = useState<
    Array<{ name?: string; columnType?: ColumnType; exampleValue?: string }>
  >(
    preloadedData?.header || [
      { columnType: ColumnType.String, exampleValue: ExampleDataCell.STRING },
    ]
  );
  const [dataName, setDataName] = useState<string | undefined>(preloadedData?.name);
  const [referenceName, setReferenceName] = useState<string | undefined>(
    preloadedData?.references?.length ? preloadedData?.references[0].name : undefined
  );
  const [referenceLink, setReferenceLink] = useState<string | undefined>(
    preloadedData?.references?.length
      ? preloadedData?.references[0].link || undefined
      : undefined
  );
  const [notes, setNotes] = useState<string | undefined>(
    preloadedData?.notes || undefined
  );
  const [topicNames, setTopicNames] = useState<Array<string>>(
    preloadedData?.topicNames || defaultTopicNames
  );

  const [progressLoaded, setProgressLoaded] = useState(0);
  const [progressTotal, setProgressTotal] = useState(100);
  const [uploadError, setUploadError] = useState<Error>();
  const [uploadedKey, setUploadedKey] = useState<{
    prefix: string;
    basename: string;
  }>();
  const [progressStatus, setProgressStatus] = useState<string>();
  const level = 'public';

  const exampleHeaderCsv = header.map((col) => col.name).join(',');
  const exampleRowCsv = header.map((col) => col.exampleValue).join(',');
  const exampleCsv = [exampleHeaderCsv, exampleRowCsv].join('\n');

  const [processTrainCars, { data, error, loading }] = useProcessTrainCarsMutation();
  const [
    updateData,
    { data: dataUpdate, error: errorUpdate, loading: loadingUpdate },
  ] = useUpdateDataManagedMutation();

  async function onSubmitData() {
    const timestamp = new Date().toISOString();
    const fileID = v4().substring(0, 6);
    const filename = `SnippetRequest/${timestamp}-${fileID}-${dataName}.csv`;
    setProgressStatus('Uploading ...');
    try {
      const uploadResult: any = await Storage.put(filename, exampleCsv, {
        progressCallback(progress: any) {
          setProgressTotal(progress.total);
          setProgressLoaded(progress.loaded);
        },
        level,
      });

      const basename = uploadResult?.key;

      if (basename) {
        setUploadedKey({ basename, prefix: level });
      }
    } catch (error) {
      setUploadError(error);
      setProgressStatus('Upload error');
      return undefined;
    }

    return true;
  }

  useEffect(() => {
    if (
      uploadedKey &&
      progressLoaded === progressTotal &&
      !loading &&
      !loadingUpdate &&
      !data &&
      !dataUpdate &&
      !error &&
      !errorUpdate &&
      dataName
    ) {
      setProgressStatus('Analyzing ...');
      let dataInput: CreateDataManagedInput = {
        ...uploadedKey,
        name: dataName,
        isRequest: true,
        topicNames,
      };
      if (notes) {
        dataInput = { ...dataInput, notes };
      }
      if (referenceName) {
        let reference: ReferenceInput = { name: referenceName };
        if (referenceLink) {
          reference = { ...reference, link: referenceLink };
        }
        dataInput = { ...dataInput, references: [reference] };
      }

      if (preloadedData) {
        updateData({ variables: { input: { ...dataInput, id: preloadedData.id } } });
      } else {
        const processTrainCarInput: ProcessTrainCarsMutationVariables = {
          input: {
            trainCarInputs: [
              ...trainCarInputs,
              {
                carType: CarType.DataType,
                createNew: {
                  dataInput,
                },
              },
            ],
          },
        };

        processTrainCars({
          variables: processTrainCarInput,
        });
      }
    }
  }, [
    data,
    dataName,
    error,
    loading,
    progressLoaded,
    progressTotal,
    referenceLink,
    referenceName,
    uploadedKey,
    notes,
    trainCarInputs,
    processTrainCars,
    topicNames,
    preloadedData,
    updateData,
    dataUpdate,
    errorUpdate,
    loadingUpdate,
  ]);

  useEffect(() => {
    if (data?.processTrainCars.length) {
      const numCars = data.processTrainCars.length;
      setProgressStatus(undefined);
      history.push(`/data/${data.processTrainCars[numCars - 1].id}`);
    } else if (dataUpdate?.updateDataManaged) {
      setProgressStatus(undefined);
      history.push(`/data/${dataUpdate.updateDataManaged.id}`);
    }
  }, [data, dataUpdate, history]);

  function onChangeName(name: string, index: number) {
    const nameWithoutCommas = name.replaceAll(',', '');
    setHeader([
      ...header.slice(0, index),
      { ...header[index], name: nameWithoutCommas },
      ...header.slice(index + 1, header.length),
    ]);
  }

  function onChangeType(columnType: ColumnType, index: number) {
    setHeader([
      ...header.slice(0, index),
      { ...header[index], columnType, exampleValue: ExampleDataCell[columnType] },
      ...header.slice(index + 1, header.length),
    ]);
  }

  function onChangeExample(example: string, index: number) {
    const exampleWithoutCommas = example.replaceAll(',', '');
    setHeader([
      ...header.slice(0, index),
      { ...header[index], exampleValue: exampleWithoutCommas },
      ...header.slice(index + 1, header.length),
    ]);
  }

  let isSubmitReady = header.length > 0 && dataName;
  header.forEach((col) => {
    if (!col.name || !col.columnType || !col.exampleValue) {
      isSubmitReady = false;
    }
  });
  if (data || error || loading || progressStatus) {
    isSubmitReady = false;
  }

  const items = header.map((col, index) => (
    <Grid item>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <TextField
            value={col.name}
            onChange={(event: any) => onChangeName(event.target.value, index)}
            label={`Column #${index + 1} Name`}
            required
            error={!col.name}
          />
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              value={col.columnType}
              onChange={(event: any) => onChangeType(event.target.value, index)}
            >
              <MenuItem value={ColumnType.String}>Text</MenuItem>
              <MenuItem value={ColumnType.Number}>Number</MenuItem>
              <MenuItem value={ColumnType.Boolean}>True/False</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            value={col.exampleValue}
            onChange={(event: any) => onChangeExample(event.target.value, index)}
            label="Example Value"
            required
            error={!col.exampleValue}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Delete column" placement="right">
            <IconButton
              onClick={() => {
                const tempHeader = [...header];
                tempHeader.splice(index, 1);
                setHeader(tempHeader);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      {items}
      <Grid item>
        <Tooltip title="Add column">
          <Button
            variant="outlined"
            color="primary"
            onClick={
              () =>
                setHeader([
                  ...header,
                  {
                    columnType: ColumnType.String,
                    exampleValue: ExampleDataCell.STRING,
                  },
                ])
              // eslint-disable-next-line react/jsx-curly-newline
            }
          >
            Add Column
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <TextField
          label="Data Name"
          value={dataName}
          onChange={(event) => setDataName(event.target.value)}
          required
          error={!dataName}
        />
      </Grid>
      <Grid item hidden={!isSubmitReady}>
        <EditTopics topicNames={topicNames} setTopicNames={setTopicNames} />
      </Grid>
      {isSubmitReady ? (
        <Grid item>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-end"
            alignContent="center"
          >
            <Grid item>
              <TextField
                label="Reference Name (Optional)"
                value={referenceName}
                onChange={(event) => setReferenceName(event.target.value)}
              />
            </Grid>
            <Grid item>
              <Tooltip
                title="(Optional) Provide a reference name, if you know where to find primary source data."
                placement="right"
              >
                <Info color="secondary" />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ) : undefined}
      {isSubmitReady ? (
        <Grid item>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-end"
            alignContent="center"
          >
            <Grid item>
              <TextField
                label="Reference Link (Optional)"
                value={referenceLink}
                onChange={(event) => setReferenceLink(event.target.value)}
              />
            </Grid>
            <Grid item>
              <Tooltip
                title="(Optional) Provide a reference URL, if you know where to find primary source data."
                placement="right"
              >
                <Info color="secondary" />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ) : undefined}

      <Grid item hidden={!isSubmitReady}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <TextField
              label="Notes (Optional)"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              multiline
              variant="outlined"
              rows={4}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title="(Optional) Any additional notes you'd like to provide on the data?"
              placement="right"
            >
              <Info color="secondary" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSubmitData()}
          disabled={!isSubmitReady}
        >
          Submit
        </Button>
      </Grid>
      <Grid item style={{ width: '80%' }}>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={(100 * progressLoaded) / progressTotal}
          title="Progress"
          hidden={progressLoaded === 0}
        />
      </Grid>
      {progressStatus ? <Grid item>{progressStatus}</Grid> : undefined}
      {uploadError ? (
        <Grid item>{statusGateway({ error: uploadError })}</Grid>
      ) : undefined}
      {error || loading ? (
        <Grid item>{statusGateway({ error, loading })}</Grid>
      ) : undefined}
      {errorUpdate || loadingUpdate ? (
        <Grid item>
          {statusGateway({ error: errorUpdate, loading: loadingUpdate })}
        </Grid>
      ) : undefined}
    </Grid>
  );
}

enum RequestDataSlider {
  Specify = 'Make a template',
  HaveExample = 'I have an example file',
}

interface RequestDataProps {
  trainCarInputs: Array<TrainCarInput>;
  defaultTopicNames: Array<string>;
  preloadedData?: GetDataManagedResult;
}

export function RequestData(props: RequestDataProps & RouteHistoryProps) {
  const { trainCarInputs, defaultTopicNames, preloadedData, history } = props;

  const [slider, setSlider] = useState(RequestDataSlider.Specify);

  function changeSlider(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: RequestDataSlider
  ) {
    if (value !== null) {
      setSlider(value);
    }
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="subtitle2">What should the data look like?</Typography>
      </Grid>
      <Grid item>
        <ToggleButtonGroup value={slider} exclusive onChange={changeSlider}>
          <ToggleButton value={RequestDataSlider.Specify}>
            <Tooltip title="I want to make a template for data">
              <div>{RequestDataSlider.Specify}</div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={RequestDataSlider.HaveExample}>
            <Tooltip title="I have a small example file">
              <div>{RequestDataSlider.HaveExample}</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        {slider === RequestDataSlider.Specify ? (
          <SpecifySchema
            trainCarInputs={trainCarInputs}
            defaultTopicNames={defaultTopicNames}
            preloadedData={preloadedData}
            history={history}
          />
        ) : undefined}
        {slider === RequestDataSlider.HaveExample ? (
          <AddDataFile
            trainCarInputs={trainCarInputs}
            addDataType={AddDataSlider.Request}
            defaultTopicNames={defaultTopicNames}
            preloadedData={preloadedData}
            history={history}
          />
        ) : undefined}
      </Grid>
    </Grid>
  );
}

interface AddDataFileProps {
  trainCarInputs: Array<TrainCarInput>;
  addDataType: AddDataSlider;
  defaultTopicNames: Array<string>;
  preloadedData?: GetDataManagedResult;
}

export function AddDataFile(props: AddDataFileProps & RouteHistoryProps) {
  const {
    trainCarInputs,
    addDataType,
    defaultTopicNames = [],
    preloadedData,
    history,
  } = props;

  const level = 'public';
  const [stagedFile, setStagedFile] = useState<File>();
  const [dataName, setDataName] = useState<string | undefined>(preloadedData?.name);
  const [referenceName, setReferenceName] = useState<string | undefined>(
    preloadedData?.references?.length ? preloadedData?.references[0].name : undefined
  );
  const [referenceLink, setReferenceLink] = useState<string | undefined>(
    preloadedData?.references?.length
      ? preloadedData?.references[0].link || undefined
      : undefined
  );
  const [notes, setNotes] = useState<string | undefined>(
    preloadedData?.notes || undefined
  );
  const [topicNames, setTopicNames] = useState<Array<string>>(
    preloadedData?.topicNames || defaultTopicNames
  );

  const [progressLoaded, setProgressLoaded] = useState(0);
  const [progressTotal, setProgressTotal] = useState(100);
  const [uploadError, setUploadError] = useState<Error>();
  const [uploadedKey, setUploadedKey] = useState<{
    prefix: string;
    basename: string;
  }>();

  const [progressStatus, setProgressStatus] = useState<string>();
  const [fileError, setFileError] = useState<Error>();

  const [processTrainCars, { data, error, loading }] = useProcessTrainCarsMutation();
  const [
    updateData,
    { data: dataUpdate, error: errorUpdate, loading: loadingUpdate },
  ] = useUpdateDataManagedMutation();

  async function onChooseFile(files: FileList | null) {
    const firstFile = files?.item(0);
    if (!firstFile) {
      setStagedFile(undefined);
      return undefined;
    }

    const sizeLimit = 10000000;
    if (firstFile.size > sizeLimit) {
      setFileError(
        new Error(
          `File is too large (${
            firstFile.size / 1000000
          } MB). Please choose a file less than ${sizeLimit / 1000000} MB.`
        )
      );
      return undefined;
    }

    setFileError(undefined);
    setStagedFile(firstFile);
    setDataName(normalizeDataName(firstFile.name));
    return firstFile;
  }

  useEffect(() => {
    if (
      stagedFile &&
      uploadedKey &&
      progressLoaded === progressTotal &&
      !loading &&
      !loadingUpdate &&
      !data &&
      !dataUpdate &&
      !error &&
      !errorUpdate &&
      dataName
    ) {
      setProgressStatus('Analyzing ...');
      let dataInput: CreateDataManagedInput = {
        ...uploadedKey,
        name: dataName,
        isRequest: addDataType === AddDataSlider.Request,
        topicNames,
      };
      if (notes) {
        dataInput = { ...dataInput, notes };
      }
      if (referenceName) {
        let reference: ReferenceInput = { name: referenceName };
        if (referenceLink) {
          reference = { ...reference, link: referenceLink };
        }
        dataInput = { ...dataInput, references: [reference] };
      }

      if (preloadedData) {
        updateData({ variables: { input: { ...dataInput, id: preloadedData.id } } });
      } else {
        const processTrainCarInput: ProcessTrainCarsMutationVariables = {
          input: {
            trainCarInputs: [
              ...trainCarInputs,
              {
                carType: CarType.DataType,
                createNew: {
                  dataInput,
                },
              },
            ],
          },
        };

        processTrainCars({
          variables: processTrainCarInput,
        });
      }
    }
  }, [
    data,
    dataName,
    error,
    loading,
    progressLoaded,
    progressTotal,
    stagedFile,
    uploadedKey,
    referenceName,
    referenceLink,
    addDataType,
    notes,
    trainCarInputs,
    processTrainCars,
    topicNames,
    preloadedData,
    updateData,
    dataUpdate,
    errorUpdate,
    loadingUpdate,
  ]);

  useEffect(() => {
    if (data?.processTrainCars.length) {
      const numCars = data.processTrainCars.length;
      setProgressStatus(undefined);
      history.push(`/data/${data.processTrainCars[numCars - 1].id}`);
    } else if (dataUpdate?.updateDataManaged) {
      setProgressStatus(undefined);
      history.push(`/data/${dataUpdate.updateDataManaged.id}`);
    }
  }, [data, dataUpdate, history]);

  async function onSubmitData() {
    if (!stagedFile) {
      return undefined;
    }

    if (stagedFile.type === 'text/csv') {
      const timestamp = new Date().toISOString();
      const fileID = v4().substring(0, 6);
      const filename = `${
        addDataType === AddDataSlider.HaveFile ? 'Full' : 'SnippetRequest'
      }/${timestamp}-${fileID}-${stagedFile.name}`;
      setProgressStatus('Uploading ...');
      try {
        const uploadResult: any = await Storage.put(filename, stagedFile, {
          progressCallback(progress: any) {
            setProgressTotal(progress.total);
            setProgressLoaded(progress.loaded);
          },
          level,
        });

        const basename = uploadResult?.key;

        if (basename) {
          setUploadedKey({ basename, prefix: level });
        }
      } catch (error) {
        setUploadError(error);
        setProgressStatus('Upload error');
        return undefined;
      }
    }

    return stagedFile;
  }

  const isSubmitReady = stagedFile && dataName;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="subtitle2">
          {addDataType === AddDataSlider.Request
            ? "We'll attempt to parse the schema from your file. "
            : ''}
          For simplicity, we require&nbsp;
          <Link
            href="https://www.computerhope.com/issues/ch001356.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            CSV format
          </Link>
          &nbsp;with labeled header columns.
        </Typography>
      </Grid>
      <Grid item>
        <Input
          type="file"
          inputProps={{ accept: ['text/csv'] }}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
              onChooseFile(event.target.files)
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      </Grid>
      <Grid item hidden={!fileError}>
        {statusGateway({ error: fileError })}
      </Grid>
      <Grid item hidden={!stagedFile}>
        <TextField
          label="Data Name"
          value={dataName}
          onChange={(event) => {
            setDataName(event.target.value);
          }}
          required
          error={!dataName}
        />
      </Grid>
      <Grid item hidden={!isSubmitReady}>
        <EditTopics topicNames={topicNames} setTopicNames={setTopicNames} />
      </Grid>
      {isSubmitReady ? (
        <Grid item hidden={!stagedFile}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-end"
            alignContent="center"
          >
            <Grid item>
              <TextField
                label="Reference Name (Optional)"
                value={referenceName}
                onChange={(event) => {
                  setReferenceName(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Tooltip
                title="(Optional) Provide a reference name, if you know where to find primary source data."
                placement="right"
              >
                <Info color="secondary" />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ) : undefined}
      {isSubmitReady ? (
        <Grid item hidden={!stagedFile}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-end"
            alignContent="center"
          >
            <Grid item>
              <TextField
                label="Reference Link (Optional)"
                value={referenceLink}
                onChange={(event) => {
                  setReferenceLink(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Tooltip
                title="(Optional) Provide a reference URL, if you know where to find primary source data."
                placement="right"
              >
                <Info color="secondary" />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ) : undefined}
      <Grid item hidden={!stagedFile || !isSubmitReady}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <TextField
              label="Notes (Optional)"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              multiline
              variant="outlined"
              rows={4}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title="(Optional) Any additional notes you'd like to provide on the data?"
              placement="right"
            >
              <Info color="secondary" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item hidden={!stagedFile}>
        <Alert severity="info">
          <Typography variant="subtitle2">
            Please remember our&nbsp;
            <Link href="/community/guidelines">community guidelines</Link>, make sure
            you have permission to upload this data, and note that the data will be
            publicly accessible.
          </Typography>
        </Alert>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={!stagedFile || !dataName}
          onClick={() => onSubmitData()}
        >
          {addDataType === AddDataSlider.HaveFile ? 'Upload' : `Submit`}
        </Button>
      </Grid>
      <Grid item style={{ width: '80%' }}>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={(100 * progressLoaded) / progressTotal}
          title="Progress"
          hidden={progressLoaded === 0}
        />
      </Grid>
      {progressStatus ? <Grid item>{progressStatus}</Grid> : undefined}
      {uploadError ? (
        <Grid item>{statusGateway({ error: uploadError })}</Grid>
      ) : undefined}
      {error || loading ? (
        <Grid item>{statusGateway({ error, loading })}</Grid>
      ) : undefined}
      {errorUpdate || loadingUpdate ? (
        <Grid item>
          {statusGateway({ error: errorUpdate, loading: loadingUpdate })}
        </Grid>
      ) : undefined}
    </Grid>
  );
}

interface AddDataProps {
  trainCarInputs: Array<TrainCarInput>;
  defaultTopicNames: Array<string>;
  defaultSlider?: AddDataSlider;
  preloadedData?: GetDataManagedResult;
}

function normalizeDataName(text: string) {
  return normalizeQuery(text)?.replace('.csv', '').replace('.CSV', '');
}

export enum AddDataSlider {
  HaveFile = 'I have data',
  Request = 'I want to request data',
}

export function AddData(props: AddDataProps & RouteHistoryProps) {
  const {
    trainCarInputs,
    defaultTopicNames,
    defaultSlider,
    preloadedData,
    history,
  } = props;

  const [slider, setSlider] = useState(defaultSlider);

  function changeSlider(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: AddDataSlider
  ) {
    if (value !== null) {
      setSlider(value);
    }
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="subtitle2">
          Are you asking for data, or do you already have some?
        </Typography>
      </Grid>
      <Grid item>
        <ToggleButtonGroup value={slider} exclusive onChange={changeSlider}>
          <ToggleButton value={AddDataSlider.Request}>
            <Tooltip title="I want to make a request for data">
              <div>{AddDataSlider.Request}</div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={AddDataSlider.HaveFile}>
            <Tooltip title="I have a CSV file">
              <div>{AddDataSlider.HaveFile}</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        {slider === AddDataSlider.HaveFile ? (
          <AddDataFile
            trainCarInputs={trainCarInputs}
            addDataType={AddDataSlider.HaveFile}
            defaultTopicNames={defaultTopicNames}
            preloadedData={preloadedData}
            history={history}
          />
        ) : undefined}
        {slider === AddDataSlider.Request ? (
          <RequestData
            trainCarInputs={trainCarInputs}
            defaultTopicNames={defaultTopicNames}
            preloadedData={preloadedData}
            history={history}
          />
        ) : undefined}
      </Grid>
    </Grid>
  );
}
