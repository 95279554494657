import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useGeneralStyles } from 'GeneralStyle';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { capitalizeFirst, useFormFields } from 'Utils';
import Typography from '@material-ui/core/Typography';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { RouteHistoryProps } from 'RouterProps';
import { LoadingButton, ErrorAlert } from 'Reportable';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import { analyticsCapture } from 'Analytics';
import Info from '@material-ui/icons/Info';

interface UnnamedAuthInputFieldProps {
  value?: string;
  setFields: (event: React.FocusEvent<HTMLInputElement>) => void;
  textFieldProps: TextFieldProps;
}

interface AuthInputFieldProps extends UnnamedAuthInputFieldProps {
  name: string;
}

export function AuthInputField(props: AuthInputFieldProps) {
  const { name, value, setFields, textFieldProps } = props;

  const appliedProps = {
    value,
    onChange: (event: React.FocusEvent<HTMLInputElement>) => {
      setFields(event);
    },
    label: capitalizeFirst(name),
    id: name,
    type: name,
    ...textFieldProps,
  };

  return <TextField {...appliedProps} />;
}

interface ValidatedInputFieldProps extends AuthInputFieldProps {
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  validate: (text?: string) => boolean;
}

export function ValidatedInputField(props: ValidatedInputFieldProps) {
  const { value, setIsValid, validate, ...authInputFieldProps } = props;

  useEffect(() => {
    setIsValid(validate(value));
  }, [setIsValid, validate, value]);

  return <AuthInputField {...authInputFieldProps} value={value} />;
}

interface ValidatedStringLengthInputFieldProps extends AuthInputFieldProps {
  minLength: number;
  maxLength: number;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ValidatedStringLengthInputField(
  props: ValidatedStringLengthInputFieldProps
) {
  const { minLength, maxLength, ...passProps } = props;

  function validate(text?: string) {
    if (!text || text.length < minLength || text.length > maxLength) {
      return false;
    }
    return true;
  }

  return <ValidatedInputField {...passProps} validate={validate} />;
}

export interface UnnamedInternallyValidatedTextFieldProps {
  value?: string;
  setFields: (event: React.FocusEvent<HTMLInputElement>) => void;
  isValid: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

export interface InternallyValidatedTextFieldProps
  extends UnnamedInternallyValidatedTextFieldProps {
  name: string;
  minLength: number;
  maxLength: number;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  helperText?: string;
  textFieldProps?: TextFieldProps;
}

export function InternallyValidatedTextField(props: InternallyValidatedTextFieldProps) {
  const { value, isValid, textFieldProps, disabled, ...rest } = props;

  const appliedTextFieldProps: TextFieldProps = textFieldProps || {};

  const { helperText, ...restTextFieldProps } = appliedTextFieldProps;

  const showError = !isValid && !!value;

  return (
    <ValidatedStringLengthInputField
      value={value || ''}
      {...rest}
      textFieldProps={{
        disabled,
        error: showError,
        helperText: showError ? helperText : undefined,
        ...restTextFieldProps,
      }}
    />
  );
}

export interface UnnamedInternallyValidatedTextFieldAlternateProps {
  value?: string;
  setField: (value: string) => void;
  isValid: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

export interface InternallyValidatedTextFieldAlternateProps
  extends UnnamedInternallyValidatedTextFieldAlternateProps {
  name: string;
  minLength: number;
  maxLength: number;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  helperText?: string;
  textFieldProps?: TextFieldProps;
}

export function InternallyValidatedTextFieldAlternate(
  props: InternallyValidatedTextFieldAlternateProps
) {
  const { value, isValid, textFieldProps, disabled, setField, ...rest } = props;

  const appliedTextFieldProps: TextFieldProps = textFieldProps || {};

  const { helperText, ...restTextFieldProps } = appliedTextFieldProps;

  const showError = !isValid && !!value;

  function setFields(event: React.FocusEvent<HTMLInputElement>) {
    setField(event.target.value);
  }

  return (
    <ValidatedStringLengthInputField
      value={value || ''}
      setFields={setFields}
      {...rest}
      textFieldProps={{
        disabled,
        error: showError,
        helperText: showError ? helperText : undefined,
        ...restTextFieldProps,
      }}
    />
  );
}

export function UsernameInput(props: UnnamedInternallyValidatedTextFieldProps) {
  return (
    <InternallyValidatedTextField
      name="username"
      {...props}
      minLength={1}
      maxLength={32}
      textFieldProps={{
        placeholder: 'Your username',
        autoComplete: 'username',
        required: true,
        helperText: 'Must be 1 - 32 characters',
      }}
    />
  );
}

export function NameInput(props: UnnamedInternallyValidatedTextFieldProps) {
  return (
    <InternallyValidatedTextField
      name="name"
      {...props}
      minLength={1}
      maxLength={64}
      textFieldProps={{
        placeholder: 'First Last',
        autoComplete: 'name',
        required: true,
        helperText: 'Must be 1 - 64 characters',
      }}
    />
  );
}

export function EmailInput(props: UnnamedInternallyValidatedTextFieldProps) {
  return (
    <InternallyValidatedTextField
      name="email"
      {...props}
      minLength={1}
      maxLength={96}
      textFieldProps={{
        placeholder: 'Your email address',
        autoComplete: 'email',
        required: true,
        helperText: 'Must be a valid email address',
      }}
    />
  );
}

export function LoginCodeInput(props: UnnamedInternallyValidatedTextFieldProps) {
  return (
    <InternallyValidatedTextField
      name="code"
      {...props}
      minLength={6}
      maxLength={6}
      textFieldProps={{
        placeholder: 'Your login code',
        autoComplete: 'code',
        required: true,
        helperText: 'The code should be 6 digits',
      }}
    />
  );
}

enum AuthWorkflowState {
  SignIn,
  SignUp,
  EnterCode,
}

interface SignInProps {
  username?: string;
}

interface AuthModifierProps {
  disableRedirect?: boolean;
  setUsername?: React.Dispatch<React.SetStateAction<string | undefined>>;
  // eslint-disable-next-line react/no-unused-prop-types
  reasonForAuth?: string;
}

export function SignInRoute(props: AuthModifierProps & RouteHistoryProps) {
  const { setUsername, history, disableRedirect, reasonForAuth } = props;

  const classes = useGeneralStyles();

  const { fields, setFields } = useFormFields<SignInProps>({});
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [workflowState, setWorkflowState] = useState(AuthWorkflowState.SignIn);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [info, setInfo] = useState<string>();

  const submitDisabled = !isUsernameValid;
  const reasonForRedirect = history.location.state?.reasonForRedirect;

  useEffect(() => {
    analyticsCapture('Viewed Sign In');
  }, []);

  useEffect(() => {
    if (reasonForAuth) {
      setInfo(reasonForAuth);
    } else if (reasonForRedirect) {
      setInfo(reasonForRedirect);
    }
  }, [reasonForAuth, reasonForRedirect]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    setError(undefined);
    setLoading(true);
    event.preventDefault();
    analyticsCapture('Clicked Sign In');

    if (!submitDisabled && fields.username) {
      try {
        const user: CognitoUser = await Auth.signIn(fields.username);
        setCognitoUser(user);
        setWorkflowState(AuthWorkflowState.EnterCode);
        setLoading(false);
      } catch (e) {
        if (e.code === 'UserNotFoundException') {
          setWorkflowState(AuthWorkflowState.SignUp);
          setError("Hmm, we don't recognize that username. Would you like to sign up?");
          setLoading(false);
        } else {
          setError(e.message || 'Could not sign in');
        }
        setLoading(false);
      }
    }
  }

  let renderedElement = (
    <Grid
      container
      spacing={3}
      direction="column"
      className={classes.centeredWithTopMargin}
    >
      <ErrorAlert message={error} />
      <ErrorAlert message={info} severity="info" />
      <Grid item>
        <Typography variant="h5">Sign in</Typography>
      </Grid>
      <Grid item>
        New to QuestTrain? {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          onClick={() => {
            setWorkflowState(AuthWorkflowState.SignUp);
          }}
        >
          Sign up
        </Link>
      </Grid>
      <Grid item>
        <form onSubmit={onSubmit}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <UsernameInput
                value={fields.username}
                setFields={setFields}
                isValid={isUsernameValid}
                setIsValid={setIsUsernameValid}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitDisabled}
                loading={loading}
              >
                Get Login Code
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );

  if (workflowState === AuthWorkflowState.SignUp) {
    renderedElement = (
      <SignUpRoute
        preloadedUsername={fields.username}
        history={history}
        disableRedirect={disableRedirect}
        reasonForAuth={reasonForAuth}
        setUsername={setUsername}
      />
    );
  }
  if (
    workflowState === AuthWorkflowState.EnterCode &&
    fields.username &&
    isUsernameValid &&
    cognitoUser
  ) {
    renderedElement = (
      <EnterCodeRoute
        cognitoUser={cognitoUser}
        history={history}
        setUsername={setUsername}
        disableRedirect={disableRedirect}
      />
    );
  }

  return renderedElement;
}

interface SignUpProps extends SignInProps {
  email?: string;
  name?: string;
}

interface SignUpRouteProps {
  preloadedUsername?: string;
}

export function SignUpRoute(
  props: AuthModifierProps & RouteHistoryProps & SignUpRouteProps
) {
  const {
    setUsername,
    history,
    disableRedirect,
    reasonForAuth,
    preloadedUsername,
  } = props;

  const classes = useGeneralStyles();

  const { fields, setFields } = useFormFields<SignUpProps>({
    username: preloadedUsername,
  });
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [isNameValid, setIsNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [workflowState, setWorkflowState] = useState(AuthWorkflowState.SignUp);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const submitDisabled = !isNameValid || !isEmailValid || !isUsernameValid;

  useEffect(() => {
    analyticsCapture('Viewed Sign Up');
  }, []);

  function intToHex(num: number) {
    return num.toString(16).padStart(2, '0');
  }

  function getRandomString(bytes: number) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join('');
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    setError(undefined);
    setLoading(true);
    event.preventDefault();
    analyticsCapture('Clicked Sign Up');

    if (!submitDisabled && fields.username) {
      const params = {
        username: fields.username,
        password: getRandomString(30),
        attributes: {
          name: fields.name,
          email: fields.email,
        },
      };

      try {
        await Auth.signUp(params);
      } catch (e) {
        setError(e.message || 'Could not sign up');
        setLoading(false);
        return;
      }

      try {
        const signedInUser: CognitoUser = await Auth.signIn(fields.username);
        setCognitoUser(signedInUser);
        setWorkflowState(AuthWorkflowState.EnterCode);
        setLoading(false);
      } catch (e) {
        setError(e.message || 'Could not send login code');
        setLoading(false);
      }
    }
  }

  let renderedElement = (
    <Grid
      container
      spacing={3}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
      className={classes.centeredWithTopMargin}
      style={{ width: '100%' }}
    >
      <ErrorAlert message={error} />
      {preloadedUsername ? (
        <Alert severity="info">
          Looks like you&apos;re new here - welcome! Let&apos;s get you signed up. (Or,
          if you misspelled your username, head back to{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            onClick={() => {
              setWorkflowState(AuthWorkflowState.SignIn);
            }}
          >
            sign in
          </Link>
          )
        </Alert>
      ) : undefined}
      <Grid item>
        <Typography variant="h5">Sign up</Typography>
      </Grid>
      <Grid item>Create an account to manage your questions and data!</Grid>
      <Grid item style={{ width: '100%' }}>
        <form onSubmit={onSubmit}>
          <Grid
            container
            direction="column"
            spacing={4}
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="flex-end"
                alignContent="center"
              >
                <Grid item>
                  <NameInput
                    value={fields.name}
                    setFields={setFields}
                    isValid={isNameValid}
                    setIsValid={setIsNameValid}
                    disabled={loading}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title="Your name is private by default. However, you can share your name on your public profile later, if you'd like."
                    placement="right"
                  >
                    <Info color="secondary" />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="flex-end"
                alignContent="center"
              >
                <Grid item>
                  <EmailInput
                    value={fields.email}
                    setFields={setFields}
                    isValid={isEmailValid}
                    setIsValid={setIsEmailValid}
                    disabled={loading}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title="We will send login codes to this email. Your email address is not publicly viewable."
                    placement="right"
                  >
                    <Info color="secondary" />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="flex-end"
                alignContent="center"
              >
                <Grid item>
                  <UsernameInput
                    value={fields.username}
                    setFields={setFields}
                    isValid={isUsernameValid}
                    setIsValid={setIsUsernameValid}
                    disabled={loading}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title="Your username is publicly viewable and cannot be changed later."
                    placement="right"
                  >
                    <Info color="secondary" />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitDisabled}
                loading={loading}
              >
                Sign Up
              </LoadingButton>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              Already have an account?{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                onClick={() => {
                  setWorkflowState(AuthWorkflowState.SignIn);
                }}
              >
                Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );

  if (workflowState === AuthWorkflowState.SignIn) {
    renderedElement = (
      <SignInRoute
        history={history}
        disableRedirect={disableRedirect}
        reasonForAuth={reasonForAuth}
        setUsername={setUsername}
      />
    );
  }
  if (
    workflowState === AuthWorkflowState.EnterCode &&
    fields.username &&
    isUsernameValid &&
    cognitoUser
  ) {
    renderedElement = (
      <EnterCodeRoute
        cognitoUser={cognitoUser}
        history={history}
        setUsername={setUsername}
        disableRedirect={disableRedirect}
      />
    );
  }

  return renderedElement;
}

export interface EnterCodeProps {
  code?: string;
}

export interface EnterCodeRouteProps {
  cognitoUser: CognitoUser;
}

export function EnterCodeRoute(
  props: AuthModifierProps & EnterCodeRouteProps & RouteHistoryProps
) {
  const { setUsername, cognitoUser, history, disableRedirect } = props;

  const classes = useGeneralStyles();

  const [isValid, setIsValid] = useState(false);
  const [loginCode, setLoginCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [internalUser, setInternalUser] = useState(cognitoUser);

  async function submitIfCodeValid(event: React.FocusEvent<HTMLInputElement>) {
    const preCode = event.target.value;
    const code = preCode.toUpperCase();
    setLoginCode(code);

    if (code.length !== 6) {
      return;
    }

    setError(undefined);
    setLoading(true);

    // Send the answer to the User Pool
    // This will throw an error if it’s the 3rd wrong answer
    try {
      await Auth.sendCustomChallengeAnswer(internalUser, code);
    } catch (e) {
      setError(e.message || 'Unknown network error');
      setLoading(false);
      return;
    }

    try {
      // It we get here, the answer was sent successfully,
      // but it might have been wrong (1st or 2nd time)
      // So we should test if the user is authenticated now

      // This will throw an error if the user is not yet authenticated:
      await Auth.currentSession();
      if (setUsername) {
        setUsername(internalUser.getUsername());
      }
      if (!disableRedirect) {
        if (
          history.location.state?.returnTo &&
          !history.location.state.returnTo.includes('signin') &&
          !history.location.state.returnTo.includes('signup')
        ) {
          history.push(history.location.state.returnTo, {
            returnTo: undefined,
            reasonForRedirect: undefined,
          });
        } else {
          history.push('/engine', {
            returnTo: undefined,
            reasonForRedirect: undefined,
          });
        }
      }
    } catch (e) {
      setError(e.message || 'Wrong code');
      setLoading(false);
    }
  }

  async function onNewCode(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setError(undefined);
    setLoading(true);
    event.preventDefault();
    analyticsCapture('Sent new code');

    try {
      const user = await Auth.signIn(internalUser.getUsername());
      setInternalUser(user);
      setLoading(false);
    } catch (e) {
      setError(e.message || 'Could not sign in');
      setLoading(false);
    }
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      className={classes.centeredWithTopMargin}
    >
      <ErrorAlert message={error} />
      <Grid item>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h5">Hello, {internalUser.getUsername()}!</Typography>
          </Grid>
          <Grid item>Check your email for the temporary login code.</Grid>
          <Grid item>
            <LoginCodeInput
              value={loginCode}
              setFields={submitIfCodeValid}
              isValid={isValid}
              setIsValid={setIsValid}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled
              loading={loading}
            >
              Sign In
            </LoadingButton>
          </Grid>
          <Grid item>
            Can&apos;t find the code?
            <Button color="primary" onClick={(event) => onNewCode(event)}>
              Email new code
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
