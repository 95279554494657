import { CarType, QuestTrainResult } from 'data/types';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { QuestionCard } from 'ViewQuestion';
import { RouteHistoryProps } from 'RouterProps';
import { DataCard } from 'DataCard';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import Assignment from '@material-ui/icons/Assignment';
import StorageIcon from '@material-ui/icons/Storage';
import logoGreen from 'assets/QuestTrainLogoGreen.png';
import { Tooltip } from '@material-ui/core';

export enum AnchorLocation {
  Left,
  Right,
}

export enum AnchorType {
  Question,
  Data,
  DataRequest,
}

interface QuestTrainCardProps {
  questTrain: QuestTrainResult;
  anchorLocation: AnchorLocation;
  anchorType: AnchorType;
  anchorText: string;
}

export function QuestTrainCard(props: QuestTrainCardProps & RouteHistoryProps) {
  const { questTrain, anchorLocation, anchorType, anchorText, history } = props;

  let anchorIcon: JSX.Element;

  switch (anchorType) {
    case AnchorType.Question:
      anchorIcon = <img src={logoGreen} alt="logoGreen" style={{ height: 20 }} />;
      break;
    case AnchorType.Data:
      anchorIcon = <StorageIcon color="primary" />;
      break;
    case AnchorType.DataRequest:
      anchorIcon = <Assignment color="primary" />;
      break;
    default:
      anchorIcon = <img src={logoGreen} alt="logoGreen" style={{ height: 20 }} />;
      break;
  }

  const anchorElement = <Tooltip title={anchorText}>{anchorIcon}</Tooltip>;

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          {anchorLocation === AnchorLocation.Left ? (
            <Grid item>{anchorElement}</Grid>
          ) : undefined}
          {anchorLocation === AnchorLocation.Left ? (
            <Grid item>
              <ArrowRightAlt />
            </Grid>
          ) : undefined}
          {questTrain.trainCars.map((trainCar) => (
            <Grid item>
              {trainCar.carType === CarType.QuestionType && trainCar.question ? (
                <QuestionCard
                  question={trainCar.question}
                  trainCarInputs={[]}
                  history={history}
                />
              ) : undefined}
              {trainCar.carType === CarType.DataType && trainCar.data ? (
                <DataCard data={trainCar.data} trainCarInputs={[]} history={history} />
              ) : undefined}
            </Grid>
          ))}
          {anchorLocation === AnchorLocation.Right ? (
            <Grid item>
              <ArrowRightAlt />
            </Grid>
          ) : undefined}
          {anchorLocation === AnchorLocation.Right ? (
            <Grid item>{anchorElement}</Grid>
          ) : undefined}
        </Grid>
      </CardContent>
    </Card>
  );
}

interface QuestTrainListProps {
  questTrains: Array<QuestTrainResult>;
  anchorLocation: AnchorLocation;
  anchorType: AnchorType;
  anchorText: string;
}

export function QuestTrainList(props: QuestTrainListProps & RouteHistoryProps) {
  const { questTrains, anchorLocation, anchorType, anchorText, history } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {questTrains.map((questTrain) => (
        <Grid item>
          <QuestTrainCard
            questTrain={questTrain}
            anchorLocation={anchorLocation}
            anchorType={anchorType}
            anchorText={anchorText}
            history={history}
          />
        </Grid>
      ))}
      {questTrains.length === 0 ? (
        <Grid item>You found a dangler, no QuestTrains yet!</Grid>
      ) : undefined}
    </Grid>
  );
}
