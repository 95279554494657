import React from 'react';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useGeneralStyles } from 'GeneralStyle';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { analyticsCapture } from 'Analytics';

export interface ReportableProps<T> {
  data?: T | null;
  error?: Error;
  loading?: boolean;
}

export interface StatusGatewayProps {
  error?: Error;
  loading?: boolean;
  loadingMessage?: string;
  setError?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function renderError(error: Error, onClose?: () => void) {
  return (
    <Alert onClose={onClose} severity="error">
      {error.toString()}
    </Alert>
  );
}

interface ErrorAlertProps {
  message?: string;
  setMessage?: React.Dispatch<React.SetStateAction<string | undefined>>;
  severity?: AlertProps['severity'];
}

export function ErrorAlert(props: ErrorAlertProps) {
  const { message, setMessage, severity } = props;

  const classes = useGeneralStyles();

  if (!message) {
    return <div />;
  }

  return (
    <Grid container direction="column" className={classes.centered} spacing={3}>
      <Grid item>
        <Alert
          onClose={setMessage ? () => setMessage(undefined) : undefined}
          severity={severity || 'error'}
        >
          {message}
        </Alert>
      </Grid>
    </Grid>
  );
}

interface LoadingIndicatorProps {
  message?: string;
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const { message } = props;

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item>
        <CircularProgress color="secondary" />
      </Grid>
      {message ? <Grid item>{message}</Grid> : undefined}
    </Grid>
  );
}

interface LoadingButtonProps {
  loading?: boolean;
}

export function LoadingButton(props: LoadingButtonProps & ButtonProps) {
  const { loading, ...buttonProps } = props;

  const appliedProps: ButtonProps = {
    ...buttonProps,
    disabled: buttonProps.disabled || loading,
    children: loading ? <CircularProgress /> : buttonProps.children,
  };

  return <Button {...appliedProps} />;
}

export function statusGateway(props: StatusGatewayProps) {
  const { error, loading, loadingMessage, setError } = props;

  if (error) {
    analyticsCapture('Error', { message: error?.message });
    return <ErrorAlert message={error.message} setMessage={setError} />;
  }
  if (loading) return <LoadingIndicator message={loadingMessage} />;
  return undefined;
}

export function reportNoData(dataName?: string) {
  return <Alert severity="error">No {dataName || 'data'} found!</Alert>;
}
