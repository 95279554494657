import { RouteHistoryProps } from 'RouterProps';
import { LandingPage } from 'LandingPage';
import { ViewQuestionRoute, ViewQuestTrainRoute } from 'ViewQuestion';
import { ContactUsRoute } from 'ContactUs';
import { CommunityGuidelinesRoute } from 'CommunityGuidelines';
import { EngineRoute } from 'Routes/EngineRoute';
import { SignInRoute, SignUpRoute } from 'Routes/SignInRoute';
import { AuthenticatedProps } from 'RouteAuth';
import { MyUserProfileRoute, UserProfileRoute } from 'Routes/UserProfileRoute';
import { AddDataRoute } from 'Routes/AddDataRoute';
import { ViewDataRoute } from 'Routes/ViewDataRoute';
import { EditQuestionRoute } from 'Routes/EditQuestionRoute';
import { EditDataRoute } from 'Routes/EditDataRoute';

export interface DrawerRoute {
  path: string;
  name?: string;
  icon?: any;
  component: React.ComponentType<RouteHistoryProps & AuthenticatedProps>;
  auth?: AuthGate;
  drawer?: boolean;
  exact?: boolean;
  reasonForRedirect?: string;
}

export enum AuthGate {
  PrivateOnly = 1,
  PublicOnly = 2,
  PublicWithPrivateOptional = 3,
}

export const drawerRoutes: Array<DrawerRoute> = [
  {
    path: '/',
    component: LandingPage,
    auth: AuthGate.PublicWithPrivateOptional,
    exact: true,
  },
  {
    path: '/question/:questionID',
    name: 'Question',
    component: ViewQuestionRoute,
    auth: AuthGate.PublicWithPrivateOptional,
    exact: true,
  },
  {
    path: '/edit/question/:questionID',
    name: 'Edit Question',
    component: EditQuestionRoute,
    auth: AuthGate.PrivateOnly,
    exact: true,
  },
  {
    path: '/edit/data/:dataID',
    name: 'Edit Data',
    component: EditDataRoute,
    auth: AuthGate.PrivateOnly,
    exact: true,
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: ContactUsRoute,
    auth: AuthGate.PublicWithPrivateOptional,
    exact: true,
  },
  {
    path: '/community/guidelines',
    name: 'Community Guidelines',
    component: CommunityGuidelinesRoute,
    auth: AuthGate.PublicWithPrivateOptional,
    exact: true,
  },
  {
    path: '/questtrain/:questTrainID',
    name: 'QuestTrain',
    component: ViewQuestTrainRoute,
    auth: AuthGate.PublicWithPrivateOptional,
    exact: true,
  },
  {
    path: '/engine',
    name: 'Engine',
    drawer: true,
    component: EngineRoute,
    auth: AuthGate.PublicWithPrivateOptional,
    exact: true,
  },
  {
    path: '/signin',
    component: SignInRoute,
    name: 'Sign In',
    auth: AuthGate.PublicOnly,
  },
  {
    path: '/signup',
    component: SignUpRoute,
    name: 'Sign Up',
    auth: AuthGate.PublicOnly,
  },
  {
    path: '/user/:targetUsername',
    component: UserProfileRoute,
    name: 'User Profile',
    auth: AuthGate.PublicWithPrivateOptional,
  },
  {
    path: '/my/profile',
    component: MyUserProfileRoute,
    name: 'My Profile',
    auth: AuthGate.PrivateOnly,
  },
  {
    path: '/add/data',
    component: AddDataRoute,
    name: 'Add Data',
    auth: AuthGate.PublicWithPrivateOptional,
    drawer: true,
  },
  {
    path: '/data/:dataID',
    component: ViewDataRoute,
    name: 'View Data',
    auth: AuthGate.PublicWithPrivateOptional,
  },
];
