import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
};

export type Query = {
  searchVectorIndex: SearchResults;
  searchTraditionalIndex: SearchResults;
  randomSample: SearchResults;
  getTopTopics: Array<TopicBasics>;
  getPublicUserProfile?: Maybe<PublicUserProfileResult>;
  getMyUserProfile?: Maybe<MyUserProfileResult>;
  getQuestionManaged: GetQuestionManagedResult;
  getDataManaged: GetDataManagedResult;
  getUser?: Maybe<User>;
  listUsers?: Maybe<ModelUserConnection>;
  getUsernameLookup?: Maybe<UsernameLookup>;
  getEmailLookup?: Maybe<EmailLookup>;
  listUserFollowsQuestions?: Maybe<ModelUserFollowsQuestionConnection>;
  listUserFollowsDatas?: Maybe<ModelUserFollowsDataConnection>;
  listUserFollowsUsers?: Maybe<ModelUserFollowsUserConnection>;
  getQuestion?: Maybe<Question>;
  listQuestions?: Maybe<ModelQuestionConnection>;
  listQuestionEdges?: Maybe<ModelQuestionEdgeConnection>;
  listDataDataEdges?: Maybe<ModelDataDataEdgeConnection>;
  getQuestTrain?: Maybe<QuestTrain>;
  listQuestTrains?: Maybe<ModelQuestTrainConnection>;
  getData?: Maybe<Data>;
  listDatas?: Maybe<ModelDataConnection>;
  listQuestionDataEdges?: Maybe<ModelQuestionDataEdgeConnection>;
  getTopic?: Maybe<Topic>;
  listTopics?: Maybe<ModelTopicConnection>;
  listTopicsByFollowerCount?: Maybe<ModelTopicConnection>;
  listTopicsByEntityCount?: Maybe<ModelTopicConnection>;
  listUserFollowsTopics?: Maybe<ModelUserFollowsTopicConnection>;
};

export type QuerySearchVectorIndexArgs = {
  query: Scalars['String'];
  limit: Scalars['Int'];
  scoreThreshold: Scalars['Float'];
  filter?: Maybe<SearchFilterInput>;
  from?: Maybe<Scalars['Int']>;
};

export type QuerySearchTraditionalIndexArgs = {
  query: Scalars['String'];
  limit: Scalars['Int'];
  filter?: Maybe<SearchFilterInput>;
  from?: Maybe<Scalars['Int']>;
};

export type QueryRandomSampleArgs = {
  limit: Scalars['Int'];
  from?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchFilterInput>;
};

export type QueryGetTopTopicsArgs = {
  limit: Scalars['Int'];
};

export type QueryGetPublicUserProfileArgs = {
  username: Scalars['ID'];
};

export type QueryGetQuestionManagedArgs = {
  id: Scalars['ID'];
};

export type QueryGetDataManagedArgs = {
  id: Scalars['ID'];
};

export type QueryGetUserArgs = {
  id: Scalars['ID'];
};

export type QueryListUsersArgs = {
  filter?: Maybe<ModelUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetUsernameLookupArgs = {
  username: Scalars['ID'];
};

export type QueryGetEmailLookupArgs = {
  email: Scalars['ID'];
};

export type QueryListUserFollowsQuestionsArgs = {
  filter?: Maybe<ModelUserFollowsQuestionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListUserFollowsDatasArgs = {
  filter?: Maybe<ModelUserFollowsDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListUserFollowsUsersArgs = {
  filter?: Maybe<ModelUserFollowsUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetQuestionArgs = {
  id: Scalars['ID'];
};

export type QueryListQuestionsArgs = {
  filter?: Maybe<ModelQuestionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListQuestionEdgesArgs = {
  filter?: Maybe<ModelQuestionEdgeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListDataDataEdgesArgs = {
  filter?: Maybe<ModelDataDataEdgeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetQuestTrainArgs = {
  id: Scalars['ID'];
};

export type QueryListQuestTrainsArgs = {
  filter?: Maybe<ModelQuestTrainFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetDataArgs = {
  id: Scalars['ID'];
};

export type QueryListDatasArgs = {
  filter?: Maybe<ModelDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListQuestionDataEdgesArgs = {
  filter?: Maybe<ModelQuestionDataEdgeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryGetTopicArgs = {
  id: Scalars['ID'];
};

export type QueryListTopicsArgs = {
  filter?: Maybe<ModelTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListTopicsByFollowerCountArgs = {
  sortable?: Maybe<IsSortable>;
  followerCount?: Maybe<ModelIntKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListTopicsByEntityCountArgs = {
  sortable?: Maybe<IsSortable>;
  entityCount?: Maybe<ModelIntKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QueryListUserFollowsTopicsArgs = {
  filter?: Maybe<ModelUserFollowsTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type SearchResults = {
  results: Array<SearchResult>;
  from?: Maybe<Scalars['Int']>;
};

export type SearchResult = {
  carType: CarType;
  question?: Maybe<SearchQuestionResult>;
  data?: Maybe<SearchDataResult>;
};

export enum CarType {
  QuestionType = 'QuestionType',
  DataType = 'DataType',
}

export type SearchQuestionResult = {
  id: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type SearchDataResult = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  name: Scalars['String'];
  header: Array<HeaderColumn>;
  isRequest: Scalars['Boolean'];
  updatedAt: Scalars['AWSDateTime'];
};

export type HeaderColumn = {
  name: Scalars['String'];
  columnType: ColumnType;
  required?: Maybe<Scalars['Boolean']>;
  exampleValue: Scalars['String'];
};

export enum ColumnType {
  String = 'STRING',
  Number = 'NUMBER',
  Boolean = 'BOOLEAN',
}

export type SearchFilterInput = {
  entityTypes?: Maybe<Array<ElasticSearchIndexEntityType>>;
  topicNames?: Maybe<Array<Scalars['String']>>;
};

export enum ElasticSearchIndexEntityType {
  Question = 'Question',
  Data = 'Data',
  DataRequest = 'DataRequest',
}

export type TopicBasics = {
  id: Scalars['ID'];
  name: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
};

export type PublicUserProfileResult = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  username: Scalars['ID'];
  ownedQuestions?: Maybe<Array<SearchQuestionResult>>;
  ownedQuestTrains?: Maybe<Array<SearchQuestionResultQuestTrain>>;
  ownedDatas?: Maybe<Array<SearchDataResult>>;
  followingUsers?: Maybe<Array<SearchUserResult>>;
  followedByUsers?: Maybe<Array<SearchUserResult>>;
  amIFollower: Scalars['Boolean'];
};

export type SearchQuestionResultQuestTrain = {
  id: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  questions: Array<SearchQuestionResultQuestTrainQuestion>;
  carSpecs: Array<CarSpec>;
  updatedAt: Scalars['AWSDateTime'];
};

export type SearchQuestionResultQuestTrainQuestion = {
  id: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type CarSpec = {
  id: Scalars['ID'];
  carType: CarType;
};

export type SearchUserResult = {
  id: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type MyUserProfileResult = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  username: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['ID'];
  followedQuestions?: Maybe<Array<SearchQuestionResult>>;
  followedDatas?: Maybe<Array<SearchDataResult>>;
  ownedQuestions?: Maybe<Array<SearchQuestionResult>>;
  ownedQuestTrains?: Maybe<Array<SearchQuestionResultQuestTrain>>;
  ownedDatas?: Maybe<Array<SearchDataResult>>;
  notifications?: Maybe<Array<NotificationResult>>;
  followingUsers?: Maybe<Array<SearchUserResult>>;
  followedByUsers?: Maybe<Array<SearchUserResult>>;
  followedTopics?: Maybe<Array<TopicBasics>>;
};

export type NotificationResult = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  actorUserID: Scalars['ID'];
  actorUsername: Scalars['ID'];
  actorQuestionID?: Maybe<Scalars['ID']>;
  actorQuestion?: Maybe<SearchQuestionResult>;
  actorDataID?: Maybe<Scalars['ID']>;
  actorData?: Maybe<SearchDataResult>;
  followedQuestionID?: Maybe<Scalars['ID']>;
  followedQuestion?: Maybe<SearchQuestionResult>;
  followedDataID?: Maybe<Scalars['ID']>;
  followedData?: Maybe<SearchDataResult>;
  read: Scalars['Boolean'];
  pushNotified: Scalars['Boolean'];
};

export type GetQuestionManagedResult = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  text: Scalars['String'];
  questTrains: Array<QuestTrainResult>;
  neighborQuestions?: Maybe<Array<SearchQuestionResult>>;
  amIFollower: Scalars['Boolean'];
  topicNames: Array<Scalars['String']>;
};

export type QuestTrainResult = {
  trainCars: Array<TrainCarResult>;
};

export type TrainCarResult = {
  id: Scalars['ID'];
  carType: CarType;
  question?: Maybe<SearchQuestionResult>;
  data?: Maybe<SearchDataResult>;
};

export type GetDataManagedResult = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  name: Scalars['String'];
  header: Array<HeaderColumn>;
  snippet?: Maybe<Scalars['String']>;
  numRows: Scalars['Int'];
  fileSizeBytes: Scalars['Int'];
  references?: Maybe<Array<Reference>>;
  notes?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  s3Prefix: Scalars['String'];
  s3Basename: Scalars['String'];
  isRequest: Scalars['Boolean'];
  questTrains: Array<QuestTrainResult>;
  amIFollower: Scalars['Boolean'];
  topicNames: Array<Scalars['String']>;
};

export type Reference = {
  name: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};

export type User = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  username: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  name: Scalars['String'];
  email: Scalars['ID'];
  ownedQuestions?: Maybe<ModelUserOwnsQuestionConnection>;
  ownedQuestTrains?: Maybe<ModelUserOwnsQuestTrainConnection>;
  followedQuestions?: Maybe<ModelUserFollowsQuestionConnection>;
  followedDatas?: Maybe<ModelUserFollowsDataConnection>;
  followingUsers?: Maybe<ModelUserFollowsUserConnection>;
  followedByUsers?: Maybe<ModelUserFollowsUserConnection>;
  ownedData?: Maybe<ModelUserOwnsDataConnection>;
  notifications?: Maybe<ModelNotificationConnection>;
  followedTopics?: Maybe<ModelUserFollowsTopicConnection>;
};

export type UserOwnedQuestionsArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserOwnsQuestionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserOwnedQuestTrainsArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserOwnsQuestTrainFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowedQuestionsArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsQuestionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowedDatasArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsDataFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowingUsersArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsUserFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowedByUsersArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsUserFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserOwnedDataArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserOwnsDataFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserNotificationsArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelNotificationFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowedTopicsArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsTopicFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserOwnsQuestionConnection = {
  items?: Maybe<Array<Maybe<UserOwnsQuestion>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserOwnsQuestion = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  questionID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  user: User;
  question: Question;
};

export type Question = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  text: Scalars['String'];
  viewsCounter: Scalars['Int'];
  topicNames: Array<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  ownerEdge: UserOwnsQuestion;
  userFollows?: Maybe<ModelUserFollowsQuestionConnection>;
  questionsForward?: Maybe<ModelQuestionEdgeConnection>;
  questionsBackward?: Maybe<ModelQuestionEdgeConnection>;
  questTrains?: Maybe<ModelQuestTrainQuestionEdgeConnection>;
  data?: Maybe<ModelQuestionDataEdgeConnection>;
};

export type QuestionUserFollowsArgs = {
  userID?: Maybe<ModelIdKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsQuestionFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionQuestionsForwardArgs = {
  score?: Maybe<ModelFloatKeyConditionInput>;
  filter?: Maybe<ModelQuestionEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionQuestionsBackwardArgs = {
  score?: Maybe<ModelFloatKeyConditionInput>;
  filter?: Maybe<ModelQuestionEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionQuestTrainsArgs = {
  questTrainID?: Maybe<ModelIdKeyConditionInput>;
  filter?: Maybe<ModelQuestTrainQuestionEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionDataArgs = {
  score?: Maybe<ModelFloatKeyConditionInput>;
  filter?: Maybe<ModelQuestionDataEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserFollowsQuestionConnection = {
  items?: Maybe<Array<Maybe<UserFollowsQuestion>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowsQuestion = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  questionID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  user: User;
  question: Question;
};

export type ModelIdKeyConditionInput = {
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  beginsWith?: Maybe<Scalars['ID']>;
};

export type ModelUserFollowsQuestionFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  userID?: Maybe<ModelIdInput>;
  questionID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsQuestionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsQuestionFilterInput>>>;
  not?: Maybe<ModelUserFollowsQuestionFilterInput>;
};

export type ModelIdInput = {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  beginsWith?: Maybe<Scalars['ID']>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  size?: Maybe<ModelSizeInput>;
};

export enum ModelAttributeTypes {
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet',
  Null = '_null',
}

export type ModelSizeInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ModelStringInput = {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
  size?: Maybe<ModelSizeInput>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ModelQuestionEdgeConnection = {
  items?: Maybe<Array<Maybe<QuestionEdge>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionEdge = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  aID: Scalars['ID'];
  bID: Scalars['ID'];
  score: Scalars['Float'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  questionA: Question;
  questionB: Question;
};

export type ModelFloatKeyConditionInput = {
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type ModelQuestionEdgeFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  aID?: Maybe<ModelIdInput>;
  bID?: Maybe<ModelIdInput>;
  score?: Maybe<ModelFloatInput>;
  and?: Maybe<Array<Maybe<ModelQuestionEdgeFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestionEdgeFilterInput>>>;
  not?: Maybe<ModelQuestionEdgeFilterInput>;
};

export type ModelFloatInput = {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
};

export type ModelQuestTrainQuestionEdgeConnection = {
  items?: Maybe<Array<Maybe<QuestTrainQuestionEdge>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestTrainQuestionEdge = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  questTrainID: Scalars['ID'];
  questionID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  question: Question;
  questTrain: QuestTrain;
};

export type QuestTrain = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  carSpecs: Array<CarSpec>;
  viewsCounter: Scalars['Int'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  ownerEdge: UserOwnsQuestTrain;
  questions?: Maybe<ModelQuestTrainQuestionEdgeConnection>;
};

export type QuestTrainQuestionsArgs = {
  questionID?: Maybe<ModelIdKeyConditionInput>;
  filter?: Maybe<ModelQuestTrainQuestionEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserOwnsQuestTrain = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  questTrainID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  user: User;
  questTrain: QuestTrain;
};

export type ModelQuestTrainQuestionEdgeFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  questTrainID?: Maybe<ModelIdInput>;
  questionID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelQuestTrainQuestionEdgeFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestTrainQuestionEdgeFilterInput>>>;
  not?: Maybe<ModelQuestTrainQuestionEdgeFilterInput>;
};

export type ModelQuestionDataEdgeConnection = {
  items?: Maybe<Array<Maybe<QuestionDataEdge>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionDataEdge = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  questionID: Scalars['ID'];
  dataID: Scalars['ID'];
  score: Scalars['Float'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  question: Question;
  data: Data;
};

export type Data = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  name: Scalars['String'];
  header: Array<HeaderColumn>;
  snippet?: Maybe<Scalars['String']>;
  numRows: Scalars['Int'];
  fileSizeBytes: Scalars['Int'];
  references?: Maybe<Array<Reference>>;
  notes?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  s3Prefix: Scalars['String'];
  s3Basename: Scalars['String'];
  viewsCounter: Scalars['Int'];
  isRequest: Scalars['Boolean'];
  topicNames: Array<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  userFollows?: Maybe<ModelUserFollowsDataConnection>;
  datasForward?: Maybe<ModelDataDataEdgeConnection>;
  datasBackward?: Maybe<ModelDataDataEdgeConnection>;
  ownerEdge: UserOwnsData;
  questions?: Maybe<ModelQuestionDataEdgeConnection>;
};

export type DataUserFollowsArgs = {
  userID?: Maybe<ModelIdKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsDataFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DataDatasForwardArgs = {
  score?: Maybe<ModelFloatKeyConditionInput>;
  filter?: Maybe<ModelDataDataEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DataDatasBackwardArgs = {
  score?: Maybe<ModelFloatKeyConditionInput>;
  filter?: Maybe<ModelDataDataEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DataQuestionsArgs = {
  score?: Maybe<ModelFloatKeyConditionInput>;
  filter?: Maybe<ModelQuestionDataEdgeFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserFollowsDataConnection = {
  items?: Maybe<Array<Maybe<UserFollowsData>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowsData = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  dataID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  user: User;
  data: Data;
};

export type ModelUserFollowsDataFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  userID?: Maybe<ModelIdInput>;
  dataID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsDataFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsDataFilterInput>>>;
  not?: Maybe<ModelUserFollowsDataFilterInput>;
};

export type ModelDataDataEdgeConnection = {
  items?: Maybe<Array<Maybe<DataDataEdge>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DataDataEdge = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  aID: Scalars['ID'];
  bID: Scalars['ID'];
  score: Scalars['Float'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  dataA: Data;
  dataB: Data;
};

export type ModelDataDataEdgeFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  aID?: Maybe<ModelIdInput>;
  bID?: Maybe<ModelIdInput>;
  score?: Maybe<ModelFloatInput>;
  and?: Maybe<Array<Maybe<ModelDataDataEdgeFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDataDataEdgeFilterInput>>>;
  not?: Maybe<ModelDataDataEdgeFilterInput>;
};

export type UserOwnsData = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  dataID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  user: User;
  data: Data;
};

export type ModelQuestionDataEdgeFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  questionID?: Maybe<ModelIdInput>;
  dataID?: Maybe<ModelIdInput>;
  score?: Maybe<ModelFloatInput>;
  and?: Maybe<Array<Maybe<ModelQuestionDataEdgeFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestionDataEdgeFilterInput>>>;
  not?: Maybe<ModelQuestionDataEdgeFilterInput>;
};

export type ModelStringKeyConditionInput = {
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
};

export type ModelUserOwnsQuestionFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  userID?: Maybe<ModelIdInput>;
  questionID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserOwnsQuestionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserOwnsQuestionFilterInput>>>;
  not?: Maybe<ModelUserOwnsQuestionFilterInput>;
};

export type ModelUserOwnsQuestTrainConnection = {
  items?: Maybe<Array<Maybe<UserOwnsQuestTrain>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserOwnsQuestTrainFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  userID?: Maybe<ModelIdInput>;
  questTrainID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserOwnsQuestTrainFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserOwnsQuestTrainFilterInput>>>;
  not?: Maybe<ModelUserOwnsQuestTrainFilterInput>;
};

export type ModelUserFollowsUserConnection = {
  items?: Maybe<Array<Maybe<UserFollowsUser>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowsUser = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  followerID: Scalars['ID'];
  followedID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  follower: User;
  followed: User;
};

export type ModelUserFollowsUserFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  followerID?: Maybe<ModelIdInput>;
  followedID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsUserFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsUserFilterInput>>>;
  not?: Maybe<ModelUserFollowsUserFilterInput>;
};

export type ModelUserOwnsDataConnection = {
  items?: Maybe<Array<Maybe<UserOwnsData>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserOwnsDataFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  userID?: Maybe<ModelIdInput>;
  dataID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserOwnsDataFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserOwnsDataFilterInput>>>;
  not?: Maybe<ModelUserOwnsDataFilterInput>;
};

export type ModelNotificationConnection = {
  items?: Maybe<Array<Maybe<Notification>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Notification = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  actorUserID: Scalars['ID'];
  actorQuestionID?: Maybe<Scalars['ID']>;
  actorDataID?: Maybe<Scalars['ID']>;
  followedQuestionID?: Maybe<Scalars['ID']>;
  followedDataID?: Maybe<Scalars['ID']>;
  read: Scalars['Boolean'];
  pushNotified: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  actorUser: User;
  actorQuestion?: Maybe<Question>;
  followedQuestion?: Maybe<Question>;
  actorData?: Maybe<Data>;
  followedData?: Maybe<Data>;
};

export type ModelNotificationFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  actorUserID?: Maybe<ModelIdInput>;
  actorQuestionID?: Maybe<ModelIdInput>;
  actorDataID?: Maybe<ModelIdInput>;
  followedQuestionID?: Maybe<ModelIdInput>;
  followedDataID?: Maybe<ModelIdInput>;
  read?: Maybe<ModelBooleanInput>;
  pushNotified?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelNotificationFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelNotificationFilterInput>>>;
  not?: Maybe<ModelNotificationFilterInput>;
};

export type ModelBooleanInput = {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
};

export type ModelUserFollowsTopicConnection = {
  items?: Maybe<Array<Maybe<UserFollowsTopic>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserFollowsTopic = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  topicID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  user: User;
  topic: Topic;
};

export type Topic = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  followerCount: Scalars['Int'];
  entityCount: Scalars['Int'];
  sortable: IsSortable;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  userFollows?: Maybe<ModelUserFollowsTopicConnection>;
};

export type TopicUserFollowsArgs = {
  updatedAt?: Maybe<ModelStringKeyConditionInput>;
  filter?: Maybe<ModelUserFollowsTopicFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum IsSortable {
  Yes = 'Yes',
  No = 'No',
}

export type ModelUserFollowsTopicFilterInput = {
  id?: Maybe<ModelIdInput>;
  updatedAt?: Maybe<ModelStringInput>;
  owner?: Maybe<ModelIdInput>;
  userID?: Maybe<ModelIdInput>;
  topicID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsTopicFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsTopicFilterInput>>>;
  not?: Maybe<ModelUserFollowsTopicFilterInput>;
};

export type ModelUserConnection = {
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  username?: Maybe<ModelIdInput>;
  email?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFilterInput>>>;
  not?: Maybe<ModelUserFilterInput>;
};

export type UsernameLookup = {
  owner: Scalars['ID'];
  username: Scalars['ID'];
  userID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type EmailLookup = {
  owner: Scalars['ID'];
  email: Scalars['ID'];
  userID: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type ModelQuestionConnection = {
  items?: Maybe<Array<Maybe<Question>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelQuestionFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  text?: Maybe<ModelStringInput>;
  viewsCounter?: Maybe<ModelIntInput>;
  topicNames?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelQuestionFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestionFilterInput>>>;
  not?: Maybe<ModelQuestionFilterInput>;
};

export type ModelIntInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  attributeExists?: Maybe<Scalars['Boolean']>;
  attributeType?: Maybe<ModelAttributeTypes>;
};

export type ModelQuestTrainConnection = {
  items?: Maybe<Array<Maybe<QuestTrain>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelQuestTrainFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  viewsCounter?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelQuestTrainFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestTrainFilterInput>>>;
  not?: Maybe<ModelQuestTrainFilterInput>;
};

export type ModelDataConnection = {
  items?: Maybe<Array<Maybe<Data>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDataFilterInput = {
  id?: Maybe<ModelIdInput>;
  owner?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  snippet?: Maybe<ModelStringInput>;
  numRows?: Maybe<ModelIntInput>;
  fileSizeBytes?: Maybe<ModelIntInput>;
  notes?: Maybe<ModelStringInput>;
  s3Bucket?: Maybe<ModelStringInput>;
  s3Key?: Maybe<ModelStringInput>;
  s3Prefix?: Maybe<ModelStringInput>;
  s3Basename?: Maybe<ModelStringInput>;
  viewsCounter?: Maybe<ModelIntInput>;
  isRequest?: Maybe<ModelBooleanInput>;
  topicNames?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDataFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelDataFilterInput>>>;
  not?: Maybe<ModelDataFilterInput>;
};

export type ModelTopicConnection = {
  items?: Maybe<Array<Maybe<Topic>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelTopicFilterInput = {
  id?: Maybe<ModelIdInput>;
  name?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  followerCount?: Maybe<ModelIntInput>;
  entityCount?: Maybe<ModelIntInput>;
  sortable?: Maybe<ModelIsSortableInput>;
  and?: Maybe<Array<Maybe<ModelTopicFilterInput>>>;
  or?: Maybe<Array<Maybe<ModelTopicFilterInput>>>;
  not?: Maybe<ModelTopicFilterInput>;
};

export type ModelIsSortableInput = {
  eq?: Maybe<IsSortable>;
  ne?: Maybe<IsSortable>;
};

export type ModelIntKeyConditionInput = {
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Mutation = {
  createQuestTrainManaged: Scalars['ID'];
  updateQuestTrainManaged: Scalars['ID'];
  incrementQuestionViews: Scalars['Int'];
  incrementQuestTrainViews: Scalars['Int'];
  incrementDataViews: Scalars['Int'];
  deleteQuestion: Scalars['ID'];
  deleteData: Scalars['ID'];
  updateQuestionFollower: LinkResult;
  updateDataFollower: LinkResult;
  updateUserFollower: LinkResult;
  processTrainCars: Array<ProcessTrainCarResult>;
  updateQuestionManaged: UpdateQuestionManagedResult;
  updateDataManaged: CreateDataManagedResult;
  updateDataMetadataManaged: CreateDataManagedResult;
  createUserOwnsQuestion?: Maybe<UserOwnsQuestion>;
  createUserOwnsQuestTrain?: Maybe<UserOwnsQuestTrain>;
  createUserFollowsQuestion?: Maybe<UserFollowsQuestion>;
  createUserFollowsData?: Maybe<UserFollowsData>;
  createUserFollowsUser?: Maybe<UserFollowsUser>;
  createQuestion?: Maybe<Question>;
  updateQuestion?: Maybe<Question>;
  createQuestionEdge?: Maybe<QuestionEdge>;
  createDataDataEdge?: Maybe<DataDataEdge>;
  createQuestTrain?: Maybe<QuestTrain>;
  updateQuestTrain?: Maybe<QuestTrain>;
  createQuestTrainQuestionEdge?: Maybe<QuestTrainQuestionEdge>;
  createData?: Maybe<Data>;
  updateData?: Maybe<Data>;
  createUserOwnsData?: Maybe<UserOwnsData>;
  createQuestionDataEdge?: Maybe<QuestionDataEdge>;
  createNotification?: Maybe<Notification>;
  updateNotification?: Maybe<Notification>;
  createTopic?: Maybe<Topic>;
  createUserFollowsTopic?: Maybe<UserFollowsTopic>;
};

export type MutationCreateQuestTrainManagedArgs = {
  carSpecs: Array<CarSpecInput>;
  summary?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateQuestTrainManagedArgs = {
  carSpecs: Array<CarSpecInput>;
  summary?: Maybe<Scalars['Boolean']>;
};

export type MutationIncrementQuestionViewsArgs = {
  id: Scalars['ID'];
};

export type MutationIncrementQuestTrainViewsArgs = {
  id: Scalars['ID'];
};

export type MutationIncrementDataViewsArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDataArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateQuestionFollowerArgs = {
  followedID: Scalars['ID'];
  linked: Scalars['Boolean'];
};

export type MutationUpdateDataFollowerArgs = {
  followedID: Scalars['ID'];
  linked: Scalars['Boolean'];
};

export type MutationUpdateUserFollowerArgs = {
  followedID: Scalars['ID'];
  linked: Scalars['Boolean'];
};

export type MutationProcessTrainCarsArgs = {
  input: ProcessTrainCarsInput;
};

export type MutationUpdateQuestionManagedArgs = {
  input: UpdateQuestionManagedInput;
};

export type MutationUpdateDataManagedArgs = {
  input: UpdateDataManagedInput;
};

export type MutationUpdateDataMetadataManagedArgs = {
  input: UpdateDataMetadataManagedInput;
};

export type MutationCreateUserOwnsQuestionArgs = {
  input: CreateUserOwnsQuestionInput;
  condition?: Maybe<ModelUserOwnsQuestionConditionInput>;
};

export type MutationCreateUserOwnsQuestTrainArgs = {
  input: CreateUserOwnsQuestTrainInput;
  condition?: Maybe<ModelUserOwnsQuestTrainConditionInput>;
};

export type MutationCreateUserFollowsQuestionArgs = {
  input: CreateUserFollowsQuestionInput;
  condition?: Maybe<ModelUserFollowsQuestionConditionInput>;
};

export type MutationCreateUserFollowsDataArgs = {
  input: CreateUserFollowsDataInput;
  condition?: Maybe<ModelUserFollowsDataConditionInput>;
};

export type MutationCreateUserFollowsUserArgs = {
  input: CreateUserFollowsUserInput;
  condition?: Maybe<ModelUserFollowsUserConditionInput>;
};

export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput;
  condition?: Maybe<ModelQuestionConditionInput>;
};

export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
  condition?: Maybe<ModelQuestionConditionInput>;
};

export type MutationCreateQuestionEdgeArgs = {
  input: CreateQuestionEdgeInput;
  condition?: Maybe<ModelQuestionEdgeConditionInput>;
};

export type MutationCreateDataDataEdgeArgs = {
  input: CreateDataDataEdgeInput;
  condition?: Maybe<ModelDataDataEdgeConditionInput>;
};

export type MutationCreateQuestTrainArgs = {
  input: CreateQuestTrainInput;
  condition?: Maybe<ModelQuestTrainConditionInput>;
};

export type MutationUpdateQuestTrainArgs = {
  input: UpdateQuestTrainInput;
  condition?: Maybe<ModelQuestTrainConditionInput>;
};

export type MutationCreateQuestTrainQuestionEdgeArgs = {
  input: CreateQuestTrainQuestionEdgeInput;
  condition?: Maybe<ModelQuestTrainQuestionEdgeConditionInput>;
};

export type MutationCreateDataArgs = {
  input: CreateDataInput;
  condition?: Maybe<ModelDataConditionInput>;
};

export type MutationUpdateDataArgs = {
  input: UpdateDataInput;
  condition?: Maybe<ModelDataConditionInput>;
};

export type MutationCreateUserOwnsDataArgs = {
  input: CreateUserOwnsDataInput;
  condition?: Maybe<ModelUserOwnsDataConditionInput>;
};

export type MutationCreateQuestionDataEdgeArgs = {
  input: CreateQuestionDataEdgeInput;
  condition?: Maybe<ModelQuestionDataEdgeConditionInput>;
};

export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
  condition?: Maybe<ModelNotificationConditionInput>;
};

export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput;
  condition?: Maybe<ModelNotificationConditionInput>;
};

export type MutationCreateTopicArgs = {
  input: CreateTopicInput;
  condition?: Maybe<ModelTopicConditionInput>;
};

export type MutationCreateUserFollowsTopicArgs = {
  input: CreateUserFollowsTopicInput;
  condition?: Maybe<ModelUserFollowsTopicConditionInput>;
};

export type CarSpecInput = {
  id: Scalars['ID'];
  carType: CarType;
};

export type LinkResult = {
  id?: Maybe<Scalars['ID']>;
  beforeLinked: Scalars['Boolean'];
  afterLinked: Scalars['Boolean'];
  afterScore?: Maybe<Scalars['Float']>;
};

export type ProcessTrainCarResult = {
  id: Scalars['ID'];
  carType: CarType;
  createdNew: Scalars['Boolean'];
};

export type ProcessTrainCarsInput = {
  trainCarInputs: Array<TrainCarInput>;
};

export type TrainCarInput = {
  carType: CarType;
  createNew?: Maybe<CreateNewInput>;
  useExisting?: Maybe<UseExistingInput>;
};

export type CreateNewInput = {
  questionInput?: Maybe<CreateQuestionManagedInput>;
  dataInput?: Maybe<CreateDataManagedInput>;
};

export type CreateQuestionManagedInput = {
  text: Scalars['String'];
  topicNames: Array<Scalars['String']>;
};

export type CreateDataManagedInput = {
  name: Scalars['String'];
  prefix: Scalars['String'];
  basename: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  references?: Maybe<Array<ReferenceInput>>;
  isRequest: Scalars['Boolean'];
  topicNames: Array<Scalars['String']>;
};

export type ReferenceInput = {
  name: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};

export type UseExistingInput = {
  id: Scalars['ID'];
};

export type UpdateQuestionManagedResult = {
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  text: Scalars['String'];
  topicNames: Array<Scalars['String']>;
};

export type UpdateQuestionManagedInput = {
  id: Scalars['ID'];
  text: Scalars['String'];
  topicNames: Array<Scalars['String']>;
};

export type CreateDataManagedResult = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  ownerUsername: Scalars['ID'];
  name: Scalars['String'];
  header: Array<HeaderColumn>;
  snippet?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  viewsCounter: Scalars['Int'];
  isRequest: Scalars['Boolean'];
};

export type UpdateDataManagedInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  basename: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  references?: Maybe<Array<ReferenceInput>>;
  isRequest: Scalars['Boolean'];
  topicNames: Array<Scalars['String']>;
};

export type UpdateDataMetadataManagedInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  references?: Maybe<Array<ReferenceInput>>;
  topicNames: Array<Scalars['String']>;
};

export type CreateUserOwnsQuestionInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  questionID: Scalars['ID'];
};

export type ModelUserOwnsQuestionConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  userID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserOwnsQuestionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserOwnsQuestionConditionInput>>>;
  not?: Maybe<ModelUserOwnsQuestionConditionInput>;
};

export type CreateUserOwnsQuestTrainInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  questTrainID: Scalars['ID'];
};

export type ModelUserOwnsQuestTrainConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  userID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserOwnsQuestTrainConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserOwnsQuestTrainConditionInput>>>;
  not?: Maybe<ModelUserOwnsQuestTrainConditionInput>;
};

export type CreateUserFollowsQuestionInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  questionID: Scalars['ID'];
};

export type ModelUserFollowsQuestionConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  userID?: Maybe<ModelIdInput>;
  questionID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsQuestionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsQuestionConditionInput>>>;
  not?: Maybe<ModelUserFollowsQuestionConditionInput>;
};

export type CreateUserFollowsDataInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  dataID: Scalars['ID'];
};

export type ModelUserFollowsDataConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  userID?: Maybe<ModelIdInput>;
  dataID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsDataConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsDataConditionInput>>>;
  not?: Maybe<ModelUserFollowsDataConditionInput>;
};

export type CreateUserFollowsUserInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  followerID: Scalars['ID'];
  followedID: Scalars['ID'];
};

export type ModelUserFollowsUserConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  followerID?: Maybe<ModelIdInput>;
  followedID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsUserConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsUserConditionInput>>>;
  not?: Maybe<ModelUserFollowsUserConditionInput>;
};

export type CreateQuestionInput = {
  id?: Maybe<Scalars['ID']>;
  owner: Scalars['ID'];
  text: Scalars['String'];
  viewsCounter: Scalars['Int'];
  topicNames: Array<Scalars['String']>;
};

export type ModelQuestionConditionInput = {
  text?: Maybe<ModelStringInput>;
  viewsCounter?: Maybe<ModelIntInput>;
  topicNames?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelQuestionConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestionConditionInput>>>;
  not?: Maybe<ModelQuestionConditionInput>;
};

export type UpdateQuestionInput = {
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
  viewsCounter?: Maybe<Scalars['Int']>;
  topicNames?: Maybe<Array<Scalars['String']>>;
};

export type CreateQuestionEdgeInput = {
  id?: Maybe<Scalars['ID']>;
  owner: Scalars['ID'];
  aID: Scalars['ID'];
  bID: Scalars['ID'];
  score: Scalars['Float'];
};

export type ModelQuestionEdgeConditionInput = {
  aID?: Maybe<ModelIdInput>;
  bID?: Maybe<ModelIdInput>;
  score?: Maybe<ModelFloatInput>;
  and?: Maybe<Array<Maybe<ModelQuestionEdgeConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestionEdgeConditionInput>>>;
  not?: Maybe<ModelQuestionEdgeConditionInput>;
};

export type CreateDataDataEdgeInput = {
  id?: Maybe<Scalars['ID']>;
  owner: Scalars['ID'];
  aID: Scalars['ID'];
  bID: Scalars['ID'];
  score: Scalars['Float'];
};

export type ModelDataDataEdgeConditionInput = {
  aID?: Maybe<ModelIdInput>;
  bID?: Maybe<ModelIdInput>;
  score?: Maybe<ModelFloatInput>;
  and?: Maybe<Array<Maybe<ModelDataDataEdgeConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDataDataEdgeConditionInput>>>;
  not?: Maybe<ModelDataDataEdgeConditionInput>;
};

export type CreateQuestTrainInput = {
  id?: Maybe<Scalars['ID']>;
  owner: Scalars['ID'];
  carSpecs: Array<CarSpecInput>;
  viewsCounter: Scalars['Int'];
};

export type ModelQuestTrainConditionInput = {
  viewsCounter?: Maybe<ModelIntInput>;
  and?: Maybe<Array<Maybe<ModelQuestTrainConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestTrainConditionInput>>>;
  not?: Maybe<ModelQuestTrainConditionInput>;
};

export type UpdateQuestTrainInput = {
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  carSpecs?: Maybe<Array<CarSpecInput>>;
  viewsCounter?: Maybe<Scalars['Int']>;
};

export type CreateQuestTrainQuestionEdgeInput = {
  id?: Maybe<Scalars['ID']>;
  owner: Scalars['ID'];
  questTrainID: Scalars['ID'];
  questionID: Scalars['ID'];
};

export type ModelQuestTrainQuestionEdgeConditionInput = {
  questTrainID?: Maybe<ModelIdInput>;
  questionID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelQuestTrainQuestionEdgeConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestTrainQuestionEdgeConditionInput>>>;
  not?: Maybe<ModelQuestTrainQuestionEdgeConditionInput>;
};

export type CreateDataInput = {
  id?: Maybe<Scalars['ID']>;
  owner: Scalars['ID'];
  name: Scalars['String'];
  header: Array<HeaderColumnInput>;
  snippet?: Maybe<Scalars['String']>;
  numRows: Scalars['Int'];
  fileSizeBytes: Scalars['Int'];
  references?: Maybe<Array<ReferenceInput>>;
  notes?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  s3Key: Scalars['String'];
  s3Prefix: Scalars['String'];
  s3Basename: Scalars['String'];
  viewsCounter: Scalars['Int'];
  isRequest: Scalars['Boolean'];
  topicNames: Array<Scalars['String']>;
};

export type HeaderColumnInput = {
  name: Scalars['String'];
  columnType: ColumnType;
  required?: Maybe<Scalars['Boolean']>;
  exampleValue: Scalars['String'];
};

export type ModelDataConditionInput = {
  name?: Maybe<ModelStringInput>;
  snippet?: Maybe<ModelStringInput>;
  numRows?: Maybe<ModelIntInput>;
  fileSizeBytes?: Maybe<ModelIntInput>;
  notes?: Maybe<ModelStringInput>;
  s3Bucket?: Maybe<ModelStringInput>;
  s3Key?: Maybe<ModelStringInput>;
  s3Prefix?: Maybe<ModelStringInput>;
  s3Basename?: Maybe<ModelStringInput>;
  viewsCounter?: Maybe<ModelIntInput>;
  isRequest?: Maybe<ModelBooleanInput>;
  topicNames?: Maybe<ModelStringInput>;
  and?: Maybe<Array<Maybe<ModelDataConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelDataConditionInput>>>;
  not?: Maybe<ModelDataConditionInput>;
};

export type UpdateDataInput = {
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  header?: Maybe<Array<HeaderColumnInput>>;
  snippet?: Maybe<Scalars['String']>;
  numRows?: Maybe<Scalars['Int']>;
  fileSizeBytes?: Maybe<Scalars['Int']>;
  references?: Maybe<Array<ReferenceInput>>;
  notes?: Maybe<Scalars['String']>;
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  s3Prefix?: Maybe<Scalars['String']>;
  s3Basename?: Maybe<Scalars['String']>;
  viewsCounter?: Maybe<Scalars['Int']>;
  isRequest?: Maybe<Scalars['Boolean']>;
  topicNames?: Maybe<Array<Scalars['String']>>;
};

export type CreateUserOwnsDataInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  dataID: Scalars['ID'];
};

export type ModelUserOwnsDataConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  userID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserOwnsDataConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserOwnsDataConditionInput>>>;
  not?: Maybe<ModelUserOwnsDataConditionInput>;
};

export type CreateQuestionDataEdgeInput = {
  id?: Maybe<Scalars['ID']>;
  owner: Scalars['ID'];
  questionID: Scalars['ID'];
  dataID: Scalars['ID'];
  score: Scalars['Float'];
};

export type ModelQuestionDataEdgeConditionInput = {
  questionID?: Maybe<ModelIdInput>;
  dataID?: Maybe<ModelIdInput>;
  score?: Maybe<ModelFloatInput>;
  and?: Maybe<Array<Maybe<ModelQuestionDataEdgeConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelQuestionDataEdgeConditionInput>>>;
  not?: Maybe<ModelQuestionDataEdgeConditionInput>;
};

export type CreateNotificationInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  actorUserID: Scalars['ID'];
  actorQuestionID?: Maybe<Scalars['ID']>;
  actorDataID?: Maybe<Scalars['ID']>;
  followedQuestionID?: Maybe<Scalars['ID']>;
  followedDataID?: Maybe<Scalars['ID']>;
  read: Scalars['Boolean'];
  pushNotified: Scalars['Boolean'];
};

export type ModelNotificationConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  actorUserID?: Maybe<ModelIdInput>;
  actorQuestionID?: Maybe<ModelIdInput>;
  actorDataID?: Maybe<ModelIdInput>;
  followedQuestionID?: Maybe<ModelIdInput>;
  followedDataID?: Maybe<ModelIdInput>;
  read?: Maybe<ModelBooleanInput>;
  pushNotified?: Maybe<ModelBooleanInput>;
  and?: Maybe<Array<Maybe<ModelNotificationConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelNotificationConditionInput>>>;
  not?: Maybe<ModelNotificationConditionInput>;
};

export type UpdateNotificationInput = {
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner?: Maybe<Scalars['ID']>;
  actorUserID?: Maybe<Scalars['ID']>;
  actorQuestionID?: Maybe<Scalars['ID']>;
  actorDataID?: Maybe<Scalars['ID']>;
  followedQuestionID?: Maybe<Scalars['ID']>;
  followedDataID?: Maybe<Scalars['ID']>;
  read?: Maybe<Scalars['Boolean']>;
  pushNotified?: Maybe<Scalars['Boolean']>;
};

export type CreateTopicInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  followerCount: Scalars['Int'];
  entityCount: Scalars['Int'];
  sortable: IsSortable;
};

export type ModelTopicConditionInput = {
  name?: Maybe<ModelStringInput>;
  description?: Maybe<ModelStringInput>;
  followerCount?: Maybe<ModelIntInput>;
  entityCount?: Maybe<ModelIntInput>;
  sortable?: Maybe<ModelIsSortableInput>;
  and?: Maybe<Array<Maybe<ModelTopicConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelTopicConditionInput>>>;
  not?: Maybe<ModelTopicConditionInput>;
};

export type CreateUserFollowsTopicInput = {
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  owner: Scalars['ID'];
  userID: Scalars['ID'];
  topicID: Scalars['ID'];
};

export type ModelUserFollowsTopicConditionInput = {
  updatedAt?: Maybe<ModelStringInput>;
  userID?: Maybe<ModelIdInput>;
  topicID?: Maybe<ModelIdInput>;
  and?: Maybe<Array<Maybe<ModelUserFollowsTopicConditionInput>>>;
  or?: Maybe<Array<Maybe<ModelUserFollowsTopicConditionInput>>>;
  not?: Maybe<ModelUserFollowsTopicConditionInput>;
};

export type TopicEntityEdge = {
  id: Scalars['ID'];
  owner: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  topicID: Scalars['ID'];
  questionID?: Maybe<Scalars['ID']>;
  dataID?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
};

export type CreateQuestTrainManagedMutationVariables = Exact<{
  carSpecs: Array<CarSpecInput> | CarSpecInput;
  summary?: Maybe<Scalars['Boolean']>;
}>;

export type CreateQuestTrainManagedMutation = Pick<Mutation, 'createQuestTrainManaged'>;

export type UpdateQuestTrainManagedMutationVariables = Exact<{
  carSpecs: Array<CarSpecInput> | CarSpecInput;
  summary?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateQuestTrainManagedMutation = Pick<Mutation, 'updateQuestTrainManaged'>;

export type IncrementQuestionViewsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IncrementQuestionViewsMutation = Pick<Mutation, 'incrementQuestionViews'>;

export type IncrementQuestTrainViewsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IncrementQuestTrainViewsMutation = Pick<
  Mutation,
  'incrementQuestTrainViews'
>;

export type IncrementDataViewsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IncrementDataViewsMutation = Pick<Mutation, 'incrementDataViews'>;

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteQuestionMutation = Pick<Mutation, 'deleteQuestion'>;

export type DeleteDataMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteDataMutation = Pick<Mutation, 'deleteData'>;

export type UpdateQuestionFollowerMutationVariables = Exact<{
  followedID: Scalars['ID'];
  linked: Scalars['Boolean'];
}>;

export type UpdateQuestionFollowerMutation = {
  updateQuestionFollower: Pick<
    LinkResult,
    'id' | 'beforeLinked' | 'afterLinked' | 'afterScore'
  >;
};

export type UpdateDataFollowerMutationVariables = Exact<{
  followedID: Scalars['ID'];
  linked: Scalars['Boolean'];
}>;

export type UpdateDataFollowerMutation = {
  updateDataFollower: Pick<
    LinkResult,
    'id' | 'beforeLinked' | 'afterLinked' | 'afterScore'
  >;
};

export type UpdateUserFollowerMutationVariables = Exact<{
  followedID: Scalars['ID'];
  linked: Scalars['Boolean'];
}>;

export type UpdateUserFollowerMutation = {
  updateUserFollower: Pick<
    LinkResult,
    'id' | 'beforeLinked' | 'afterLinked' | 'afterScore'
  >;
};

export type ProcessTrainCarsMutationVariables = Exact<{
  input: ProcessTrainCarsInput;
}>;

export type ProcessTrainCarsMutation = {
  processTrainCars: Array<Pick<ProcessTrainCarResult, 'id' | 'carType' | 'createdNew'>>;
};

export type UpdateQuestionManagedMutationVariables = Exact<{
  input: UpdateQuestionManagedInput;
}>;

export type UpdateQuestionManagedMutation = {
  updateQuestionManaged: Pick<
    UpdateQuestionManagedResult,
    'id' | 'updatedAt' | 'text' | 'topicNames'
  >;
};

export type UpdateDataManagedMutationVariables = Exact<{
  input: UpdateDataManagedInput;
}>;

export type UpdateDataManagedMutation = {
  updateDataManaged: Pick<
    CreateDataManagedResult,
    | 'id'
    | 'owner'
    | 'ownerUsername'
    | 'name'
    | 'snippet'
    | 'notes'
    | 's3Bucket'
    | 's3Key'
    | 'viewsCounter'
    | 'isRequest'
  > & {
    header: Array<
      Pick<HeaderColumn, 'name' | 'columnType' | 'required' | 'exampleValue'>
    >;
  };
};

export type UpdateDataMetadataManagedMutationVariables = Exact<{
  input: UpdateDataMetadataManagedInput;
}>;

export type UpdateDataMetadataManagedMutation = {
  updateDataMetadataManaged: Pick<
    CreateDataManagedResult,
    | 'id'
    | 'owner'
    | 'ownerUsername'
    | 'name'
    | 'snippet'
    | 'notes'
    | 's3Bucket'
    | 's3Key'
    | 'viewsCounter'
    | 'isRequest'
  > & {
    header: Array<
      Pick<HeaderColumn, 'name' | 'columnType' | 'required' | 'exampleValue'>
    >;
  };
};

export type CreateUserOwnsQuestionMutationVariables = Exact<{
  input: CreateUserOwnsQuestionInput;
  condition?: Maybe<ModelUserOwnsQuestionConditionInput>;
}>;

export type CreateUserOwnsQuestionMutation = {
  createUserOwnsQuestion?: Maybe<
    Pick<
      UserOwnsQuestion,
      'id' | 'updatedAt' | 'owner' | 'userID' | 'questionID' | 'createdAt'
    > & {
      user: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      question: Pick<
        Question,
        | 'id'
        | 'owner'
        | 'text'
        | 'viewsCounter'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type CreateUserOwnsQuestTrainMutationVariables = Exact<{
  input: CreateUserOwnsQuestTrainInput;
  condition?: Maybe<ModelUserOwnsQuestTrainConditionInput>;
}>;

export type CreateUserOwnsQuestTrainMutation = {
  createUserOwnsQuestTrain?: Maybe<
    Pick<
      UserOwnsQuestTrain,
      'id' | 'updatedAt' | 'owner' | 'userID' | 'questTrainID' | 'createdAt'
    > & {
      user: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      questTrain: Pick<
        QuestTrain,
        'id' | 'owner' | 'viewsCounter' | 'createdAt' | 'updatedAt'
      >;
    }
  >;
};

export type CreateUserFollowsQuestionMutationVariables = Exact<{
  input: CreateUserFollowsQuestionInput;
  condition?: Maybe<ModelUserFollowsQuestionConditionInput>;
}>;

export type CreateUserFollowsQuestionMutation = {
  createUserFollowsQuestion?: Maybe<
    Pick<
      UserFollowsQuestion,
      'id' | 'updatedAt' | 'owner' | 'userID' | 'questionID' | 'createdAt'
    > & {
      user: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      question: Pick<
        Question,
        | 'id'
        | 'owner'
        | 'text'
        | 'viewsCounter'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type CreateUserFollowsDataMutationVariables = Exact<{
  input: CreateUserFollowsDataInput;
  condition?: Maybe<ModelUserFollowsDataConditionInput>;
}>;

export type CreateUserFollowsDataMutation = {
  createUserFollowsData?: Maybe<
    Pick<
      UserFollowsData,
      'id' | 'updatedAt' | 'owner' | 'userID' | 'dataID' | 'createdAt'
    > & {
      user: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      data: Pick<
        Data,
        | 'id'
        | 'owner'
        | 'name'
        | 'snippet'
        | 'numRows'
        | 'fileSizeBytes'
        | 'notes'
        | 's3Bucket'
        | 's3Key'
        | 's3Prefix'
        | 's3Basename'
        | 'viewsCounter'
        | 'isRequest'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type CreateUserFollowsUserMutationVariables = Exact<{
  input: CreateUserFollowsUserInput;
  condition?: Maybe<ModelUserFollowsUserConditionInput>;
}>;

export type CreateUserFollowsUserMutation = {
  createUserFollowsUser?: Maybe<
    Pick<
      UserFollowsUser,
      'id' | 'updatedAt' | 'owner' | 'followerID' | 'followedID' | 'createdAt'
    > & {
      follower: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      followed: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
    }
  >;
};

export type CreateQuestionMutationVariables = Exact<{
  input: CreateQuestionInput;
  condition?: Maybe<ModelQuestionConditionInput>;
}>;

export type CreateQuestionMutation = {
  createQuestion?: Maybe<
    Pick<
      Question,
      | 'id'
      | 'owner'
      | 'text'
      | 'viewsCounter'
      | 'topicNames'
      | 'createdAt'
      | 'updatedAt'
    > & {
      ownerEdge: Pick<
        UserOwnsQuestion,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'questionID' | 'createdAt'
      >;
      userFollows?: Maybe<Pick<ModelUserFollowsQuestionConnection, 'nextToken'>>;
      questionsForward?: Maybe<Pick<ModelQuestionEdgeConnection, 'nextToken'>>;
      questionsBackward?: Maybe<Pick<ModelQuestionEdgeConnection, 'nextToken'>>;
      questTrains?: Maybe<Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'>>;
      data?: Maybe<Pick<ModelQuestionDataEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type UpdateQuestionMutationVariables = Exact<{
  input: UpdateQuestionInput;
  condition?: Maybe<ModelQuestionConditionInput>;
}>;

export type UpdateQuestionMutation = {
  updateQuestion?: Maybe<
    Pick<
      Question,
      | 'id'
      | 'owner'
      | 'text'
      | 'viewsCounter'
      | 'topicNames'
      | 'createdAt'
      | 'updatedAt'
    > & {
      ownerEdge: Pick<
        UserOwnsQuestion,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'questionID' | 'createdAt'
      >;
      userFollows?: Maybe<Pick<ModelUserFollowsQuestionConnection, 'nextToken'>>;
      questionsForward?: Maybe<Pick<ModelQuestionEdgeConnection, 'nextToken'>>;
      questionsBackward?: Maybe<Pick<ModelQuestionEdgeConnection, 'nextToken'>>;
      questTrains?: Maybe<Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'>>;
      data?: Maybe<Pick<ModelQuestionDataEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type CreateQuestionEdgeMutationVariables = Exact<{
  input: CreateQuestionEdgeInput;
  condition?: Maybe<ModelQuestionEdgeConditionInput>;
}>;

export type CreateQuestionEdgeMutation = {
  createQuestionEdge?: Maybe<
    Pick<
      QuestionEdge,
      'id' | 'owner' | 'aID' | 'bID' | 'score' | 'createdAt' | 'updatedAt'
    > & {
      questionA: Pick<
        Question,
        | 'id'
        | 'owner'
        | 'text'
        | 'viewsCounter'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
      questionB: Pick<
        Question,
        | 'id'
        | 'owner'
        | 'text'
        | 'viewsCounter'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type CreateDataDataEdgeMutationVariables = Exact<{
  input: CreateDataDataEdgeInput;
  condition?: Maybe<ModelDataDataEdgeConditionInput>;
}>;

export type CreateDataDataEdgeMutation = {
  createDataDataEdge?: Maybe<
    Pick<
      DataDataEdge,
      'id' | 'owner' | 'aID' | 'bID' | 'score' | 'createdAt' | 'updatedAt'
    > & {
      dataA: Pick<
        Data,
        | 'id'
        | 'owner'
        | 'name'
        | 'snippet'
        | 'numRows'
        | 'fileSizeBytes'
        | 'notes'
        | 's3Bucket'
        | 's3Key'
        | 's3Prefix'
        | 's3Basename'
        | 'viewsCounter'
        | 'isRequest'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
      dataB: Pick<
        Data,
        | 'id'
        | 'owner'
        | 'name'
        | 'snippet'
        | 'numRows'
        | 'fileSizeBytes'
        | 'notes'
        | 's3Bucket'
        | 's3Key'
        | 's3Prefix'
        | 's3Basename'
        | 'viewsCounter'
        | 'isRequest'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type CreateQuestTrainMutationVariables = Exact<{
  input: CreateQuestTrainInput;
  condition?: Maybe<ModelQuestTrainConditionInput>;
}>;

export type CreateQuestTrainMutation = {
  createQuestTrain?: Maybe<
    Pick<QuestTrain, 'id' | 'owner' | 'viewsCounter' | 'createdAt' | 'updatedAt'> & {
      carSpecs: Array<Pick<CarSpec, 'id' | 'carType'>>;
      ownerEdge: Pick<
        UserOwnsQuestTrain,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'questTrainID' | 'createdAt'
      >;
      questions?: Maybe<Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type UpdateQuestTrainMutationVariables = Exact<{
  input: UpdateQuestTrainInput;
  condition?: Maybe<ModelQuestTrainConditionInput>;
}>;

export type UpdateQuestTrainMutation = {
  updateQuestTrain?: Maybe<
    Pick<QuestTrain, 'id' | 'owner' | 'viewsCounter' | 'createdAt' | 'updatedAt'> & {
      carSpecs: Array<Pick<CarSpec, 'id' | 'carType'>>;
      ownerEdge: Pick<
        UserOwnsQuestTrain,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'questTrainID' | 'createdAt'
      >;
      questions?: Maybe<Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type CreateQuestTrainQuestionEdgeMutationVariables = Exact<{
  input: CreateQuestTrainQuestionEdgeInput;
  condition?: Maybe<ModelQuestTrainQuestionEdgeConditionInput>;
}>;

export type CreateQuestTrainQuestionEdgeMutation = {
  createQuestTrainQuestionEdge?: Maybe<
    Pick<
      QuestTrainQuestionEdge,
      'id' | 'owner' | 'questTrainID' | 'questionID' | 'createdAt' | 'updatedAt'
    > & {
      question: Pick<
        Question,
        | 'id'
        | 'owner'
        | 'text'
        | 'viewsCounter'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
      questTrain: Pick<
        QuestTrain,
        'id' | 'owner' | 'viewsCounter' | 'createdAt' | 'updatedAt'
      >;
    }
  >;
};

export type CreateDataMutationVariables = Exact<{
  input: CreateDataInput;
  condition?: Maybe<ModelDataConditionInput>;
}>;

export type CreateDataMutation = {
  createData?: Maybe<
    Pick<
      Data,
      | 'id'
      | 'owner'
      | 'name'
      | 'snippet'
      | 'numRows'
      | 'fileSizeBytes'
      | 'notes'
      | 's3Bucket'
      | 's3Key'
      | 's3Prefix'
      | 's3Basename'
      | 'viewsCounter'
      | 'isRequest'
      | 'topicNames'
      | 'createdAt'
      | 'updatedAt'
    > & {
      header: Array<
        Pick<HeaderColumn, 'name' | 'columnType' | 'required' | 'exampleValue'>
      >;
      references?: Maybe<Array<Pick<Reference, 'name' | 'link'>>>;
      userFollows?: Maybe<Pick<ModelUserFollowsDataConnection, 'nextToken'>>;
      datasForward?: Maybe<Pick<ModelDataDataEdgeConnection, 'nextToken'>>;
      datasBackward?: Maybe<Pick<ModelDataDataEdgeConnection, 'nextToken'>>;
      ownerEdge: Pick<
        UserOwnsData,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'dataID' | 'createdAt'
      >;
      questions?: Maybe<Pick<ModelQuestionDataEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type UpdateDataMutationVariables = Exact<{
  input: UpdateDataInput;
  condition?: Maybe<ModelDataConditionInput>;
}>;

export type UpdateDataMutation = {
  updateData?: Maybe<
    Pick<
      Data,
      | 'id'
      | 'owner'
      | 'name'
      | 'snippet'
      | 'numRows'
      | 'fileSizeBytes'
      | 'notes'
      | 's3Bucket'
      | 's3Key'
      | 's3Prefix'
      | 's3Basename'
      | 'viewsCounter'
      | 'isRequest'
      | 'topicNames'
      | 'createdAt'
      | 'updatedAt'
    > & {
      header: Array<
        Pick<HeaderColumn, 'name' | 'columnType' | 'required' | 'exampleValue'>
      >;
      references?: Maybe<Array<Pick<Reference, 'name' | 'link'>>>;
      userFollows?: Maybe<Pick<ModelUserFollowsDataConnection, 'nextToken'>>;
      datasForward?: Maybe<Pick<ModelDataDataEdgeConnection, 'nextToken'>>;
      datasBackward?: Maybe<Pick<ModelDataDataEdgeConnection, 'nextToken'>>;
      ownerEdge: Pick<
        UserOwnsData,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'dataID' | 'createdAt'
      >;
      questions?: Maybe<Pick<ModelQuestionDataEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type CreateUserOwnsDataMutationVariables = Exact<{
  input: CreateUserOwnsDataInput;
  condition?: Maybe<ModelUserOwnsDataConditionInput>;
}>;

export type CreateUserOwnsDataMutation = {
  createUserOwnsData?: Maybe<
    Pick<
      UserOwnsData,
      'id' | 'updatedAt' | 'owner' | 'userID' | 'dataID' | 'createdAt'
    > & {
      user: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      data: Pick<
        Data,
        | 'id'
        | 'owner'
        | 'name'
        | 'snippet'
        | 'numRows'
        | 'fileSizeBytes'
        | 'notes'
        | 's3Bucket'
        | 's3Key'
        | 's3Prefix'
        | 's3Basename'
        | 'viewsCounter'
        | 'isRequest'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type CreateQuestionDataEdgeMutationVariables = Exact<{
  input: CreateQuestionDataEdgeInput;
  condition?: Maybe<ModelQuestionDataEdgeConditionInput>;
}>;

export type CreateQuestionDataEdgeMutation = {
  createQuestionDataEdge?: Maybe<
    Pick<
      QuestionDataEdge,
      'id' | 'owner' | 'questionID' | 'dataID' | 'score' | 'createdAt' | 'updatedAt'
    > & {
      question: Pick<
        Question,
        | 'id'
        | 'owner'
        | 'text'
        | 'viewsCounter'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
      data: Pick<
        Data,
        | 'id'
        | 'owner'
        | 'name'
        | 'snippet'
        | 'numRows'
        | 'fileSizeBytes'
        | 'notes'
        | 's3Bucket'
        | 's3Key'
        | 's3Prefix'
        | 's3Basename'
        | 'viewsCounter'
        | 'isRequest'
        | 'topicNames'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type CreateNotificationMutationVariables = Exact<{
  input: CreateNotificationInput;
  condition?: Maybe<ModelNotificationConditionInput>;
}>;

export type CreateNotificationMutation = {
  createNotification?: Maybe<
    Pick<
      Notification,
      | 'id'
      | 'updatedAt'
      | 'owner'
      | 'actorUserID'
      | 'actorQuestionID'
      | 'actorDataID'
      | 'followedQuestionID'
      | 'followedDataID'
      | 'read'
      | 'pushNotified'
      | 'createdAt'
    > & {
      actorUser: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      actorQuestion?: Maybe<
        Pick<
          Question,
          | 'id'
          | 'owner'
          | 'text'
          | 'viewsCounter'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
      followedQuestion?: Maybe<
        Pick<
          Question,
          | 'id'
          | 'owner'
          | 'text'
          | 'viewsCounter'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
      actorData?: Maybe<
        Pick<
          Data,
          | 'id'
          | 'owner'
          | 'name'
          | 'snippet'
          | 'numRows'
          | 'fileSizeBytes'
          | 'notes'
          | 's3Bucket'
          | 's3Key'
          | 's3Prefix'
          | 's3Basename'
          | 'viewsCounter'
          | 'isRequest'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
      followedData?: Maybe<
        Pick<
          Data,
          | 'id'
          | 'owner'
          | 'name'
          | 'snippet'
          | 'numRows'
          | 'fileSizeBytes'
          | 'notes'
          | 's3Bucket'
          | 's3Key'
          | 's3Prefix'
          | 's3Basename'
          | 'viewsCounter'
          | 'isRequest'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
    }
  >;
};

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
  condition?: Maybe<ModelNotificationConditionInput>;
}>;

export type UpdateNotificationMutation = {
  updateNotification?: Maybe<
    Pick<
      Notification,
      | 'id'
      | 'updatedAt'
      | 'owner'
      | 'actorUserID'
      | 'actorQuestionID'
      | 'actorDataID'
      | 'followedQuestionID'
      | 'followedDataID'
      | 'read'
      | 'pushNotified'
      | 'createdAt'
    > & {
      actorUser: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      actorQuestion?: Maybe<
        Pick<
          Question,
          | 'id'
          | 'owner'
          | 'text'
          | 'viewsCounter'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
      followedQuestion?: Maybe<
        Pick<
          Question,
          | 'id'
          | 'owner'
          | 'text'
          | 'viewsCounter'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
      actorData?: Maybe<
        Pick<
          Data,
          | 'id'
          | 'owner'
          | 'name'
          | 'snippet'
          | 'numRows'
          | 'fileSizeBytes'
          | 'notes'
          | 's3Bucket'
          | 's3Key'
          | 's3Prefix'
          | 's3Basename'
          | 'viewsCounter'
          | 'isRequest'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
      followedData?: Maybe<
        Pick<
          Data,
          | 'id'
          | 'owner'
          | 'name'
          | 'snippet'
          | 'numRows'
          | 'fileSizeBytes'
          | 'notes'
          | 's3Bucket'
          | 's3Key'
          | 's3Prefix'
          | 's3Basename'
          | 'viewsCounter'
          | 'isRequest'
          | 'topicNames'
          | 'createdAt'
          | 'updatedAt'
        >
      >;
    }
  >;
};

export type CreateTopicMutationVariables = Exact<{
  input: CreateTopicInput;
  condition?: Maybe<ModelTopicConditionInput>;
}>;

export type CreateTopicMutation = {
  createTopic?: Maybe<
    Pick<
      Topic,
      | 'id'
      | 'name'
      | 'description'
      | 'followerCount'
      | 'entityCount'
      | 'sortable'
      | 'createdAt'
      | 'updatedAt'
    > & { userFollows?: Maybe<Pick<ModelUserFollowsTopicConnection, 'nextToken'>> }
  >;
};

export type CreateUserFollowsTopicMutationVariables = Exact<{
  input: CreateUserFollowsTopicInput;
  condition?: Maybe<ModelUserFollowsTopicConditionInput>;
}>;

export type CreateUserFollowsTopicMutation = {
  createUserFollowsTopic?: Maybe<
    Pick<
      UserFollowsTopic,
      'id' | 'updatedAt' | 'owner' | 'userID' | 'topicID' | 'createdAt'
    > & {
      user: Pick<
        User,
        'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
      >;
      topic: Pick<
        Topic,
        | 'id'
        | 'name'
        | 'description'
        | 'followerCount'
        | 'entityCount'
        | 'sortable'
        | 'createdAt'
        | 'updatedAt'
      >;
    }
  >;
};

export type SearchVectorIndexQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
  scoreThreshold: Scalars['Float'];
  filter?: Maybe<SearchFilterInput>;
  from?: Maybe<Scalars['Int']>;
}>;

export type SearchVectorIndexQuery = {
  searchVectorIndex: Pick<SearchResults, 'from'> & {
    results: Array<Pick<SearchResult, 'carType'>>;
  };
};

export type SearchTraditionalIndexQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
  filter?: Maybe<SearchFilterInput>;
  from?: Maybe<Scalars['Int']>;
}>;

export type SearchTraditionalIndexQuery = {
  searchTraditionalIndex: Pick<SearchResults, 'from'> & {
    results: Array<Pick<SearchResult, 'carType'>>;
  };
};

export type RandomSampleQueryVariables = Exact<{
  limit: Scalars['Int'];
  from?: Maybe<Scalars['Int']>;
  filter?: Maybe<SearchFilterInput>;
}>;

export type RandomSampleQuery = {
  randomSample: Pick<SearchResults, 'from'> & {
    results: Array<Pick<SearchResult, 'carType'>>;
  };
};

export type GetTopTopicsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type GetTopTopicsQuery = {
  getTopTopics: Array<Pick<TopicBasics, 'id' | 'name' | 'score'>>;
};

export type GetPublicUserProfileQueryVariables = Exact<{
  username: Scalars['ID'];
}>;

export type GetPublicUserProfileQuery = {
  getPublicUserProfile?: Maybe<
    Pick<PublicUserProfileResult, 'id' | 'owner' | 'username' | 'amIFollower'> & {
      ownedQuestions?: Maybe<
        Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
      >;
      ownedQuestTrains?: Maybe<
        Array<
          Pick<SearchQuestionResultQuestTrain, 'id' | 'ownerUsername' | 'updatedAt'>
        >
      >;
      ownedDatas?: Maybe<
        Array<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'name' | 'isRequest' | 'updatedAt'
          >
        >
      >;
      followingUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'ownerUsername' | 'updatedAt'>>
      >;
      followedByUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'ownerUsername' | 'updatedAt'>>
      >;
    }
  >;
};

export type GetMyUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserProfileQuery = {
  getMyUserProfile?: Maybe<
    Pick<MyUserProfileResult, 'id' | 'owner' | 'username' | 'name' | 'email'> & {
      followedQuestions?: Maybe<
        Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
      >;
      followedDatas?: Maybe<
        Array<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'name' | 'isRequest' | 'updatedAt'
          >
        >
      >;
      ownedQuestions?: Maybe<
        Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
      >;
      ownedQuestTrains?: Maybe<
        Array<
          Pick<SearchQuestionResultQuestTrain, 'id' | 'ownerUsername' | 'updatedAt'>
        >
      >;
      ownedDatas?: Maybe<
        Array<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'name' | 'isRequest' | 'updatedAt'
          >
        >
      >;
      notifications?: Maybe<
        Array<
          Pick<
            NotificationResult,
            | 'id'
            | 'owner'
            | 'createdAt'
            | 'updatedAt'
            | 'actorUserID'
            | 'actorUsername'
            | 'actorQuestionID'
            | 'actorDataID'
            | 'followedQuestionID'
            | 'followedDataID'
            | 'read'
            | 'pushNotified'
          >
        >
      >;
      followingUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'ownerUsername' | 'updatedAt'>>
      >;
      followedByUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'ownerUsername' | 'updatedAt'>>
      >;
      followedTopics?: Maybe<Array<Pick<TopicBasics, 'id' | 'name' | 'score'>>>;
    }
  >;
};

export type GetQuestionManagedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestionManagedQuery = {
  getQuestionManaged: Pick<
    GetQuestionManagedResult,
    | 'id'
    | 'updatedAt'
    | 'owner'
    | 'ownerUsername'
    | 'text'
    | 'amIFollower'
    | 'topicNames'
  > & {
    neighborQuestions?: Maybe<
      Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
    >;
  };
};

export type GetDataManagedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDataManagedQuery = {
  getDataManaged: Pick<
    GetDataManagedResult,
    | 'id'
    | 'updatedAt'
    | 'owner'
    | 'ownerUsername'
    | 'name'
    | 'snippet'
    | 'numRows'
    | 'fileSizeBytes'
    | 'notes'
    | 's3Bucket'
    | 's3Key'
    | 's3Prefix'
    | 's3Basename'
    | 'isRequest'
    | 'amIFollower'
    | 'topicNames'
  > & {
    header: Array<
      Pick<HeaderColumn, 'name' | 'columnType' | 'required' | 'exampleValue'>
    >;
    references?: Maybe<Array<Pick<Reference, 'name' | 'link'>>>;
  };
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserQuery = {
  getUser?: Maybe<
    Pick<
      User,
      'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
    > & {
      ownedQuestions?: Maybe<Pick<ModelUserOwnsQuestionConnection, 'nextToken'>>;
      ownedQuestTrains?: Maybe<Pick<ModelUserOwnsQuestTrainConnection, 'nextToken'>>;
      followedQuestions?: Maybe<Pick<ModelUserFollowsQuestionConnection, 'nextToken'>>;
      followedDatas?: Maybe<Pick<ModelUserFollowsDataConnection, 'nextToken'>>;
      followingUsers?: Maybe<Pick<ModelUserFollowsUserConnection, 'nextToken'>>;
      followedByUsers?: Maybe<Pick<ModelUserFollowsUserConnection, 'nextToken'>>;
      ownedData?: Maybe<Pick<ModelUserOwnsDataConnection, 'nextToken'>>;
      notifications?: Maybe<Pick<ModelNotificationConnection, 'nextToken'>>;
      followedTopics?: Maybe<Pick<ModelUserFollowsTopicConnection, 'nextToken'>>;
    }
  >;
};

export type ListUsersQueryVariables = Exact<{
  filter?: Maybe<ModelUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUsersQuery = {
  listUsers?: Maybe<
    Pick<ModelUserConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              User,
              'id' | 'owner' | 'username' | 'createdAt' | 'updatedAt' | 'name' | 'email'
            >
          >
        >
      >;
    }
  >;
};

export type GetUsernameLookupQueryVariables = Exact<{
  username: Scalars['ID'];
}>;

export type GetUsernameLookupQuery = {
  getUsernameLookup?: Maybe<
    Pick<UsernameLookup, 'owner' | 'username' | 'userID' | 'createdAt' | 'updatedAt'>
  >;
};

export type GetEmailLookupQueryVariables = Exact<{
  email: Scalars['ID'];
}>;

export type GetEmailLookupQuery = {
  getEmailLookup?: Maybe<
    Pick<EmailLookup, 'owner' | 'email' | 'userID' | 'createdAt' | 'updatedAt'>
  >;
};

export type ListUserFollowsQuestionsQueryVariables = Exact<{
  filter?: Maybe<ModelUserFollowsQuestionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUserFollowsQuestionsQuery = {
  listUserFollowsQuestions?: Maybe<
    Pick<ModelUserFollowsQuestionConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              UserFollowsQuestion,
              'id' | 'updatedAt' | 'owner' | 'userID' | 'questionID' | 'createdAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListUserFollowsDatasQueryVariables = Exact<{
  filter?: Maybe<ModelUserFollowsDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUserFollowsDatasQuery = {
  listUserFollowsDatas?: Maybe<
    Pick<ModelUserFollowsDataConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              UserFollowsData,
              'id' | 'updatedAt' | 'owner' | 'userID' | 'dataID' | 'createdAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListUserFollowsUsersQueryVariables = Exact<{
  filter?: Maybe<ModelUserFollowsUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUserFollowsUsersQuery = {
  listUserFollowsUsers?: Maybe<
    Pick<ModelUserFollowsUserConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              UserFollowsUser,
              'id' | 'updatedAt' | 'owner' | 'followerID' | 'followedID' | 'createdAt'
            >
          >
        >
      >;
    }
  >;
};

export type GetQuestionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestionQuery = {
  getQuestion?: Maybe<
    Pick<
      Question,
      | 'id'
      | 'owner'
      | 'text'
      | 'viewsCounter'
      | 'topicNames'
      | 'createdAt'
      | 'updatedAt'
    > & {
      ownerEdge: Pick<
        UserOwnsQuestion,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'questionID' | 'createdAt'
      >;
      userFollows?: Maybe<Pick<ModelUserFollowsQuestionConnection, 'nextToken'>>;
      questionsForward?: Maybe<Pick<ModelQuestionEdgeConnection, 'nextToken'>>;
      questionsBackward?: Maybe<Pick<ModelQuestionEdgeConnection, 'nextToken'>>;
      questTrains?: Maybe<Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'>>;
      data?: Maybe<Pick<ModelQuestionDataEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type ListQuestionsQueryVariables = Exact<{
  filter?: Maybe<ModelQuestionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListQuestionsQuery = {
  listQuestions?: Maybe<
    Pick<ModelQuestionConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              Question,
              | 'id'
              | 'owner'
              | 'text'
              | 'viewsCounter'
              | 'topicNames'
              | 'createdAt'
              | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListQuestionEdgesQueryVariables = Exact<{
  filter?: Maybe<ModelQuestionEdgeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListQuestionEdgesQuery = {
  listQuestionEdges?: Maybe<
    Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              QuestionEdge,
              'id' | 'owner' | 'aID' | 'bID' | 'score' | 'createdAt' | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListDataDataEdgesQueryVariables = Exact<{
  filter?: Maybe<ModelDataDataEdgeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListDataDataEdgesQuery = {
  listDataDataEdges?: Maybe<
    Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              DataDataEdge,
              'id' | 'owner' | 'aID' | 'bID' | 'score' | 'createdAt' | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type GetQuestTrainQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestTrainQuery = {
  getQuestTrain?: Maybe<
    Pick<QuestTrain, 'id' | 'owner' | 'viewsCounter' | 'createdAt' | 'updatedAt'> & {
      carSpecs: Array<Pick<CarSpec, 'id' | 'carType'>>;
      ownerEdge: Pick<
        UserOwnsQuestTrain,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'questTrainID' | 'createdAt'
      >;
      questions?: Maybe<Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type ListQuestTrainsQueryVariables = Exact<{
  filter?: Maybe<ModelQuestTrainFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListQuestTrainsQuery = {
  listQuestTrains?: Maybe<
    Pick<ModelQuestTrainConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              QuestTrain,
              'id' | 'owner' | 'viewsCounter' | 'createdAt' | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type GetDataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDataQuery = {
  getData?: Maybe<
    Pick<
      Data,
      | 'id'
      | 'owner'
      | 'name'
      | 'snippet'
      | 'numRows'
      | 'fileSizeBytes'
      | 'notes'
      | 's3Bucket'
      | 's3Key'
      | 's3Prefix'
      | 's3Basename'
      | 'viewsCounter'
      | 'isRequest'
      | 'topicNames'
      | 'createdAt'
      | 'updatedAt'
    > & {
      header: Array<
        Pick<HeaderColumn, 'name' | 'columnType' | 'required' | 'exampleValue'>
      >;
      references?: Maybe<Array<Pick<Reference, 'name' | 'link'>>>;
      userFollows?: Maybe<Pick<ModelUserFollowsDataConnection, 'nextToken'>>;
      datasForward?: Maybe<Pick<ModelDataDataEdgeConnection, 'nextToken'>>;
      datasBackward?: Maybe<Pick<ModelDataDataEdgeConnection, 'nextToken'>>;
      ownerEdge: Pick<
        UserOwnsData,
        'id' | 'updatedAt' | 'owner' | 'userID' | 'dataID' | 'createdAt'
      >;
      questions?: Maybe<Pick<ModelQuestionDataEdgeConnection, 'nextToken'>>;
    }
  >;
};

export type ListDatasQueryVariables = Exact<{
  filter?: Maybe<ModelDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListDatasQuery = {
  listDatas?: Maybe<
    Pick<ModelDataConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              Data,
              | 'id'
              | 'owner'
              | 'name'
              | 'snippet'
              | 'numRows'
              | 'fileSizeBytes'
              | 'notes'
              | 's3Bucket'
              | 's3Key'
              | 's3Prefix'
              | 's3Basename'
              | 'viewsCounter'
              | 'isRequest'
              | 'topicNames'
              | 'createdAt'
              | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListQuestionDataEdgesQueryVariables = Exact<{
  filter?: Maybe<ModelQuestionDataEdgeFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListQuestionDataEdgesQuery = {
  listQuestionDataEdges?: Maybe<
    Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              QuestionDataEdge,
              | 'id'
              | 'owner'
              | 'questionID'
              | 'dataID'
              | 'score'
              | 'createdAt'
              | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type GetTopicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTopicQuery = {
  getTopic?: Maybe<
    Pick<
      Topic,
      | 'id'
      | 'name'
      | 'description'
      | 'followerCount'
      | 'entityCount'
      | 'sortable'
      | 'createdAt'
      | 'updatedAt'
    > & { userFollows?: Maybe<Pick<ModelUserFollowsTopicConnection, 'nextToken'>> }
  >;
};

export type ListTopicsQueryVariables = Exact<{
  filter?: Maybe<ModelTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListTopicsQuery = {
  listTopics?: Maybe<
    Pick<ModelTopicConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              Topic,
              | 'id'
              | 'name'
              | 'description'
              | 'followerCount'
              | 'entityCount'
              | 'sortable'
              | 'createdAt'
              | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListTopicsByFollowerCountQueryVariables = Exact<{
  sortable?: Maybe<IsSortable>;
  followerCount?: Maybe<ModelIntKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListTopicsByFollowerCountQuery = {
  listTopicsByFollowerCount?: Maybe<
    Pick<ModelTopicConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              Topic,
              | 'id'
              | 'name'
              | 'description'
              | 'followerCount'
              | 'entityCount'
              | 'sortable'
              | 'createdAt'
              | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListTopicsByEntityCountQueryVariables = Exact<{
  sortable?: Maybe<IsSortable>;
  entityCount?: Maybe<ModelIntKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  filter?: Maybe<ModelTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListTopicsByEntityCountQuery = {
  listTopicsByEntityCount?: Maybe<
    Pick<ModelTopicConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              Topic,
              | 'id'
              | 'name'
              | 'description'
              | 'followerCount'
              | 'entityCount'
              | 'sortable'
              | 'createdAt'
              | 'updatedAt'
            >
          >
        >
      >;
    }
  >;
};

export type ListUserFollowsTopicsQueryVariables = Exact<{
  filter?: Maybe<ModelUserFollowsTopicFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUserFollowsTopicsQuery = {
  listUserFollowsTopics?: Maybe<
    Pick<ModelUserFollowsTopicConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              UserFollowsTopic,
              'id' | 'updatedAt' | 'owner' | 'userID' | 'topicID' | 'createdAt'
            >
          >
        >
      >;
    }
  >;
};

export type QuestionBasicsFragment = Pick<
  Question,
  'id' | 'owner' | 'text' | 'updatedAt' | 'topicNames'
> & {
  ownerEdge: Pick<UserOwnsQuestion, 'id'> & { user: Pick<User, 'id' | 'username'> };
};

export type GetQuestionBasicsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestionBasicsQuery = { getQuestion?: Maybe<QuestionBasicsFragment> };

export type UpdateQuestionInternalMutationVariables = Exact<{
  id: Scalars['ID'];
  text: Scalars['String'];
  topicNames: Array<Scalars['String']> | Scalars['String'];
}>;

export type UpdateQuestionInternalMutation = {
  updateQuestion?: Maybe<QuestionBasicsFragment>;
};

export type UpdateDataMetadataInternalMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  topicNames: Array<Scalars['String']> | Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  references?: Maybe<Array<ReferenceInput> | ReferenceInput>;
}>;

export type UpdateDataMetadataInternalMutation = {
  updateData?: Maybe<
    Pick<Data, 's3Bucket' | 's3Key' | 'viewsCounter'> & DataCardBasicsFragment
  >;
};

export type ListQuestionsBasicsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type ListQuestionsBasicsQuery = {
  listQuestions?: Maybe<
    Pick<ModelQuestionConnection, 'nextToken'> & {
      items?: Maybe<Array<Maybe<QuestionBasicsFragment>>>;
    }
  >;
};

export type ListDatasBasicsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;

export type ListDatasBasicsQuery = {
  listDatas?: Maybe<
    Pick<ModelDataConnection, 'nextToken'> & {
      items?: Maybe<Array<Maybe<DataCardBasicsFragment>>>;
    }
  >;
};

export type GetQuestionManagedWrapperQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestionManagedWrapperQuery = {
  getQuestionManaged: Pick<
    GetQuestionManagedResult,
    | 'id'
    | 'owner'
    | 'ownerUsername'
    | 'text'
    | 'updatedAt'
    | 'amIFollower'
    | 'topicNames'
  > & {
    neighborQuestions?: Maybe<
      Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
    >;
    questTrains: Array<{
      trainCars: Array<
        Pick<TrainCarResult, 'id' | 'carType'> & {
          question?: Maybe<
            Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>
          >;
          data?: Maybe<
            Pick<
              SearchDataResult,
              'id' | 'owner' | 'ownerUsername' | 'updatedAt' | 'isRequest' | 'name'
            > & {
              header: Array<
                Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
              >;
            }
          >;
        }
      >;
    }>;
  };
};

export type GetQuestionInternalQueryVariables = Exact<{
  id: Scalars['ID'];
  userID?: Maybe<ModelIdKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
}>;

export type GetQuestionInternalQuery = {
  getQuestion?: Maybe<
    {
      data?: Maybe<
        Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<QuestionDataEdge, 'id' | 'score'> & {
                  data: DataCardBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      userFollows?: Maybe<{
        items?: Maybe<Array<Maybe<Pick<UserFollowsQuestion, 'id' | 'userID'>>>>;
      }>;
      questionsBackward?: Maybe<
        Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<QuestionEdge, 'id' | 'score'> & {
                  questionA: {
                    questionsBackward?: Maybe<
                      Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionEdge, 'id' | 'score'> & {
                                questionA: {
                                  data?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              data: DataCardBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & QuestionBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    questionsForward?: Maybe<
                      Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionEdge, 'id' | 'score'> & {
                                questionB: {
                                  data?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              data: DataCardBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & QuestionBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    data?: Maybe<
                      Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionDataEdge, 'id' | 'score'> & {
                                data: DataCardBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                  } & QuestionBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      questionsForward?: Maybe<
        Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<QuestionEdge, 'id' | 'score'> & {
                  questionB: {
                    questionsBackward?: Maybe<
                      Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionEdge, 'id' | 'score'> & {
                                questionA: {
                                  data?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              data: DataCardBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & QuestionBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    questionsForward?: Maybe<
                      Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionEdge, 'id' | 'score'> & {
                                questionB: {
                                  data?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              data: DataCardBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & QuestionBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    data?: Maybe<
                      Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionDataEdge, 'id' | 'score'> & {
                                data: DataCardBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                  } & QuestionBasicsFragment;
                }
              >
            >
          >;
        }
      >;
    } & QuestionBasicsFragment
  >;
};

export type ListQuestionsManagedQueryVariables = Exact<{
  filter?: Maybe<ModelQuestionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListQuestionsManagedQuery = {
  listQuestions?: Maybe<
    Pick<ModelQuestionConnection, 'nextToken'> & {
      items?: Maybe<Array<Maybe<QuestionBasicsFragment>>>;
    }
  >;
};

export type ListDatasManagedQueryVariables = Exact<{
  filter?: Maybe<ModelDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListDatasManagedQuery = {
  listDatas?: Maybe<
    Pick<ModelDataConnection, 'nextToken'> & {
      items?: Maybe<Array<Maybe<DataCardBasicsFragment>>>;
    }
  >;
};

export type GetQuestTrainManagedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestTrainManagedQuery = {
  getQuestTrain?: Maybe<
    Pick<QuestTrain, 'id' | 'createdAt' | 'updatedAt'> & {
      ownerEdge: Pick<UserOwnsQuestTrain, 'id'> & {
        user: Pick<User, 'id' | 'username'>;
      };
      carSpecs: Array<Pick<CarSpec, 'id' | 'carType'>>;
      questions?: Maybe<
        Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<
                  QuestTrainQuestionEdge,
                  'id' | 'questionID' | 'createdAt' | 'updatedAt' | 'questTrainID'
                > & {
                  questTrain: Pick<QuestTrain, 'id' | 'createdAt' | 'updatedAt'> & {
                    ownerEdge: Pick<UserOwnsQuestTrain, 'id'> & {
                      user: Pick<User, 'id' | 'username'>;
                    };
                  };
                  question: QuestionBasicsFragment;
                }
              >
            >
          >;
        }
      >;
    }
  >;
};

export type ListQuestTrainsManagedQueryVariables = Exact<{
  filter?: Maybe<ModelQuestTrainFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListQuestTrainsManagedQuery = {
  listQuestTrains?: Maybe<
    Pick<ModelQuestTrainConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<QuestTrain, 'id'> & {
              ownerEdge: Pick<UserOwnsQuestTrain, 'id'> & {
                user: Pick<User, 'id' | 'username'>;
              };
              carSpecs: Array<Pick<CarSpec, 'carType' | 'id'>>;
              questions?: Maybe<
                Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'> & {
                  items?: Maybe<
                    Array<
                      Maybe<
                        Pick<QuestTrainQuestionEdge, 'id' | 'questionID'> & {
                          question: QuestionBasicsFragment;
                        }
                      >
                    >
                  >;
                }
              >;
            }
          >
        >
      >;
    }
  >;
};

export type SearchVectorIndexManagedQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
  scoreThreshold: Scalars['Float'];
  filter?: Maybe<SearchFilterInput>;
  from?: Maybe<Scalars['Int']>;
}>;

export type SearchVectorIndexManagedQuery = {
  searchVectorIndex: Pick<SearchResults, 'from'> & {
    results: Array<
      Pick<SearchResult, 'carType'> & {
        data?: Maybe<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'updatedAt' | 'name' | 'isRequest'
          > & {
            header: Array<
              Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
            >;
          }
        >;
        question?: Maybe<
          Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>
        >;
      }
    >;
  };
};

export type SearchTraditionalIndexManagedQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
  filter?: Maybe<SearchFilterInput>;
  from?: Maybe<Scalars['Int']>;
}>;

export type SearchTraditionalIndexManagedQuery = {
  searchTraditionalIndex: Pick<SearchResults, 'from'> & {
    results: Array<
      Pick<SearchResult, 'carType'> & {
        data?: Maybe<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'updatedAt' | 'name' | 'isRequest'
          > & {
            header: Array<
              Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
            >;
          }
        >;
        question?: Maybe<
          Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>
        >;
      }
    >;
  };
};

export type RandomSampleManagedQueryVariables = Exact<{
  limit: Scalars['Int'];
  filter?: Maybe<SearchFilterInput>;
  from?: Maybe<Scalars['Int']>;
}>;

export type RandomSampleManagedQuery = {
  randomSample: Pick<SearchResults, 'from'> & {
    results: Array<
      Pick<SearchResult, 'carType'> & {
        data?: Maybe<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'name' | 'updatedAt' | 'isRequest'
          > & {
            header: Array<
              Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
            >;
          }
        >;
        question?: Maybe<
          Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>
        >;
      }
    >;
  };
};

export type CreateUserOwnsQuestionManagedMutationVariables = Exact<{
  input: CreateUserOwnsQuestionInput;
}>;

export type CreateUserOwnsQuestionManagedMutation = {
  createUserOwnsQuestion?: Maybe<
    Pick<UserOwnsQuestion, 'id' | 'owner' | 'questionID' | 'userID'>
  >;
};

export type CreateUserOwnsQuestTrainManagedMutationVariables = Exact<{
  input: CreateUserOwnsQuestTrainInput;
}>;

export type CreateUserOwnsQuestTrainManagedMutation = {
  createUserOwnsQuestTrain?: Maybe<
    Pick<UserOwnsQuestTrain, 'id' | 'owner' | 'questTrainID' | 'userID'>
  >;
};

export type GetUserManagedQueryVariables = Exact<{
  id: Scalars['ID'];
  sortDirection?: Maybe<ModelSortDirection>;
}>;

export type GetUserManagedQuery = {
  getUser?: Maybe<
    Pick<User, 'id' | 'owner' | 'username'> & {
      ownedData?: Maybe<
        Pick<ModelUserOwnsDataConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserOwnsData, 'id' | 'updatedAt'> & {
                  data: Pick<
                    Data,
                    'id' | 'owner' | 'updatedAt' | 'name' | 'isRequest'
                  > & {
                    ownerEdge: Pick<UserOwnsData, 'id'> & {
                      user: Pick<User, 'id' | 'username'>;
                    };
                    header: Array<
                      Pick<
                        HeaderColumn,
                        'exampleValue' | 'name' | 'columnType' | 'required'
                      >
                    >;
                  };
                }
              >
            >
          >;
        }
      >;
      ownedQuestions?: Maybe<
        Pick<ModelUserOwnsQuestionConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserOwnsQuestion, 'id' | 'updatedAt'> & {
                  question: QuestionBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      followedByUsers?: Maybe<
        Pick<ModelUserFollowsUserConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserFollowsUser, 'id' | 'updatedAt'> & {
                  follower: Pick<User, 'id' | 'username'>;
                }
              >
            >
          >;
        }
      >;
      followingUsers?: Maybe<
        Pick<ModelUserFollowsUserConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserFollowsUser, 'id' | 'updatedAt'> & {
                  followed: Pick<User, 'id' | 'username'>;
                }
              >
            >
          >;
        }
      >;
    }
  >;
};

export type GetMyUserInternalQueryVariables = Exact<{
  id: Scalars['ID'];
  sortDirection?: Maybe<ModelSortDirection>;
}>;

export type GetMyUserInternalQuery = {
  getUser?: Maybe<
    Pick<User, 'id' | 'owner' | 'username' | 'name' | 'email'> & {
      followingUsers?: Maybe<
        Pick<ModelUserFollowsUserConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserFollowsUser, 'id' | 'updatedAt'> & {
                  followed: Pick<User, 'id' | 'username'>;
                }
              >
            >
          >;
        }
      >;
      followedByUsers?: Maybe<
        Pick<ModelUserFollowsUserConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserFollowsUser, 'id' | 'updatedAt'> & {
                  follower: Pick<User, 'id' | 'username'>;
                }
              >
            >
          >;
        }
      >;
      followedDatas?: Maybe<
        Pick<ModelUserFollowsDataConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserFollowsData, 'id' | 'updatedAt'> & {
                  data: Pick<
                    Data,
                    'id' | 'owner' | 'updatedAt' | 'name' | 'isRequest'
                  > & {
                    ownerEdge: Pick<UserOwnsData, 'id'> & {
                      user: Pick<User, 'id' | 'username'>;
                    };
                    header: Array<
                      Pick<
                        HeaderColumn,
                        'exampleValue' | 'name' | 'columnType' | 'required'
                      >
                    >;
                  };
                }
              >
            >
          >;
        }
      >;
      followedQuestions?: Maybe<
        Pick<ModelUserFollowsQuestionConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserFollowsQuestion, 'id' | 'updatedAt'> & {
                  question: QuestionBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      ownedData?: Maybe<
        Pick<ModelUserOwnsDataConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserOwnsData, 'id' | 'updatedAt'> & {
                  data: Pick<
                    Data,
                    'id' | 'owner' | 'updatedAt' | 'name' | 'isRequest'
                  > & {
                    ownerEdge: Pick<UserOwnsData, 'id'> & {
                      user: Pick<User, 'id' | 'username'>;
                    };
                    header: Array<
                      Pick<
                        HeaderColumn,
                        'exampleValue' | 'name' | 'columnType' | 'required'
                      >
                    >;
                  };
                }
              >
            >
          >;
        }
      >;
      ownedQuestions?: Maybe<
        Pick<ModelUserOwnsQuestionConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserOwnsQuestion, 'id' | 'updatedAt'> & {
                  question: QuestionBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      notifications?: Maybe<
        Pick<ModelNotificationConnection, 'nextToken'> & {
          items?: Maybe<Array<Maybe<NotificationBasicsFragment>>>;
        }
      >;
      followedTopics?: Maybe<
        Pick<ModelUserFollowsTopicConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<UserFollowsTopic, 'id' | 'updatedAt'> & {
                  topic: Pick<Topic, 'id' | 'name'>;
                }
              >
            >
          >;
        }
      >;
    }
  >;
};

export type GetPublicUserProfileManagedQueryVariables = Exact<{
  username: Scalars['ID'];
}>;

export type GetPublicUserProfileManagedQuery = {
  getPublicUserProfile?: Maybe<
    Pick<PublicUserProfileResult, 'id' | 'owner' | 'username' | 'amIFollower'> & {
      ownedQuestions?: Maybe<
        Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
      >;
      ownedQuestTrains?: Maybe<
        Array<
          Pick<SearchQuestionResultQuestTrain, 'id' | 'ownerUsername'> & {
            carSpecs: Array<Pick<CarSpec, 'id' | 'carType'>>;
            questions: Array<
              Pick<
                SearchQuestionResultQuestTrainQuestion,
                'id' | 'ownerUsername' | 'text'
              >
            >;
          }
        >
      >;
      ownedDatas?: Maybe<
        Array<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'name' | 'updatedAt' | 'isRequest'
          > & {
            header: Array<
              Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
            >;
          }
        >
      >;
      followedByUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'updatedAt' | 'ownerUsername'>>
      >;
      followingUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'updatedAt' | 'ownerUsername'>>
      >;
    }
  >;
};

export type GetMyUserProfileManagedQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserProfileManagedQuery = {
  getMyUserProfile?: Maybe<
    Pick<MyUserProfileResult, 'id' | 'owner' | 'username' | 'email' | 'name'> & {
      followedQuestions?: Maybe<
        Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
      >;
      followedDatas?: Maybe<
        Array<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'name' | 'updatedAt' | 'isRequest'
          > & {
            header: Array<
              Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
            >;
          }
        >
      >;
      ownedQuestions?: Maybe<
        Array<Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>>
      >;
      ownedQuestTrains?: Maybe<
        Array<
          Pick<SearchQuestionResultQuestTrain, 'id' | 'ownerUsername'> & {
            carSpecs: Array<Pick<CarSpec, 'id' | 'carType'>>;
            questions: Array<
              Pick<
                SearchQuestionResultQuestTrainQuestion,
                'id' | 'ownerUsername' | 'text'
              >
            >;
          }
        >
      >;
      ownedDatas?: Maybe<
        Array<
          Pick<
            SearchDataResult,
            'id' | 'owner' | 'ownerUsername' | 'name' | 'updatedAt' | 'isRequest'
          > & {
            header: Array<
              Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
            >;
          }
        >
      >;
      notifications?: Maybe<
        Array<
          Pick<
            NotificationResult,
            | 'id'
            | 'owner'
            | 'read'
            | 'pushNotified'
            | 'createdAt'
            | 'updatedAt'
            | 'actorUserID'
            | 'actorUsername'
          > & {
            actorData?: Maybe<
              Pick<
                SearchDataResult,
                'id' | 'owner' | 'ownerUsername' | 'name' | 'updatedAt' | 'isRequest'
              > & {
                header: Array<
                  Pick<
                    HeaderColumn,
                    'exampleValue' | 'name' | 'columnType' | 'required'
                  >
                >;
              }
            >;
            actorQuestion?: Maybe<
              Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>
            >;
            followedData?: Maybe<
              Pick<
                SearchDataResult,
                'id' | 'owner' | 'ownerUsername' | 'name' | 'updatedAt' | 'isRequest'
              > & {
                header: Array<
                  Pick<
                    HeaderColumn,
                    'exampleValue' | 'name' | 'columnType' | 'required'
                  >
                >;
              }
            >;
            followedQuestion?: Maybe<
              Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>
            >;
          }
        >
      >;
      followingUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'updatedAt' | 'ownerUsername'>>
      >;
      followedByUsers?: Maybe<
        Array<Pick<SearchUserResult, 'id' | 'updatedAt' | 'ownerUsername'>>
      >;
    }
  >;
};

export type NotificationBasicsFragment = Pick<
  Notification,
  'id' | 'owner' | 'read' | 'pushNotified' | 'createdAt' | 'updatedAt' | 'actorUserID'
> & {
  actorData?: Maybe<
    Pick<Data, 'id' | 'owner' | 'updatedAt' | 'name' | 'isRequest'> & {
      ownerEdge: { user: Pick<User, 'username'> };
      header: Array<
        Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
      >;
    }
  >;
  actorQuestion?: Maybe<QuestionBasicsFragment>;
  actorUser: Pick<User, 'id' | 'username'>;
  followedData?: Maybe<
    Pick<Data, 'id' | 'owner' | 'updatedAt' | 'name' | 'isRequest'> & {
      ownerEdge: { user: Pick<User, 'username'> };
      header: Array<
        Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
      >;
    }
  >;
  followedQuestion?: Maybe<QuestionBasicsFragment>;
};

export type CreateQuestionInternalMutationVariables = Exact<{
  input: CreateQuestionInput;
}>;

export type CreateQuestionInternalMutation = {
  createQuestion?: Maybe<
    Pick<
      Question,
      'id' | 'owner' | 'text' | 'updatedAt' | 'topicNames' | 'viewsCounter'
    > & {
      ownerEdge: Pick<UserOwnsQuestion, 'id'> & { user: Pick<User, 'id' | 'username'> };
    }
  >;
};

export type CreateDataInternalMutationVariables = Exact<{
  input: CreateDataInput;
}>;

export type CreateDataInternalMutation = {
  createData?: Maybe<
    Pick<
      Data,
      | 'id'
      | 'owner'
      | 'name'
      | 'snippet'
      | 's3Bucket'
      | 's3Key'
      | 'viewsCounter'
      | 'isRequest'
    > & {
      ownerEdge: Pick<UserOwnsData, 'id'> & { user: Pick<User, 'id' | 'username'> };
      header: Array<
        Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
      >;
    }
  >;
};

export type CreateUserOwnsDataManagedMutationVariables = Exact<{
  input: CreateUserOwnsDataInput;
}>;

export type CreateUserOwnsDataManagedMutation = {
  createUserOwnsData?: Maybe<Pick<UserOwnsData, 'id' | 'owner' | 'dataID' | 'userID'>>;
};

export type GetDataInternalQueryVariables = Exact<{
  id: Scalars['ID'];
  userID?: Maybe<ModelIdKeyConditionInput>;
  sortDirection?: Maybe<ModelSortDirection>;
}>;

export type GetDataInternalQuery = {
  getData?: Maybe<
    Pick<
      Data,
      | 'id'
      | 'updatedAt'
      | 'owner'
      | 'name'
      | 'notes'
      | 'snippet'
      | 'numRows'
      | 'fileSizeBytes'
      | 'topicNames'
      | 's3Bucket'
      | 's3Key'
      | 's3Prefix'
      | 's3Basename'
      | 'isRequest'
    > & {
      ownerEdge: Pick<UserOwnsData, 'id'> & { user: Pick<User, 'id' | 'username'> };
      header: Array<
        Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
      >;
      userFollows?: Maybe<{
        items?: Maybe<Array<Maybe<Pick<UserFollowsData, 'id' | 'userID'>>>>;
      }>;
      questions?: Maybe<
        Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<QuestionDataEdge, 'id' | 'score'> & {
                  question: {
                    data?: Maybe<
                      Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionDataEdge, 'id'> & {
                                data: DataCardBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                  } & QuestionBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      datasBackward?: Maybe<
        Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<DataDataEdge, 'id' | 'score'> & {
                  dataA: {
                    questions?: Maybe<
                      Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionDataEdge, 'id'> & {
                                question: QuestionBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    datasForward?: Maybe<
                      Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<DataDataEdge, 'id' | 'score'> & {
                                dataB: {
                                  questions?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              question: QuestionBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & DataCardBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    datasBackward?: Maybe<
                      Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<DataDataEdge, 'id' | 'score'> & {
                                dataA: {
                                  questions?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              question: QuestionBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & DataCardBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                  } & DataCardBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      datasForward?: Maybe<
        Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
          items?: Maybe<
            Array<
              Maybe<
                Pick<DataDataEdge, 'id' | 'score'> & {
                  dataB: {
                    questions?: Maybe<
                      Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<QuestionDataEdge, 'id'> & {
                                question: QuestionBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    datasForward?: Maybe<
                      Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<DataDataEdge, 'id' | 'score'> & {
                                dataB: {
                                  questions?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              question: QuestionBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & DataCardBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                    datasBackward?: Maybe<
                      Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              Pick<DataDataEdge, 'id' | 'score'> & {
                                dataA: {
                                  questions?: Maybe<
                                    Pick<
                                      ModelQuestionDataEdgeConnection,
                                      'nextToken'
                                    > & {
                                      items?: Maybe<
                                        Array<
                                          Maybe<
                                            Pick<QuestionDataEdge, 'id'> & {
                                              question: QuestionBasicsFragment;
                                            }
                                          >
                                        >
                                      >;
                                    }
                                  >;
                                } & DataCardBasicsFragment;
                              }
                            >
                          >
                        >;
                      }
                    >;
                  } & DataCardBasicsFragment;
                }
              >
            >
          >;
        }
      >;
      references?: Maybe<Array<Pick<Reference, 'name' | 'link'>>>;
    }
  >;
};

export type GetDataBasicsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDataBasicsQuery = {
  getData?: Maybe<
    Pick<
      Data,
      | 'fileSizeBytes'
      | 'numRows'
      | 'notes'
      | 'snippet'
      | 's3Bucket'
      | 's3Basename'
      | 's3Prefix'
      | 's3Key'
    > & {
      references?: Maybe<Array<Pick<Reference, 'name' | 'link'>>>;
    } & DataCardBasicsFragment
  >;
};

export type GetDataManagedWrapperQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDataManagedWrapperQuery = {
  getDataManaged: Pick<
    GetDataManagedResult,
    | 'id'
    | 'owner'
    | 'ownerUsername'
    | 'name'
    | 'snippet'
    | 'numRows'
    | 'fileSizeBytes'
    | 'notes'
    | 's3Bucket'
    | 's3Key'
    | 's3Prefix'
    | 's3Basename'
    | 'isRequest'
    | 'updatedAt'
    | 'amIFollower'
    | 'topicNames'
  > & {
    header: Array<
      Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
    >;
    references?: Maybe<Array<Pick<Reference, 'name' | 'link'>>>;
    questTrains: Array<{
      trainCars: Array<
        Pick<TrainCarResult, 'id' | 'carType'> & {
          question?: Maybe<
            Pick<SearchQuestionResult, 'id' | 'ownerUsername' | 'text' | 'updatedAt'>
          >;
          data?: Maybe<
            Pick<
              SearchDataResult,
              'id' | 'owner' | 'ownerUsername' | 'updatedAt' | 'isRequest' | 'name'
            > & {
              header: Array<
                Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
              >;
            }
          >;
        }
      >;
    }>;
  };
};

export type DataCardBasicsFragment = Pick<
  Data,
  'id' | 'updatedAt' | 'owner' | 'name' | 'isRequest' | 'topicNames'
> & {
  ownerEdge: Pick<UserOwnsData, 'id'> & { user: Pick<User, 'id' | 'username'> };
  header: Array<
    Pick<HeaderColumn, 'exampleValue' | 'name' | 'columnType' | 'required'>
  >;
};

export type GetQuestionOwnerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestionOwnerQuery = {
  getQuestion?: Maybe<Pick<Question, 'id' | 'owner'>>;
};

export type GetDataOwnerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDataOwnerQuery = { getData?: Maybe<Pick<Data, 'id' | 'owner'>> };

export type GetQuestionForDeleteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestionForDeleteQuery = {
  getQuestion?: Maybe<
    { __typename: 'Question' } & Pick<Question, 'id' | 'owner' | 'topicNames'> & {
        ownerEdge: { __typename: 'UserOwnsQuestion' } & Pick<
          UserOwnsQuestion,
          'questionID' | 'owner'
        >;
        questionsBackward?: Maybe<
          Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
            items?: Maybe<
              Array<Maybe<{ __typename: 'QuestionEdge' } & Pick<QuestionEdge, 'id'>>>
            >;
          }
        >;
        questionsForward?: Maybe<
          Pick<ModelQuestionEdgeConnection, 'nextToken'> & {
            items?: Maybe<
              Array<Maybe<{ __typename: 'QuestionEdge' } & Pick<QuestionEdge, 'id'>>>
            >;
          }
        >;
        questTrains?: Maybe<
          Pick<ModelQuestTrainQuestionEdgeConnection, 'nextToken'> & {
            items?: Maybe<
              Array<
                Maybe<
                  { __typename: 'QuestTrainQuestionEdge' } & Pick<
                    QuestTrainQuestionEdge,
                    'id'
                  >
                >
              >
            >;
          }
        >;
        userFollows?: Maybe<
          Pick<ModelUserFollowsQuestionConnection, 'nextToken'> & {
            items?: Maybe<
              Array<
                Maybe<
                  { __typename: 'UserFollowsQuestion' } & Pick<
                    UserFollowsQuestion,
                    'id'
                  >
                >
              >
            >;
          }
        >;
        data?: Maybe<
          Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
            items?: Maybe<
              Array<
                Maybe<{ __typename: 'QuestionDataEdge' } & Pick<QuestionDataEdge, 'id'>>
              >
            >;
          }
        >;
      }
  >;
};

export type GetDataForDeleteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDataForDeleteQuery = {
  getData?: Maybe<
    { __typename: 'Data' } & Pick<
      Data,
      'id' | 'owner' | 's3Bucket' | 's3Key' | 'topicNames'
    > & {
        ownerEdge: { __typename: 'UserOwnsData' } & Pick<
          UserOwnsData,
          'dataID' | 'owner'
        >;
        datasBackward?: Maybe<
          Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
            items?: Maybe<
              Array<Maybe<{ __typename: 'DataDataEdge' } & Pick<DataDataEdge, 'id'>>>
            >;
          }
        >;
        datasForward?: Maybe<
          Pick<ModelDataDataEdgeConnection, 'nextToken'> & {
            items?: Maybe<
              Array<Maybe<{ __typename: 'DataDataEdge' } & Pick<DataDataEdge, 'id'>>>
            >;
          }
        >;
        questions?: Maybe<
          Pick<ModelQuestionDataEdgeConnection, 'nextToken'> & {
            items?: Maybe<
              Array<
                Maybe<{ __typename: 'QuestionDataEdge' } & Pick<QuestionDataEdge, 'id'>>
              >
            >;
          }
        >;
        userFollows?: Maybe<
          Pick<ModelUserFollowsDataConnection, 'nextToken'> & {
            items?: Maybe<
              Array<
                Maybe<{ __typename: 'UserFollowsData' } & Pick<UserFollowsData, 'id'>>
              >
            >;
          }
        >;
      }
  >;
};

export type ListUserFollowsQuestionsManagedQueryVariables = Exact<{
  filter?: Maybe<ModelUserFollowsQuestionFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUserFollowsQuestionsManagedQuery = {
  listUserFollowsQuestions?: Maybe<
    Pick<ModelUserFollowsQuestionConnection, 'nextToken'> & {
      items?: Maybe<
        Array<
          Maybe<{ __typename: 'UserFollowsQuestion' } & Pick<UserFollowsQuestion, 'id'>>
        >
      >;
    }
  >;
};

export type ListUserFollowsDatasManagedQueryVariables = Exact<{
  filter?: Maybe<ModelUserFollowsDataFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUserFollowsDatasManagedQuery = {
  listUserFollowsDatas?: Maybe<
    Pick<ModelUserFollowsDataConnection, 'nextToken'> & {
      items?: Maybe<
        Array<Maybe<{ __typename: 'UserFollowsData' } & Pick<UserFollowsData, 'id'>>>
      >;
    }
  >;
};

export type ListUserFollowsUsersManagedQueryVariables = Exact<{
  filter?: Maybe<ModelUserFollowsUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type ListUserFollowsUsersManagedQuery = {
  listUserFollowsUsers?: Maybe<
    Pick<ModelUserFollowsUserConnection, 'nextToken'> & {
      items?: Maybe<
        Array<Maybe<{ __typename: 'UserFollowsUser' } & Pick<UserFollowsUser, 'id'>>>
      >;
    }
  >;
};

export type CreateUserFollowsUserManagedMutationVariables = Exact<{
  input: CreateUserFollowsUserInput;
}>;

export type CreateUserFollowsUserManagedMutation = {
  createUserFollowsUser?: Maybe<
    Pick<UserFollowsUser, 'id' | 'owner' | 'followedID' | 'followerID'>
  >;
};

export const QuestionBasicsFragmentDoc = gql`
  fragment QuestionBasics on Question {
    id
    owner
    ownerEdge {
      id
      user {
        id
        username
      }
    }
    text
    updatedAt
    topicNames
  }
`;
export const NotificationBasicsFragmentDoc = gql`
  fragment NotificationBasics on Notification {
    id
    owner
    read
    pushNotified
    createdAt
    updatedAt
    actorData {
      id
      owner
      ownerEdge {
        user {
          username
        }
      }
      updatedAt
      name
      header {
        exampleValue
        name
        columnType
        required
      }
      isRequest
    }
    actorQuestion {
      ...QuestionBasics
    }
    actorUserID
    actorUser {
      id
      username
    }
    followedData {
      id
      owner
      ownerEdge {
        user {
          username
        }
      }
      updatedAt
      name
      header {
        exampleValue
        name
        columnType
        required
      }
      isRequest
    }
    followedQuestion {
      ...QuestionBasics
    }
  }
  ${QuestionBasicsFragmentDoc}
`;
export const DataCardBasicsFragmentDoc = gql`
  fragment DataCardBasics on Data {
    id
    updatedAt
    owner
    ownerEdge {
      id
      user {
        id
        username
      }
    }
    name
    header {
      exampleValue
      name
      columnType
      required
    }
    isRequest
    topicNames
  }
`;
export const CreateQuestTrainManagedDocument = gql`
  mutation CreateQuestTrainManaged($carSpecs: [CarSpecInput!]!, $summary: Boolean) {
    createQuestTrainManaged(carSpecs: $carSpecs, summary: $summary)
  }
`;
export type CreateQuestTrainManagedMutationFn = Apollo.MutationFunction<
  CreateQuestTrainManagedMutation,
  CreateQuestTrainManagedMutationVariables
>;

/**
 * __useCreateQuestTrainManagedMutation__
 *
 * To run a mutation, you first call `useCreateQuestTrainManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestTrainManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestTrainManagedMutation, { data, loading, error }] = useCreateQuestTrainManagedMutation({
 *   variables: {
 *      carSpecs: // value for 'carSpecs'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useCreateQuestTrainManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestTrainManagedMutation,
    CreateQuestTrainManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateQuestTrainManagedMutation,
    CreateQuestTrainManagedMutationVariables
  >(CreateQuestTrainManagedDocument, baseOptions);
}
export type CreateQuestTrainManagedMutationHookResult = ReturnType<
  typeof useCreateQuestTrainManagedMutation
>;
export type CreateQuestTrainManagedMutationResult = Apollo.MutationResult<CreateQuestTrainManagedMutation>;
export type CreateQuestTrainManagedMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestTrainManagedMutation,
  CreateQuestTrainManagedMutationVariables
>;
export const UpdateQuestTrainManagedDocument = gql`
  mutation UpdateQuestTrainManaged($carSpecs: [CarSpecInput!]!, $summary: Boolean) {
    updateQuestTrainManaged(carSpecs: $carSpecs, summary: $summary)
  }
`;
export type UpdateQuestTrainManagedMutationFn = Apollo.MutationFunction<
  UpdateQuestTrainManagedMutation,
  UpdateQuestTrainManagedMutationVariables
>;

/**
 * __useUpdateQuestTrainManagedMutation__
 *
 * To run a mutation, you first call `useUpdateQuestTrainManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestTrainManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestTrainManagedMutation, { data, loading, error }] = useUpdateQuestTrainManagedMutation({
 *   variables: {
 *      carSpecs: // value for 'carSpecs'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateQuestTrainManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuestTrainManagedMutation,
    UpdateQuestTrainManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateQuestTrainManagedMutation,
    UpdateQuestTrainManagedMutationVariables
  >(UpdateQuestTrainManagedDocument, baseOptions);
}
export type UpdateQuestTrainManagedMutationHookResult = ReturnType<
  typeof useUpdateQuestTrainManagedMutation
>;
export type UpdateQuestTrainManagedMutationResult = Apollo.MutationResult<UpdateQuestTrainManagedMutation>;
export type UpdateQuestTrainManagedMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuestTrainManagedMutation,
  UpdateQuestTrainManagedMutationVariables
>;
export const IncrementQuestionViewsDocument = gql`
  mutation IncrementQuestionViews($id: ID!) {
    incrementQuestionViews(id: $id)
  }
`;
export type IncrementQuestionViewsMutationFn = Apollo.MutationFunction<
  IncrementQuestionViewsMutation,
  IncrementQuestionViewsMutationVariables
>;

/**
 * __useIncrementQuestionViewsMutation__
 *
 * To run a mutation, you first call `useIncrementQuestionViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementQuestionViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementQuestionViewsMutation, { data, loading, error }] = useIncrementQuestionViewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncrementQuestionViewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncrementQuestionViewsMutation,
    IncrementQuestionViewsMutationVariables
  >
) {
  return Apollo.useMutation<
    IncrementQuestionViewsMutation,
    IncrementQuestionViewsMutationVariables
  >(IncrementQuestionViewsDocument, baseOptions);
}
export type IncrementQuestionViewsMutationHookResult = ReturnType<
  typeof useIncrementQuestionViewsMutation
>;
export type IncrementQuestionViewsMutationResult = Apollo.MutationResult<IncrementQuestionViewsMutation>;
export type IncrementQuestionViewsMutationOptions = Apollo.BaseMutationOptions<
  IncrementQuestionViewsMutation,
  IncrementQuestionViewsMutationVariables
>;
export const IncrementQuestTrainViewsDocument = gql`
  mutation IncrementQuestTrainViews($id: ID!) {
    incrementQuestTrainViews(id: $id)
  }
`;
export type IncrementQuestTrainViewsMutationFn = Apollo.MutationFunction<
  IncrementQuestTrainViewsMutation,
  IncrementQuestTrainViewsMutationVariables
>;

/**
 * __useIncrementQuestTrainViewsMutation__
 *
 * To run a mutation, you first call `useIncrementQuestTrainViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementQuestTrainViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementQuestTrainViewsMutation, { data, loading, error }] = useIncrementQuestTrainViewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncrementQuestTrainViewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncrementQuestTrainViewsMutation,
    IncrementQuestTrainViewsMutationVariables
  >
) {
  return Apollo.useMutation<
    IncrementQuestTrainViewsMutation,
    IncrementQuestTrainViewsMutationVariables
  >(IncrementQuestTrainViewsDocument, baseOptions);
}
export type IncrementQuestTrainViewsMutationHookResult = ReturnType<
  typeof useIncrementQuestTrainViewsMutation
>;
export type IncrementQuestTrainViewsMutationResult = Apollo.MutationResult<IncrementQuestTrainViewsMutation>;
export type IncrementQuestTrainViewsMutationOptions = Apollo.BaseMutationOptions<
  IncrementQuestTrainViewsMutation,
  IncrementQuestTrainViewsMutationVariables
>;
export const IncrementDataViewsDocument = gql`
  mutation IncrementDataViews($id: ID!) {
    incrementDataViews(id: $id)
  }
`;
export type IncrementDataViewsMutationFn = Apollo.MutationFunction<
  IncrementDataViewsMutation,
  IncrementDataViewsMutationVariables
>;

/**
 * __useIncrementDataViewsMutation__
 *
 * To run a mutation, you first call `useIncrementDataViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementDataViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementDataViewsMutation, { data, loading, error }] = useIncrementDataViewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncrementDataViewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncrementDataViewsMutation,
    IncrementDataViewsMutationVariables
  >
) {
  return Apollo.useMutation<
    IncrementDataViewsMutation,
    IncrementDataViewsMutationVariables
  >(IncrementDataViewsDocument, baseOptions);
}
export type IncrementDataViewsMutationHookResult = ReturnType<
  typeof useIncrementDataViewsMutation
>;
export type IncrementDataViewsMutationResult = Apollo.MutationResult<IncrementDataViewsMutation>;
export type IncrementDataViewsMutationOptions = Apollo.BaseMutationOptions<
  IncrementDataViewsMutation,
  IncrementDataViewsMutationVariables
>;
export const DeleteQuestionDocument = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(id: $id)
  }
`;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<
  DeleteQuestionMutation,
  DeleteQuestionMutationVariables
>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteQuestionMutation,
    DeleteQuestionMutationVariables
  >
) {
  return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(
    DeleteQuestionDocument,
    baseOptions
  );
}
export type DeleteQuestionMutationHookResult = ReturnType<
  typeof useDeleteQuestionMutation
>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeleteQuestionMutation,
  DeleteQuestionMutationVariables
>;
export const DeleteDataDocument = gql`
  mutation DeleteData($id: ID!) {
    deleteData(id: $id)
  }
`;
export type DeleteDataMutationFn = Apollo.MutationFunction<
  DeleteDataMutation,
  DeleteDataMutationVariables
>;

/**
 * __useDeleteDataMutation__
 *
 * To run a mutation, you first call `useDeleteDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataMutation, { data, loading, error }] = useDeleteDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDataMutation,
    DeleteDataMutationVariables
  >
) {
  return Apollo.useMutation<DeleteDataMutation, DeleteDataMutationVariables>(
    DeleteDataDocument,
    baseOptions
  );
}
export type DeleteDataMutationHookResult = ReturnType<typeof useDeleteDataMutation>;
export type DeleteDataMutationResult = Apollo.MutationResult<DeleteDataMutation>;
export type DeleteDataMutationOptions = Apollo.BaseMutationOptions<
  DeleteDataMutation,
  DeleteDataMutationVariables
>;
export const UpdateQuestionFollowerDocument = gql`
  mutation UpdateQuestionFollower($followedID: ID!, $linked: Boolean!) {
    updateQuestionFollower(followedID: $followedID, linked: $linked) {
      id
      beforeLinked
      afterLinked
      afterScore
    }
  }
`;
export type UpdateQuestionFollowerMutationFn = Apollo.MutationFunction<
  UpdateQuestionFollowerMutation,
  UpdateQuestionFollowerMutationVariables
>;

/**
 * __useUpdateQuestionFollowerMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionFollowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionFollowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionFollowerMutation, { data, loading, error }] = useUpdateQuestionFollowerMutation({
 *   variables: {
 *      followedID: // value for 'followedID'
 *      linked: // value for 'linked'
 *   },
 * });
 */
export function useUpdateQuestionFollowerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuestionFollowerMutation,
    UpdateQuestionFollowerMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateQuestionFollowerMutation,
    UpdateQuestionFollowerMutationVariables
  >(UpdateQuestionFollowerDocument, baseOptions);
}
export type UpdateQuestionFollowerMutationHookResult = ReturnType<
  typeof useUpdateQuestionFollowerMutation
>;
export type UpdateQuestionFollowerMutationResult = Apollo.MutationResult<UpdateQuestionFollowerMutation>;
export type UpdateQuestionFollowerMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuestionFollowerMutation,
  UpdateQuestionFollowerMutationVariables
>;
export const UpdateDataFollowerDocument = gql`
  mutation UpdateDataFollower($followedID: ID!, $linked: Boolean!) {
    updateDataFollower(followedID: $followedID, linked: $linked) {
      id
      beforeLinked
      afterLinked
      afterScore
    }
  }
`;
export type UpdateDataFollowerMutationFn = Apollo.MutationFunction<
  UpdateDataFollowerMutation,
  UpdateDataFollowerMutationVariables
>;

/**
 * __useUpdateDataFollowerMutation__
 *
 * To run a mutation, you first call `useUpdateDataFollowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataFollowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataFollowerMutation, { data, loading, error }] = useUpdateDataFollowerMutation({
 *   variables: {
 *      followedID: // value for 'followedID'
 *      linked: // value for 'linked'
 *   },
 * });
 */
export function useUpdateDataFollowerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDataFollowerMutation,
    UpdateDataFollowerMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateDataFollowerMutation,
    UpdateDataFollowerMutationVariables
  >(UpdateDataFollowerDocument, baseOptions);
}
export type UpdateDataFollowerMutationHookResult = ReturnType<
  typeof useUpdateDataFollowerMutation
>;
export type UpdateDataFollowerMutationResult = Apollo.MutationResult<UpdateDataFollowerMutation>;
export type UpdateDataFollowerMutationOptions = Apollo.BaseMutationOptions<
  UpdateDataFollowerMutation,
  UpdateDataFollowerMutationVariables
>;
export const UpdateUserFollowerDocument = gql`
  mutation UpdateUserFollower($followedID: ID!, $linked: Boolean!) {
    updateUserFollower(followedID: $followedID, linked: $linked) {
      id
      beforeLinked
      afterLinked
      afterScore
    }
  }
`;
export type UpdateUserFollowerMutationFn = Apollo.MutationFunction<
  UpdateUserFollowerMutation,
  UpdateUserFollowerMutationVariables
>;

/**
 * __useUpdateUserFollowerMutation__
 *
 * To run a mutation, you first call `useUpdateUserFollowerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFollowerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFollowerMutation, { data, loading, error }] = useUpdateUserFollowerMutation({
 *   variables: {
 *      followedID: // value for 'followedID'
 *      linked: // value for 'linked'
 *   },
 * });
 */
export function useUpdateUserFollowerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserFollowerMutation,
    UpdateUserFollowerMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserFollowerMutation,
    UpdateUserFollowerMutationVariables
  >(UpdateUserFollowerDocument, baseOptions);
}
export type UpdateUserFollowerMutationHookResult = ReturnType<
  typeof useUpdateUserFollowerMutation
>;
export type UpdateUserFollowerMutationResult = Apollo.MutationResult<UpdateUserFollowerMutation>;
export type UpdateUserFollowerMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserFollowerMutation,
  UpdateUserFollowerMutationVariables
>;
export const ProcessTrainCarsDocument = gql`
  mutation ProcessTrainCars($input: ProcessTrainCarsInput!) {
    processTrainCars(input: $input) {
      id
      carType
      createdNew
    }
  }
`;
export type ProcessTrainCarsMutationFn = Apollo.MutationFunction<
  ProcessTrainCarsMutation,
  ProcessTrainCarsMutationVariables
>;

/**
 * __useProcessTrainCarsMutation__
 *
 * To run a mutation, you first call `useProcessTrainCarsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessTrainCarsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processTrainCarsMutation, { data, loading, error }] = useProcessTrainCarsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessTrainCarsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessTrainCarsMutation,
    ProcessTrainCarsMutationVariables
  >
) {
  return Apollo.useMutation<
    ProcessTrainCarsMutation,
    ProcessTrainCarsMutationVariables
  >(ProcessTrainCarsDocument, baseOptions);
}
export type ProcessTrainCarsMutationHookResult = ReturnType<
  typeof useProcessTrainCarsMutation
>;
export type ProcessTrainCarsMutationResult = Apollo.MutationResult<ProcessTrainCarsMutation>;
export type ProcessTrainCarsMutationOptions = Apollo.BaseMutationOptions<
  ProcessTrainCarsMutation,
  ProcessTrainCarsMutationVariables
>;
export const UpdateQuestionManagedDocument = gql`
  mutation UpdateQuestionManaged($input: UpdateQuestionManagedInput!) {
    updateQuestionManaged(input: $input) {
      id
      updatedAt
      text
      topicNames
    }
  }
`;
export type UpdateQuestionManagedMutationFn = Apollo.MutationFunction<
  UpdateQuestionManagedMutation,
  UpdateQuestionManagedMutationVariables
>;

/**
 * __useUpdateQuestionManagedMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionManagedMutation, { data, loading, error }] = useUpdateQuestionManagedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuestionManagedMutation,
    UpdateQuestionManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateQuestionManagedMutation,
    UpdateQuestionManagedMutationVariables
  >(UpdateQuestionManagedDocument, baseOptions);
}
export type UpdateQuestionManagedMutationHookResult = ReturnType<
  typeof useUpdateQuestionManagedMutation
>;
export type UpdateQuestionManagedMutationResult = Apollo.MutationResult<UpdateQuestionManagedMutation>;
export type UpdateQuestionManagedMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuestionManagedMutation,
  UpdateQuestionManagedMutationVariables
>;
export const UpdateDataManagedDocument = gql`
  mutation UpdateDataManaged($input: UpdateDataManagedInput!) {
    updateDataManaged(input: $input) {
      id
      owner
      ownerUsername
      name
      header {
        name
        columnType
        required
        exampleValue
      }
      snippet
      notes
      s3Bucket
      s3Key
      viewsCounter
      isRequest
    }
  }
`;
export type UpdateDataManagedMutationFn = Apollo.MutationFunction<
  UpdateDataManagedMutation,
  UpdateDataManagedMutationVariables
>;

/**
 * __useUpdateDataManagedMutation__
 *
 * To run a mutation, you first call `useUpdateDataManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataManagedMutation, { data, loading, error }] = useUpdateDataManagedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDataManagedMutation,
    UpdateDataManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateDataManagedMutation,
    UpdateDataManagedMutationVariables
  >(UpdateDataManagedDocument, baseOptions);
}
export type UpdateDataManagedMutationHookResult = ReturnType<
  typeof useUpdateDataManagedMutation
>;
export type UpdateDataManagedMutationResult = Apollo.MutationResult<UpdateDataManagedMutation>;
export type UpdateDataManagedMutationOptions = Apollo.BaseMutationOptions<
  UpdateDataManagedMutation,
  UpdateDataManagedMutationVariables
>;
export const UpdateDataMetadataManagedDocument = gql`
  mutation UpdateDataMetadataManaged($input: UpdateDataMetadataManagedInput!) {
    updateDataMetadataManaged(input: $input) {
      id
      owner
      ownerUsername
      name
      header {
        name
        columnType
        required
        exampleValue
      }
      snippet
      notes
      s3Bucket
      s3Key
      viewsCounter
      isRequest
    }
  }
`;
export type UpdateDataMetadataManagedMutationFn = Apollo.MutationFunction<
  UpdateDataMetadataManagedMutation,
  UpdateDataMetadataManagedMutationVariables
>;

/**
 * __useUpdateDataMetadataManagedMutation__
 *
 * To run a mutation, you first call `useUpdateDataMetadataManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataMetadataManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataMetadataManagedMutation, { data, loading, error }] = useUpdateDataMetadataManagedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataMetadataManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDataMetadataManagedMutation,
    UpdateDataMetadataManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateDataMetadataManagedMutation,
    UpdateDataMetadataManagedMutationVariables
  >(UpdateDataMetadataManagedDocument, baseOptions);
}
export type UpdateDataMetadataManagedMutationHookResult = ReturnType<
  typeof useUpdateDataMetadataManagedMutation
>;
export type UpdateDataMetadataManagedMutationResult = Apollo.MutationResult<UpdateDataMetadataManagedMutation>;
export type UpdateDataMetadataManagedMutationOptions = Apollo.BaseMutationOptions<
  UpdateDataMetadataManagedMutation,
  UpdateDataMetadataManagedMutationVariables
>;
export const CreateUserOwnsQuestionDocument = gql`
  mutation CreateUserOwnsQuestion(
    $input: CreateUserOwnsQuestionInput!
    $condition: ModelUserOwnsQuestionConditionInput
  ) {
    createUserOwnsQuestion(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      userID
      questionID
      createdAt
      user {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      question {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateUserOwnsQuestionMutationFn = Apollo.MutationFunction<
  CreateUserOwnsQuestionMutation,
  CreateUserOwnsQuestionMutationVariables
>;

/**
 * __useCreateUserOwnsQuestionMutation__
 *
 * To run a mutation, you first call `useCreateUserOwnsQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOwnsQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOwnsQuestionMutation, { data, loading, error }] = useCreateUserOwnsQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateUserOwnsQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserOwnsQuestionMutation,
    CreateUserOwnsQuestionMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserOwnsQuestionMutation,
    CreateUserOwnsQuestionMutationVariables
  >(CreateUserOwnsQuestionDocument, baseOptions);
}
export type CreateUserOwnsQuestionMutationHookResult = ReturnType<
  typeof useCreateUserOwnsQuestionMutation
>;
export type CreateUserOwnsQuestionMutationResult = Apollo.MutationResult<CreateUserOwnsQuestionMutation>;
export type CreateUserOwnsQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateUserOwnsQuestionMutation,
  CreateUserOwnsQuestionMutationVariables
>;
export const CreateUserOwnsQuestTrainDocument = gql`
  mutation CreateUserOwnsQuestTrain(
    $input: CreateUserOwnsQuestTrainInput!
    $condition: ModelUserOwnsQuestTrainConditionInput
  ) {
    createUserOwnsQuestTrain(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      userID
      questTrainID
      createdAt
      user {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      questTrain {
        id
        owner
        viewsCounter
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateUserOwnsQuestTrainMutationFn = Apollo.MutationFunction<
  CreateUserOwnsQuestTrainMutation,
  CreateUserOwnsQuestTrainMutationVariables
>;

/**
 * __useCreateUserOwnsQuestTrainMutation__
 *
 * To run a mutation, you first call `useCreateUserOwnsQuestTrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOwnsQuestTrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOwnsQuestTrainMutation, { data, loading, error }] = useCreateUserOwnsQuestTrainMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateUserOwnsQuestTrainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserOwnsQuestTrainMutation,
    CreateUserOwnsQuestTrainMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserOwnsQuestTrainMutation,
    CreateUserOwnsQuestTrainMutationVariables
  >(CreateUserOwnsQuestTrainDocument, baseOptions);
}
export type CreateUserOwnsQuestTrainMutationHookResult = ReturnType<
  typeof useCreateUserOwnsQuestTrainMutation
>;
export type CreateUserOwnsQuestTrainMutationResult = Apollo.MutationResult<CreateUserOwnsQuestTrainMutation>;
export type CreateUserOwnsQuestTrainMutationOptions = Apollo.BaseMutationOptions<
  CreateUserOwnsQuestTrainMutation,
  CreateUserOwnsQuestTrainMutationVariables
>;
export const CreateUserFollowsQuestionDocument = gql`
  mutation CreateUserFollowsQuestion(
    $input: CreateUserFollowsQuestionInput!
    $condition: ModelUserFollowsQuestionConditionInput
  ) {
    createUserFollowsQuestion(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      userID
      questionID
      createdAt
      user {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      question {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateUserFollowsQuestionMutationFn = Apollo.MutationFunction<
  CreateUserFollowsQuestionMutation,
  CreateUserFollowsQuestionMutationVariables
>;

/**
 * __useCreateUserFollowsQuestionMutation__
 *
 * To run a mutation, you first call `useCreateUserFollowsQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFollowsQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFollowsQuestionMutation, { data, loading, error }] = useCreateUserFollowsQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateUserFollowsQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFollowsQuestionMutation,
    CreateUserFollowsQuestionMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserFollowsQuestionMutation,
    CreateUserFollowsQuestionMutationVariables
  >(CreateUserFollowsQuestionDocument, baseOptions);
}
export type CreateUserFollowsQuestionMutationHookResult = ReturnType<
  typeof useCreateUserFollowsQuestionMutation
>;
export type CreateUserFollowsQuestionMutationResult = Apollo.MutationResult<CreateUserFollowsQuestionMutation>;
export type CreateUserFollowsQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFollowsQuestionMutation,
  CreateUserFollowsQuestionMutationVariables
>;
export const CreateUserFollowsDataDocument = gql`
  mutation CreateUserFollowsData(
    $input: CreateUserFollowsDataInput!
    $condition: ModelUserFollowsDataConditionInput
  ) {
    createUserFollowsData(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      userID
      dataID
      createdAt
      user {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      data {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateUserFollowsDataMutationFn = Apollo.MutationFunction<
  CreateUserFollowsDataMutation,
  CreateUserFollowsDataMutationVariables
>;

/**
 * __useCreateUserFollowsDataMutation__
 *
 * To run a mutation, you first call `useCreateUserFollowsDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFollowsDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFollowsDataMutation, { data, loading, error }] = useCreateUserFollowsDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateUserFollowsDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFollowsDataMutation,
    CreateUserFollowsDataMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserFollowsDataMutation,
    CreateUserFollowsDataMutationVariables
  >(CreateUserFollowsDataDocument, baseOptions);
}
export type CreateUserFollowsDataMutationHookResult = ReturnType<
  typeof useCreateUserFollowsDataMutation
>;
export type CreateUserFollowsDataMutationResult = Apollo.MutationResult<CreateUserFollowsDataMutation>;
export type CreateUserFollowsDataMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFollowsDataMutation,
  CreateUserFollowsDataMutationVariables
>;
export const CreateUserFollowsUserDocument = gql`
  mutation CreateUserFollowsUser(
    $input: CreateUserFollowsUserInput!
    $condition: ModelUserFollowsUserConditionInput
  ) {
    createUserFollowsUser(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      followerID
      followedID
      createdAt
      follower {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      followed {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
    }
  }
`;
export type CreateUserFollowsUserMutationFn = Apollo.MutationFunction<
  CreateUserFollowsUserMutation,
  CreateUserFollowsUserMutationVariables
>;

/**
 * __useCreateUserFollowsUserMutation__
 *
 * To run a mutation, you first call `useCreateUserFollowsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFollowsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFollowsUserMutation, { data, loading, error }] = useCreateUserFollowsUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateUserFollowsUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFollowsUserMutation,
    CreateUserFollowsUserMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserFollowsUserMutation,
    CreateUserFollowsUserMutationVariables
  >(CreateUserFollowsUserDocument, baseOptions);
}
export type CreateUserFollowsUserMutationHookResult = ReturnType<
  typeof useCreateUserFollowsUserMutation
>;
export type CreateUserFollowsUserMutationResult = Apollo.MutationResult<CreateUserFollowsUserMutation>;
export type CreateUserFollowsUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFollowsUserMutation,
  CreateUserFollowsUserMutationVariables
>;
export const CreateQuestionDocument = gql`
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      owner
      text
      viewsCounter
      topicNames
      createdAt
      updatedAt
      ownerEdge {
        id
        updatedAt
        owner
        userID
        questionID
        createdAt
      }
      userFollows {
        nextToken
      }
      questionsForward {
        nextToken
      }
      questionsBackward {
        nextToken
      }
      questTrains {
        nextToken
      }
      data {
        nextToken
      }
    }
  }
`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<
  CreateQuestionMutation,
  CreateQuestionMutationVariables
>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestionMutation,
    CreateQuestionMutationVariables
  >
) {
  return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(
    CreateQuestionDocument,
    baseOptions
  );
}
export type CreateQuestionMutationHookResult = ReturnType<
  typeof useCreateQuestionMutation
>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestionMutation,
  CreateQuestionMutationVariables
>;
export const UpdateQuestionDocument = gql`
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      owner
      text
      viewsCounter
      topicNames
      createdAt
      updatedAt
      ownerEdge {
        id
        updatedAt
        owner
        userID
        questionID
        createdAt
      }
      userFollows {
        nextToken
      }
      questionsForward {
        nextToken
      }
      questionsBackward {
        nextToken
      }
      questTrains {
        nextToken
      }
      data {
        nextToken
      }
    }
  }
`;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<
  UpdateQuestionMutation,
  UpdateQuestionMutationVariables
>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUpdateQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuestionMutation,
    UpdateQuestionMutationVariables
  >
) {
  return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(
    UpdateQuestionDocument,
    baseOptions
  );
}
export type UpdateQuestionMutationHookResult = ReturnType<
  typeof useUpdateQuestionMutation
>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuestionMutation,
  UpdateQuestionMutationVariables
>;
export const CreateQuestionEdgeDocument = gql`
  mutation CreateQuestionEdge(
    $input: CreateQuestionEdgeInput!
    $condition: ModelQuestionEdgeConditionInput
  ) {
    createQuestionEdge(input: $input, condition: $condition) {
      id
      owner
      aID
      bID
      score
      createdAt
      updatedAt
      questionA {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      questionB {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateQuestionEdgeMutationFn = Apollo.MutationFunction<
  CreateQuestionEdgeMutation,
  CreateQuestionEdgeMutationVariables
>;

/**
 * __useCreateQuestionEdgeMutation__
 *
 * To run a mutation, you first call `useCreateQuestionEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionEdgeMutation, { data, loading, error }] = useCreateQuestionEdgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateQuestionEdgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestionEdgeMutation,
    CreateQuestionEdgeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateQuestionEdgeMutation,
    CreateQuestionEdgeMutationVariables
  >(CreateQuestionEdgeDocument, baseOptions);
}
export type CreateQuestionEdgeMutationHookResult = ReturnType<
  typeof useCreateQuestionEdgeMutation
>;
export type CreateQuestionEdgeMutationResult = Apollo.MutationResult<CreateQuestionEdgeMutation>;
export type CreateQuestionEdgeMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestionEdgeMutation,
  CreateQuestionEdgeMutationVariables
>;
export const CreateDataDataEdgeDocument = gql`
  mutation CreateDataDataEdge(
    $input: CreateDataDataEdgeInput!
    $condition: ModelDataDataEdgeConditionInput
  ) {
    createDataDataEdge(input: $input, condition: $condition) {
      id
      owner
      aID
      bID
      score
      createdAt
      updatedAt
      dataA {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
      dataB {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateDataDataEdgeMutationFn = Apollo.MutationFunction<
  CreateDataDataEdgeMutation,
  CreateDataDataEdgeMutationVariables
>;

/**
 * __useCreateDataDataEdgeMutation__
 *
 * To run a mutation, you first call `useCreateDataDataEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataDataEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataDataEdgeMutation, { data, loading, error }] = useCreateDataDataEdgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateDataDataEdgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDataDataEdgeMutation,
    CreateDataDataEdgeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateDataDataEdgeMutation,
    CreateDataDataEdgeMutationVariables
  >(CreateDataDataEdgeDocument, baseOptions);
}
export type CreateDataDataEdgeMutationHookResult = ReturnType<
  typeof useCreateDataDataEdgeMutation
>;
export type CreateDataDataEdgeMutationResult = Apollo.MutationResult<CreateDataDataEdgeMutation>;
export type CreateDataDataEdgeMutationOptions = Apollo.BaseMutationOptions<
  CreateDataDataEdgeMutation,
  CreateDataDataEdgeMutationVariables
>;
export const CreateQuestTrainDocument = gql`
  mutation CreateQuestTrain(
    $input: CreateQuestTrainInput!
    $condition: ModelQuestTrainConditionInput
  ) {
    createQuestTrain(input: $input, condition: $condition) {
      id
      owner
      carSpecs {
        id
        carType
      }
      viewsCounter
      createdAt
      updatedAt
      ownerEdge {
        id
        updatedAt
        owner
        userID
        questTrainID
        createdAt
      }
      questions {
        nextToken
      }
    }
  }
`;
export type CreateQuestTrainMutationFn = Apollo.MutationFunction<
  CreateQuestTrainMutation,
  CreateQuestTrainMutationVariables
>;

/**
 * __useCreateQuestTrainMutation__
 *
 * To run a mutation, you first call `useCreateQuestTrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestTrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestTrainMutation, { data, loading, error }] = useCreateQuestTrainMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateQuestTrainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestTrainMutation,
    CreateQuestTrainMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateQuestTrainMutation,
    CreateQuestTrainMutationVariables
  >(CreateQuestTrainDocument, baseOptions);
}
export type CreateQuestTrainMutationHookResult = ReturnType<
  typeof useCreateQuestTrainMutation
>;
export type CreateQuestTrainMutationResult = Apollo.MutationResult<CreateQuestTrainMutation>;
export type CreateQuestTrainMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestTrainMutation,
  CreateQuestTrainMutationVariables
>;
export const UpdateQuestTrainDocument = gql`
  mutation UpdateQuestTrain(
    $input: UpdateQuestTrainInput!
    $condition: ModelQuestTrainConditionInput
  ) {
    updateQuestTrain(input: $input, condition: $condition) {
      id
      owner
      carSpecs {
        id
        carType
      }
      viewsCounter
      createdAt
      updatedAt
      ownerEdge {
        id
        updatedAt
        owner
        userID
        questTrainID
        createdAt
      }
      questions {
        nextToken
      }
    }
  }
`;
export type UpdateQuestTrainMutationFn = Apollo.MutationFunction<
  UpdateQuestTrainMutation,
  UpdateQuestTrainMutationVariables
>;

/**
 * __useUpdateQuestTrainMutation__
 *
 * To run a mutation, you first call `useUpdateQuestTrainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestTrainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestTrainMutation, { data, loading, error }] = useUpdateQuestTrainMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUpdateQuestTrainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuestTrainMutation,
    UpdateQuestTrainMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateQuestTrainMutation,
    UpdateQuestTrainMutationVariables
  >(UpdateQuestTrainDocument, baseOptions);
}
export type UpdateQuestTrainMutationHookResult = ReturnType<
  typeof useUpdateQuestTrainMutation
>;
export type UpdateQuestTrainMutationResult = Apollo.MutationResult<UpdateQuestTrainMutation>;
export type UpdateQuestTrainMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuestTrainMutation,
  UpdateQuestTrainMutationVariables
>;
export const CreateQuestTrainQuestionEdgeDocument = gql`
  mutation CreateQuestTrainQuestionEdge(
    $input: CreateQuestTrainQuestionEdgeInput!
    $condition: ModelQuestTrainQuestionEdgeConditionInput
  ) {
    createQuestTrainQuestionEdge(input: $input, condition: $condition) {
      id
      owner
      questTrainID
      questionID
      createdAt
      updatedAt
      question {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      questTrain {
        id
        owner
        viewsCounter
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateQuestTrainQuestionEdgeMutationFn = Apollo.MutationFunction<
  CreateQuestTrainQuestionEdgeMutation,
  CreateQuestTrainQuestionEdgeMutationVariables
>;

/**
 * __useCreateQuestTrainQuestionEdgeMutation__
 *
 * To run a mutation, you first call `useCreateQuestTrainQuestionEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestTrainQuestionEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestTrainQuestionEdgeMutation, { data, loading, error }] = useCreateQuestTrainQuestionEdgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateQuestTrainQuestionEdgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestTrainQuestionEdgeMutation,
    CreateQuestTrainQuestionEdgeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateQuestTrainQuestionEdgeMutation,
    CreateQuestTrainQuestionEdgeMutationVariables
  >(CreateQuestTrainQuestionEdgeDocument, baseOptions);
}
export type CreateQuestTrainQuestionEdgeMutationHookResult = ReturnType<
  typeof useCreateQuestTrainQuestionEdgeMutation
>;
export type CreateQuestTrainQuestionEdgeMutationResult = Apollo.MutationResult<CreateQuestTrainQuestionEdgeMutation>;
export type CreateQuestTrainQuestionEdgeMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestTrainQuestionEdgeMutation,
  CreateQuestTrainQuestionEdgeMutationVariables
>;
export const CreateDataDocument = gql`
  mutation CreateData($input: CreateDataInput!, $condition: ModelDataConditionInput) {
    createData(input: $input, condition: $condition) {
      id
      owner
      name
      header {
        name
        columnType
        required
        exampleValue
      }
      snippet
      numRows
      fileSizeBytes
      references {
        name
        link
      }
      notes
      s3Bucket
      s3Key
      s3Prefix
      s3Basename
      viewsCounter
      isRequest
      topicNames
      createdAt
      updatedAt
      userFollows {
        nextToken
      }
      datasForward {
        nextToken
      }
      datasBackward {
        nextToken
      }
      ownerEdge {
        id
        updatedAt
        owner
        userID
        dataID
        createdAt
      }
      questions {
        nextToken
      }
    }
  }
`;
export type CreateDataMutationFn = Apollo.MutationFunction<
  CreateDataMutation,
  CreateDataMutationVariables
>;

/**
 * __useCreateDataMutation__
 *
 * To run a mutation, you first call `useCreateDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataMutation, { data, loading, error }] = useCreateDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDataMutation,
    CreateDataMutationVariables
  >
) {
  return Apollo.useMutation<CreateDataMutation, CreateDataMutationVariables>(
    CreateDataDocument,
    baseOptions
  );
}
export type CreateDataMutationHookResult = ReturnType<typeof useCreateDataMutation>;
export type CreateDataMutationResult = Apollo.MutationResult<CreateDataMutation>;
export type CreateDataMutationOptions = Apollo.BaseMutationOptions<
  CreateDataMutation,
  CreateDataMutationVariables
>;
export const UpdateDataDocument = gql`
  mutation UpdateData($input: UpdateDataInput!, $condition: ModelDataConditionInput) {
    updateData(input: $input, condition: $condition) {
      id
      owner
      name
      header {
        name
        columnType
        required
        exampleValue
      }
      snippet
      numRows
      fileSizeBytes
      references {
        name
        link
      }
      notes
      s3Bucket
      s3Key
      s3Prefix
      s3Basename
      viewsCounter
      isRequest
      topicNames
      createdAt
      updatedAt
      userFollows {
        nextToken
      }
      datasForward {
        nextToken
      }
      datasBackward {
        nextToken
      }
      ownerEdge {
        id
        updatedAt
        owner
        userID
        dataID
        createdAt
      }
      questions {
        nextToken
      }
    }
  }
`;
export type UpdateDataMutationFn = Apollo.MutationFunction<
  UpdateDataMutation,
  UpdateDataMutationVariables
>;

/**
 * __useUpdateDataMutation__
 *
 * To run a mutation, you first call `useUpdateDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataMutation, { data, loading, error }] = useUpdateDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUpdateDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDataMutation,
    UpdateDataMutationVariables
  >
) {
  return Apollo.useMutation<UpdateDataMutation, UpdateDataMutationVariables>(
    UpdateDataDocument,
    baseOptions
  );
}
export type UpdateDataMutationHookResult = ReturnType<typeof useUpdateDataMutation>;
export type UpdateDataMutationResult = Apollo.MutationResult<UpdateDataMutation>;
export type UpdateDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateDataMutation,
  UpdateDataMutationVariables
>;
export const CreateUserOwnsDataDocument = gql`
  mutation CreateUserOwnsData(
    $input: CreateUserOwnsDataInput!
    $condition: ModelUserOwnsDataConditionInput
  ) {
    createUserOwnsData(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      userID
      dataID
      createdAt
      user {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      data {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateUserOwnsDataMutationFn = Apollo.MutationFunction<
  CreateUserOwnsDataMutation,
  CreateUserOwnsDataMutationVariables
>;

/**
 * __useCreateUserOwnsDataMutation__
 *
 * To run a mutation, you first call `useCreateUserOwnsDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOwnsDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOwnsDataMutation, { data, loading, error }] = useCreateUserOwnsDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateUserOwnsDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserOwnsDataMutation,
    CreateUserOwnsDataMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserOwnsDataMutation,
    CreateUserOwnsDataMutationVariables
  >(CreateUserOwnsDataDocument, baseOptions);
}
export type CreateUserOwnsDataMutationHookResult = ReturnType<
  typeof useCreateUserOwnsDataMutation
>;
export type CreateUserOwnsDataMutationResult = Apollo.MutationResult<CreateUserOwnsDataMutation>;
export type CreateUserOwnsDataMutationOptions = Apollo.BaseMutationOptions<
  CreateUserOwnsDataMutation,
  CreateUserOwnsDataMutationVariables
>;
export const CreateQuestionDataEdgeDocument = gql`
  mutation CreateQuestionDataEdge(
    $input: CreateQuestionDataEdgeInput!
    $condition: ModelQuestionDataEdgeConditionInput
  ) {
    createQuestionDataEdge(input: $input, condition: $condition) {
      id
      owner
      questionID
      dataID
      score
      createdAt
      updatedAt
      question {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      data {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateQuestionDataEdgeMutationFn = Apollo.MutationFunction<
  CreateQuestionDataEdgeMutation,
  CreateQuestionDataEdgeMutationVariables
>;

/**
 * __useCreateQuestionDataEdgeMutation__
 *
 * To run a mutation, you first call `useCreateQuestionDataEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionDataEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionDataEdgeMutation, { data, loading, error }] = useCreateQuestionDataEdgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateQuestionDataEdgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestionDataEdgeMutation,
    CreateQuestionDataEdgeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateQuestionDataEdgeMutation,
    CreateQuestionDataEdgeMutationVariables
  >(CreateQuestionDataEdgeDocument, baseOptions);
}
export type CreateQuestionDataEdgeMutationHookResult = ReturnType<
  typeof useCreateQuestionDataEdgeMutation
>;
export type CreateQuestionDataEdgeMutationResult = Apollo.MutationResult<CreateQuestionDataEdgeMutation>;
export type CreateQuestionDataEdgeMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestionDataEdgeMutation,
  CreateQuestionDataEdgeMutationVariables
>;
export const CreateNotificationDocument = gql`
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      actorUserID
      actorQuestionID
      actorDataID
      followedQuestionID
      followedDataID
      read
      pushNotified
      createdAt
      actorUser {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      actorQuestion {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      followedQuestion {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      actorData {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
      followedData {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >(CreateNotificationDocument, baseOptions);
}
export type CreateNotificationMutationHookResult = ReturnType<
  typeof useCreateNotificationMutation
>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;
export const UpdateNotificationDocument = gql`
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      actorUserID
      actorQuestionID
      actorDataID
      followedQuestionID
      followedDataID
      read
      pushNotified
      createdAt
      actorUser {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      actorQuestion {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      followedQuestion {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      actorData {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
      followedData {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
    }
  }
`;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useUpdateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateNotificationMutation,
    UpdateNotificationMutationVariables
  >(UpdateNotificationDocument, baseOptions);
}
export type UpdateNotificationMutationHookResult = ReturnType<
  typeof useUpdateNotificationMutation
>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationMutation,
  UpdateNotificationMutationVariables
>;
export const CreateTopicDocument = gql`
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      name
      description
      followerCount
      entityCount
      sortable
      createdAt
      updatedAt
      userFollows {
        nextToken
      }
    }
  }
`;
export type CreateTopicMutationFn = Apollo.MutationFunction<
  CreateTopicMutation,
  CreateTopicMutationVariables
>;

/**
 * __useCreateTopicMutation__
 *
 * To run a mutation, you first call `useCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicMutation, { data, loading, error }] = useCreateTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTopicMutation,
    CreateTopicMutationVariables
  >
) {
  return Apollo.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(
    CreateTopicDocument,
    baseOptions
  );
}
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<CreateTopicMutation>;
export type CreateTopicMutationOptions = Apollo.BaseMutationOptions<
  CreateTopicMutation,
  CreateTopicMutationVariables
>;
export const CreateUserFollowsTopicDocument = gql`
  mutation CreateUserFollowsTopic(
    $input: CreateUserFollowsTopicInput!
    $condition: ModelUserFollowsTopicConditionInput
  ) {
    createUserFollowsTopic(input: $input, condition: $condition) {
      id
      updatedAt
      owner
      userID
      topicID
      createdAt
      user {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      topic {
        id
        name
        description
        followerCount
        entityCount
        sortable
        createdAt
        updatedAt
      }
    }
  }
`;
export type CreateUserFollowsTopicMutationFn = Apollo.MutationFunction<
  CreateUserFollowsTopicMutation,
  CreateUserFollowsTopicMutationVariables
>;

/**
 * __useCreateUserFollowsTopicMutation__
 *
 * To run a mutation, you first call `useCreateUserFollowsTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFollowsTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFollowsTopicMutation, { data, loading, error }] = useCreateUserFollowsTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCreateUserFollowsTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFollowsTopicMutation,
    CreateUserFollowsTopicMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserFollowsTopicMutation,
    CreateUserFollowsTopicMutationVariables
  >(CreateUserFollowsTopicDocument, baseOptions);
}
export type CreateUserFollowsTopicMutationHookResult = ReturnType<
  typeof useCreateUserFollowsTopicMutation
>;
export type CreateUserFollowsTopicMutationResult = Apollo.MutationResult<CreateUserFollowsTopicMutation>;
export type CreateUserFollowsTopicMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFollowsTopicMutation,
  CreateUserFollowsTopicMutationVariables
>;
export const SearchVectorIndexDocument = gql`
  query SearchVectorIndex(
    $query: String!
    $limit: Int!
    $scoreThreshold: Float!
    $filter: SearchFilterInput
    $from: Int
  ) {
    searchVectorIndex(
      query: $query
      limit: $limit
      scoreThreshold: $scoreThreshold
      filter: $filter
      from: $from
    ) {
      results {
        carType
      }
      from
    }
  }
`;

/**
 * __useSearchVectorIndexQuery__
 *
 * To run a query within a React component, call `useSearchVectorIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVectorIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVectorIndexQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      scoreThreshold: // value for 'scoreThreshold'
 *      filter: // value for 'filter'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSearchVectorIndexQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchVectorIndexQuery,
    SearchVectorIndexQueryVariables
  >
) {
  return Apollo.useQuery<SearchVectorIndexQuery, SearchVectorIndexQueryVariables>(
    SearchVectorIndexDocument,
    baseOptions
  );
}
export function useSearchVectorIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchVectorIndexQuery,
    SearchVectorIndexQueryVariables
  >
) {
  return Apollo.useLazyQuery<SearchVectorIndexQuery, SearchVectorIndexQueryVariables>(
    SearchVectorIndexDocument,
    baseOptions
  );
}
export type SearchVectorIndexQueryHookResult = ReturnType<
  typeof useSearchVectorIndexQuery
>;
export type SearchVectorIndexLazyQueryHookResult = ReturnType<
  typeof useSearchVectorIndexLazyQuery
>;
export type SearchVectorIndexQueryResult = Apollo.QueryResult<
  SearchVectorIndexQuery,
  SearchVectorIndexQueryVariables
>;
export const SearchTraditionalIndexDocument = gql`
  query SearchTraditionalIndex(
    $query: String!
    $limit: Int!
    $filter: SearchFilterInput
    $from: Int
  ) {
    searchTraditionalIndex(query: $query, limit: $limit, filter: $filter, from: $from) {
      results {
        carType
      }
      from
    }
  }
`;

/**
 * __useSearchTraditionalIndexQuery__
 *
 * To run a query within a React component, call `useSearchTraditionalIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTraditionalIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTraditionalIndexQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSearchTraditionalIndexQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTraditionalIndexQuery,
    SearchTraditionalIndexQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchTraditionalIndexQuery,
    SearchTraditionalIndexQueryVariables
  >(SearchTraditionalIndexDocument, baseOptions);
}
export function useSearchTraditionalIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTraditionalIndexQuery,
    SearchTraditionalIndexQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchTraditionalIndexQuery,
    SearchTraditionalIndexQueryVariables
  >(SearchTraditionalIndexDocument, baseOptions);
}
export type SearchTraditionalIndexQueryHookResult = ReturnType<
  typeof useSearchTraditionalIndexQuery
>;
export type SearchTraditionalIndexLazyQueryHookResult = ReturnType<
  typeof useSearchTraditionalIndexLazyQuery
>;
export type SearchTraditionalIndexQueryResult = Apollo.QueryResult<
  SearchTraditionalIndexQuery,
  SearchTraditionalIndexQueryVariables
>;
export const RandomSampleDocument = gql`
  query RandomSample($limit: Int!, $from: Int, $filter: SearchFilterInput) {
    randomSample(limit: $limit, from: $from, filter: $filter) {
      results {
        carType
      }
      from
    }
  }
`;

/**
 * __useRandomSampleQuery__
 *
 * To run a query within a React component, call `useRandomSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomSampleQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRandomSampleQuery(
  baseOptions: Apollo.QueryHookOptions<RandomSampleQuery, RandomSampleQueryVariables>
) {
  return Apollo.useQuery<RandomSampleQuery, RandomSampleQueryVariables>(
    RandomSampleDocument,
    baseOptions
  );
}
export function useRandomSampleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RandomSampleQuery,
    RandomSampleQueryVariables
  >
) {
  return Apollo.useLazyQuery<RandomSampleQuery, RandomSampleQueryVariables>(
    RandomSampleDocument,
    baseOptions
  );
}
export type RandomSampleQueryHookResult = ReturnType<typeof useRandomSampleQuery>;
export type RandomSampleLazyQueryHookResult = ReturnType<
  typeof useRandomSampleLazyQuery
>;
export type RandomSampleQueryResult = Apollo.QueryResult<
  RandomSampleQuery,
  RandomSampleQueryVariables
>;
export const GetTopTopicsDocument = gql`
  query GetTopTopics($limit: Int!) {
    getTopTopics(limit: $limit) {
      id
      name
      score
    }
  }
`;

/**
 * __useGetTopTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopTopicsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTopTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTopTopicsQuery, GetTopTopicsQueryVariables>
) {
  return Apollo.useQuery<GetTopTopicsQuery, GetTopTopicsQueryVariables>(
    GetTopTopicsDocument,
    baseOptions
  );
}
export function useGetTopTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopTopicsQuery,
    GetTopTopicsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTopTopicsQuery, GetTopTopicsQueryVariables>(
    GetTopTopicsDocument,
    baseOptions
  );
}
export type GetTopTopicsQueryHookResult = ReturnType<typeof useGetTopTopicsQuery>;
export type GetTopTopicsLazyQueryHookResult = ReturnType<
  typeof useGetTopTopicsLazyQuery
>;
export type GetTopTopicsQueryResult = Apollo.QueryResult<
  GetTopTopicsQuery,
  GetTopTopicsQueryVariables
>;
export const GetPublicUserProfileDocument = gql`
  query GetPublicUserProfile($username: ID!) {
    getPublicUserProfile(username: $username) {
      id
      owner
      username
      ownedQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      ownedQuestTrains {
        id
        ownerUsername
        updatedAt
      }
      ownedDatas {
        id
        owner
        ownerUsername
        name
        isRequest
        updatedAt
      }
      followingUsers {
        id
        ownerUsername
        updatedAt
      }
      followedByUsers {
        id
        ownerUsername
        updatedAt
      }
      amIFollower
    }
  }
`;

/**
 * __useGetPublicUserProfileQuery__
 *
 * To run a query within a React component, call `useGetPublicUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicUserProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetPublicUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicUserProfileQuery,
    GetPublicUserProfileQueryVariables
  >
) {
  return Apollo.useQuery<GetPublicUserProfileQuery, GetPublicUserProfileQueryVariables>(
    GetPublicUserProfileDocument,
    baseOptions
  );
}
export function useGetPublicUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicUserProfileQuery,
    GetPublicUserProfileQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPublicUserProfileQuery,
    GetPublicUserProfileQueryVariables
  >(GetPublicUserProfileDocument, baseOptions);
}
export type GetPublicUserProfileQueryHookResult = ReturnType<
  typeof useGetPublicUserProfileQuery
>;
export type GetPublicUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetPublicUserProfileLazyQuery
>;
export type GetPublicUserProfileQueryResult = Apollo.QueryResult<
  GetPublicUserProfileQuery,
  GetPublicUserProfileQueryVariables
>;
export const GetMyUserProfileDocument = gql`
  query GetMyUserProfile {
    getMyUserProfile {
      id
      owner
      username
      name
      email
      followedQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      followedDatas {
        id
        owner
        ownerUsername
        name
        isRequest
        updatedAt
      }
      ownedQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      ownedQuestTrains {
        id
        ownerUsername
        updatedAt
      }
      ownedDatas {
        id
        owner
        ownerUsername
        name
        isRequest
        updatedAt
      }
      notifications {
        id
        owner
        createdAt
        updatedAt
        actorUserID
        actorUsername
        actorQuestionID
        actorDataID
        followedQuestionID
        followedDataID
        read
        pushNotified
      }
      followingUsers {
        id
        ownerUsername
        updatedAt
      }
      followedByUsers {
        id
        ownerUsername
        updatedAt
      }
      followedTopics {
        id
        name
        score
      }
    }
  }
`;

/**
 * __useGetMyUserProfileQuery__
 *
 * To run a query within a React component, call `useGetMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  return Apollo.useQuery<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>(
    GetMyUserProfileDocument,
    baseOptions
  );
}
export function useGetMyUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>(
    GetMyUserProfileDocument,
    baseOptions
  );
}
export type GetMyUserProfileQueryHookResult = ReturnType<
  typeof useGetMyUserProfileQuery
>;
export type GetMyUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyUserProfileLazyQuery
>;
export type GetMyUserProfileQueryResult = Apollo.QueryResult<
  GetMyUserProfileQuery,
  GetMyUserProfileQueryVariables
>;
export const GetQuestionManagedDocument = gql`
  query GetQuestionManaged($id: ID!) {
    getQuestionManaged(id: $id) {
      id
      updatedAt
      owner
      ownerUsername
      text
      neighborQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      amIFollower
      topicNames
    }
  }
`;

/**
 * __useGetQuestionManagedQuery__
 *
 * To run a query within a React component, call `useGetQuestionManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionManagedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionManagedQuery,
    GetQuestionManagedQueryVariables
  >
) {
  return Apollo.useQuery<GetQuestionManagedQuery, GetQuestionManagedQueryVariables>(
    GetQuestionManagedDocument,
    baseOptions
  );
}
export function useGetQuestionManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionManagedQuery,
    GetQuestionManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetQuestionManagedQuery, GetQuestionManagedQueryVariables>(
    GetQuestionManagedDocument,
    baseOptions
  );
}
export type GetQuestionManagedQueryHookResult = ReturnType<
  typeof useGetQuestionManagedQuery
>;
export type GetQuestionManagedLazyQueryHookResult = ReturnType<
  typeof useGetQuestionManagedLazyQuery
>;
export type GetQuestionManagedQueryResult = Apollo.QueryResult<
  GetQuestionManagedQuery,
  GetQuestionManagedQueryVariables
>;
export const GetDataManagedDocument = gql`
  query GetDataManaged($id: ID!) {
    getDataManaged(id: $id) {
      id
      updatedAt
      owner
      ownerUsername
      name
      header {
        name
        columnType
        required
        exampleValue
      }
      snippet
      numRows
      fileSizeBytes
      references {
        name
        link
      }
      notes
      s3Bucket
      s3Key
      s3Prefix
      s3Basename
      isRequest
      amIFollower
      topicNames
    }
  }
`;

/**
 * __useGetDataManagedQuery__
 *
 * To run a query within a React component, call `useGetDataManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataManagedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataManagedQuery,
    GetDataManagedQueryVariables
  >
) {
  return Apollo.useQuery<GetDataManagedQuery, GetDataManagedQueryVariables>(
    GetDataManagedDocument,
    baseOptions
  );
}
export function useGetDataManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataManagedQuery,
    GetDataManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDataManagedQuery, GetDataManagedQueryVariables>(
    GetDataManagedDocument,
    baseOptions
  );
}
export type GetDataManagedQueryHookResult = ReturnType<typeof useGetDataManagedQuery>;
export type GetDataManagedLazyQueryHookResult = ReturnType<
  typeof useGetDataManagedLazyQuery
>;
export type GetDataManagedQueryResult = Apollo.QueryResult<
  GetDataManagedQuery,
  GetDataManagedQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      username
      createdAt
      updatedAt
      name
      email
      ownedQuestions {
        nextToken
      }
      ownedQuestTrains {
        nextToken
      }
      followedQuestions {
        nextToken
      }
      followedDatas {
        nextToken
      }
      followingUsers {
        nextToken
      }
      followedByUsers {
        nextToken
      }
      ownedData {
        nextToken
      }
      notifications {
        nextToken
      }
      followedTopics {
        nextToken
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    baseOptions
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const ListUsersDocument = gql`
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        username
        createdAt
        updatedAt
        name
        email
      }
      nextToken
    }
  }
`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>
) {
  return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    baseOptions
  );
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>
) {
  return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    baseOptions
  );
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<
  ListUsersQuery,
  ListUsersQueryVariables
>;
export const GetUsernameLookupDocument = gql`
  query GetUsernameLookup($username: ID!) {
    getUsernameLookup(username: $username) {
      owner
      username
      userID
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUsernameLookupQuery__
 *
 * To run a query within a React component, call `useGetUsernameLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsernameLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsernameLookupQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUsernameLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsernameLookupQuery,
    GetUsernameLookupQueryVariables
  >
) {
  return Apollo.useQuery<GetUsernameLookupQuery, GetUsernameLookupQueryVariables>(
    GetUsernameLookupDocument,
    baseOptions
  );
}
export function useGetUsernameLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsernameLookupQuery,
    GetUsernameLookupQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUsernameLookupQuery, GetUsernameLookupQueryVariables>(
    GetUsernameLookupDocument,
    baseOptions
  );
}
export type GetUsernameLookupQueryHookResult = ReturnType<
  typeof useGetUsernameLookupQuery
>;
export type GetUsernameLookupLazyQueryHookResult = ReturnType<
  typeof useGetUsernameLookupLazyQuery
>;
export type GetUsernameLookupQueryResult = Apollo.QueryResult<
  GetUsernameLookupQuery,
  GetUsernameLookupQueryVariables
>;
export const GetEmailLookupDocument = gql`
  query GetEmailLookup($email: ID!) {
    getEmailLookup(email: $email) {
      owner
      email
      userID
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetEmailLookupQuery__
 *
 * To run a query within a React component, call `useGetEmailLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailLookupQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetEmailLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmailLookupQuery,
    GetEmailLookupQueryVariables
  >
) {
  return Apollo.useQuery<GetEmailLookupQuery, GetEmailLookupQueryVariables>(
    GetEmailLookupDocument,
    baseOptions
  );
}
export function useGetEmailLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailLookupQuery,
    GetEmailLookupQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetEmailLookupQuery, GetEmailLookupQueryVariables>(
    GetEmailLookupDocument,
    baseOptions
  );
}
export type GetEmailLookupQueryHookResult = ReturnType<typeof useGetEmailLookupQuery>;
export type GetEmailLookupLazyQueryHookResult = ReturnType<
  typeof useGetEmailLookupLazyQuery
>;
export type GetEmailLookupQueryResult = Apollo.QueryResult<
  GetEmailLookupQuery,
  GetEmailLookupQueryVariables
>;
export const ListUserFollowsQuestionsDocument = gql`
  query ListUserFollowsQuestions(
    $filter: ModelUserFollowsQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowsQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        owner
        userID
        questionID
        createdAt
      }
      nextToken
    }
  }
`;

/**
 * __useListUserFollowsQuestionsQuery__
 *
 * To run a query within a React component, call `useListUserFollowsQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserFollowsQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserFollowsQuestionsQuery,
    ListUserFollowsQuestionsQueryVariables
  >
) {
  return Apollo.useQuery<
    ListUserFollowsQuestionsQuery,
    ListUserFollowsQuestionsQueryVariables
  >(ListUserFollowsQuestionsDocument, baseOptions);
}
export function useListUserFollowsQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserFollowsQuestionsQuery,
    ListUserFollowsQuestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListUserFollowsQuestionsQuery,
    ListUserFollowsQuestionsQueryVariables
  >(ListUserFollowsQuestionsDocument, baseOptions);
}
export type ListUserFollowsQuestionsQueryHookResult = ReturnType<
  typeof useListUserFollowsQuestionsQuery
>;
export type ListUserFollowsQuestionsLazyQueryHookResult = ReturnType<
  typeof useListUserFollowsQuestionsLazyQuery
>;
export type ListUserFollowsQuestionsQueryResult = Apollo.QueryResult<
  ListUserFollowsQuestionsQuery,
  ListUserFollowsQuestionsQueryVariables
>;
export const ListUserFollowsDatasDocument = gql`
  query ListUserFollowsDatas(
    $filter: ModelUserFollowsDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowsDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        owner
        userID
        dataID
        createdAt
      }
      nextToken
    }
  }
`;

/**
 * __useListUserFollowsDatasQuery__
 *
 * To run a query within a React component, call `useListUserFollowsDatasQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsDatasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsDatasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserFollowsDatasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserFollowsDatasQuery,
    ListUserFollowsDatasQueryVariables
  >
) {
  return Apollo.useQuery<ListUserFollowsDatasQuery, ListUserFollowsDatasQueryVariables>(
    ListUserFollowsDatasDocument,
    baseOptions
  );
}
export function useListUserFollowsDatasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserFollowsDatasQuery,
    ListUserFollowsDatasQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListUserFollowsDatasQuery,
    ListUserFollowsDatasQueryVariables
  >(ListUserFollowsDatasDocument, baseOptions);
}
export type ListUserFollowsDatasQueryHookResult = ReturnType<
  typeof useListUserFollowsDatasQuery
>;
export type ListUserFollowsDatasLazyQueryHookResult = ReturnType<
  typeof useListUserFollowsDatasLazyQuery
>;
export type ListUserFollowsDatasQueryResult = Apollo.QueryResult<
  ListUserFollowsDatasQuery,
  ListUserFollowsDatasQueryVariables
>;
export const ListUserFollowsUsersDocument = gql`
  query ListUserFollowsUsers(
    $filter: ModelUserFollowsUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowsUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        owner
        followerID
        followedID
        createdAt
      }
      nextToken
    }
  }
`;

/**
 * __useListUserFollowsUsersQuery__
 *
 * To run a query within a React component, call `useListUserFollowsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserFollowsUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserFollowsUsersQuery,
    ListUserFollowsUsersQueryVariables
  >
) {
  return Apollo.useQuery<ListUserFollowsUsersQuery, ListUserFollowsUsersQueryVariables>(
    ListUserFollowsUsersDocument,
    baseOptions
  );
}
export function useListUserFollowsUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserFollowsUsersQuery,
    ListUserFollowsUsersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListUserFollowsUsersQuery,
    ListUserFollowsUsersQueryVariables
  >(ListUserFollowsUsersDocument, baseOptions);
}
export type ListUserFollowsUsersQueryHookResult = ReturnType<
  typeof useListUserFollowsUsersQuery
>;
export type ListUserFollowsUsersLazyQueryHookResult = ReturnType<
  typeof useListUserFollowsUsersLazyQuery
>;
export type ListUserFollowsUsersQueryResult = Apollo.QueryResult<
  ListUserFollowsUsersQuery,
  ListUserFollowsUsersQueryVariables
>;
export const GetQuestionDocument = gql`
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      owner
      text
      viewsCounter
      topicNames
      createdAt
      updatedAt
      ownerEdge {
        id
        updatedAt
        owner
        userID
        questionID
        createdAt
      }
      userFollows {
        nextToken
      }
      questionsForward {
        nextToken
      }
      questionsBackward {
        nextToken
      }
      questTrains {
        nextToken
      }
      data {
        nextToken
      }
    }
  }
`;

/**
 * __useGetQuestionQuery__
 *
 * To run a query within a React component, call `useGetQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionQuery(
  baseOptions: Apollo.QueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>
) {
  return Apollo.useQuery<GetQuestionQuery, GetQuestionQueryVariables>(
    GetQuestionDocument,
    baseOptions
  );
}
export function useGetQuestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>
) {
  return Apollo.useLazyQuery<GetQuestionQuery, GetQuestionQueryVariables>(
    GetQuestionDocument,
    baseOptions
  );
}
export type GetQuestionQueryHookResult = ReturnType<typeof useGetQuestionQuery>;
export type GetQuestionLazyQueryHookResult = ReturnType<typeof useGetQuestionLazyQuery>;
export type GetQuestionQueryResult = Apollo.QueryResult<
  GetQuestionQuery,
  GetQuestionQueryVariables
>;
export const ListQuestionsDocument = gql`
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        text
        viewsCounter
        topicNames
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListQuestionsQuery__
 *
 * To run a query within a React component, call `useListQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListQuestionsQuery, ListQuestionsQueryVariables>
) {
  return Apollo.useQuery<ListQuestionsQuery, ListQuestionsQueryVariables>(
    ListQuestionsDocument,
    baseOptions
  );
}
export function useListQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListQuestionsQuery,
    ListQuestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListQuestionsQuery, ListQuestionsQueryVariables>(
    ListQuestionsDocument,
    baseOptions
  );
}
export type ListQuestionsQueryHookResult = ReturnType<typeof useListQuestionsQuery>;
export type ListQuestionsLazyQueryHookResult = ReturnType<
  typeof useListQuestionsLazyQuery
>;
export type ListQuestionsQueryResult = Apollo.QueryResult<
  ListQuestionsQuery,
  ListQuestionsQueryVariables
>;
export const ListQuestionEdgesDocument = gql`
  query ListQuestionEdges(
    $filter: ModelQuestionEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        aID
        bID
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListQuestionEdgesQuery__
 *
 * To run a query within a React component, call `useListQuestionEdgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestionEdgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestionEdgesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListQuestionEdgesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListQuestionEdgesQuery,
    ListQuestionEdgesQueryVariables
  >
) {
  return Apollo.useQuery<ListQuestionEdgesQuery, ListQuestionEdgesQueryVariables>(
    ListQuestionEdgesDocument,
    baseOptions
  );
}
export function useListQuestionEdgesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListQuestionEdgesQuery,
    ListQuestionEdgesQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListQuestionEdgesQuery, ListQuestionEdgesQueryVariables>(
    ListQuestionEdgesDocument,
    baseOptions
  );
}
export type ListQuestionEdgesQueryHookResult = ReturnType<
  typeof useListQuestionEdgesQuery
>;
export type ListQuestionEdgesLazyQueryHookResult = ReturnType<
  typeof useListQuestionEdgesLazyQuery
>;
export type ListQuestionEdgesQueryResult = Apollo.QueryResult<
  ListQuestionEdgesQuery,
  ListQuestionEdgesQueryVariables
>;
export const ListDataDataEdgesDocument = gql`
  query ListDataDataEdges(
    $filter: ModelDataDataEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataDataEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        aID
        bID
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListDataDataEdgesQuery__
 *
 * To run a query within a React component, call `useListDataDataEdgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDataDataEdgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDataDataEdgesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListDataDataEdgesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListDataDataEdgesQuery,
    ListDataDataEdgesQueryVariables
  >
) {
  return Apollo.useQuery<ListDataDataEdgesQuery, ListDataDataEdgesQueryVariables>(
    ListDataDataEdgesDocument,
    baseOptions
  );
}
export function useListDataDataEdgesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDataDataEdgesQuery,
    ListDataDataEdgesQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListDataDataEdgesQuery, ListDataDataEdgesQueryVariables>(
    ListDataDataEdgesDocument,
    baseOptions
  );
}
export type ListDataDataEdgesQueryHookResult = ReturnType<
  typeof useListDataDataEdgesQuery
>;
export type ListDataDataEdgesLazyQueryHookResult = ReturnType<
  typeof useListDataDataEdgesLazyQuery
>;
export type ListDataDataEdgesQueryResult = Apollo.QueryResult<
  ListDataDataEdgesQuery,
  ListDataDataEdgesQueryVariables
>;
export const GetQuestTrainDocument = gql`
  query GetQuestTrain($id: ID!) {
    getQuestTrain(id: $id) {
      id
      owner
      carSpecs {
        id
        carType
      }
      viewsCounter
      createdAt
      updatedAt
      ownerEdge {
        id
        updatedAt
        owner
        userID
        questTrainID
        createdAt
      }
      questions {
        nextToken
      }
    }
  }
`;

/**
 * __useGetQuestTrainQuery__
 *
 * To run a query within a React component, call `useGetQuestTrainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestTrainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestTrainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestTrainQuery(
  baseOptions: Apollo.QueryHookOptions<GetQuestTrainQuery, GetQuestTrainQueryVariables>
) {
  return Apollo.useQuery<GetQuestTrainQuery, GetQuestTrainQueryVariables>(
    GetQuestTrainDocument,
    baseOptions
  );
}
export function useGetQuestTrainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestTrainQuery,
    GetQuestTrainQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetQuestTrainQuery, GetQuestTrainQueryVariables>(
    GetQuestTrainDocument,
    baseOptions
  );
}
export type GetQuestTrainQueryHookResult = ReturnType<typeof useGetQuestTrainQuery>;
export type GetQuestTrainLazyQueryHookResult = ReturnType<
  typeof useGetQuestTrainLazyQuery
>;
export type GetQuestTrainQueryResult = Apollo.QueryResult<
  GetQuestTrainQuery,
  GetQuestTrainQueryVariables
>;
export const ListQuestTrainsDocument = gql`
  query ListQuestTrains(
    $filter: ModelQuestTrainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestTrains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        viewsCounter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListQuestTrainsQuery__
 *
 * To run a query within a React component, call `useListQuestTrainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestTrainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestTrainsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListQuestTrainsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListQuestTrainsQuery,
    ListQuestTrainsQueryVariables
  >
) {
  return Apollo.useQuery<ListQuestTrainsQuery, ListQuestTrainsQueryVariables>(
    ListQuestTrainsDocument,
    baseOptions
  );
}
export function useListQuestTrainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListQuestTrainsQuery,
    ListQuestTrainsQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListQuestTrainsQuery, ListQuestTrainsQueryVariables>(
    ListQuestTrainsDocument,
    baseOptions
  );
}
export type ListQuestTrainsQueryHookResult = ReturnType<typeof useListQuestTrainsQuery>;
export type ListQuestTrainsLazyQueryHookResult = ReturnType<
  typeof useListQuestTrainsLazyQuery
>;
export type ListQuestTrainsQueryResult = Apollo.QueryResult<
  ListQuestTrainsQuery,
  ListQuestTrainsQueryVariables
>;
export const GetDataDocument = gql`
  query GetData($id: ID!) {
    getData(id: $id) {
      id
      owner
      name
      header {
        name
        columnType
        required
        exampleValue
      }
      snippet
      numRows
      fileSizeBytes
      references {
        name
        link
      }
      notes
      s3Bucket
      s3Key
      s3Prefix
      s3Basename
      viewsCounter
      isRequest
      topicNames
      createdAt
      updatedAt
      userFollows {
        nextToken
      }
      datasForward {
        nextToken
      }
      datasBackward {
        nextToken
      }
      ownerEdge {
        id
        updatedAt
        owner
        userID
        dataID
        createdAt
      }
      questions {
        nextToken
      }
    }
  }
`;

/**
 * __useGetDataQuery__
 *
 * To run a query within a React component, call `useGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetDataQuery, GetDataQueryVariables>
) {
  return Apollo.useQuery<GetDataQuery, GetDataQueryVariables>(
    GetDataDocument,
    baseOptions
  );
}
export function useGetDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDataQuery, GetDataQueryVariables>
) {
  return Apollo.useLazyQuery<GetDataQuery, GetDataQueryVariables>(
    GetDataDocument,
    baseOptions
  );
}
export type GetDataQueryHookResult = ReturnType<typeof useGetDataQuery>;
export type GetDataLazyQueryHookResult = ReturnType<typeof useGetDataLazyQuery>;
export type GetDataQueryResult = Apollo.QueryResult<
  GetDataQuery,
  GetDataQueryVariables
>;
export const ListDatasDocument = gql`
  query ListDatas($filter: ModelDataFilterInput, $limit: Int, $nextToken: String) {
    listDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        snippet
        numRows
        fileSizeBytes
        notes
        s3Bucket
        s3Key
        s3Prefix
        s3Basename
        viewsCounter
        isRequest
        topicNames
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListDatasQuery__
 *
 * To run a query within a React component, call `useListDatasQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDatasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDatasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListDatasQuery(
  baseOptions?: Apollo.QueryHookOptions<ListDatasQuery, ListDatasQueryVariables>
) {
  return Apollo.useQuery<ListDatasQuery, ListDatasQueryVariables>(
    ListDatasDocument,
    baseOptions
  );
}
export function useListDatasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListDatasQuery, ListDatasQueryVariables>
) {
  return Apollo.useLazyQuery<ListDatasQuery, ListDatasQueryVariables>(
    ListDatasDocument,
    baseOptions
  );
}
export type ListDatasQueryHookResult = ReturnType<typeof useListDatasQuery>;
export type ListDatasLazyQueryHookResult = ReturnType<typeof useListDatasLazyQuery>;
export type ListDatasQueryResult = Apollo.QueryResult<
  ListDatasQuery,
  ListDatasQueryVariables
>;
export const ListQuestionDataEdgesDocument = gql`
  query ListQuestionDataEdges(
    $filter: ModelQuestionDataEdgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionDataEdges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        questionID
        dataID
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListQuestionDataEdgesQuery__
 *
 * To run a query within a React component, call `useListQuestionDataEdgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestionDataEdgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestionDataEdgesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListQuestionDataEdgesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListQuestionDataEdgesQuery,
    ListQuestionDataEdgesQueryVariables
  >
) {
  return Apollo.useQuery<
    ListQuestionDataEdgesQuery,
    ListQuestionDataEdgesQueryVariables
  >(ListQuestionDataEdgesDocument, baseOptions);
}
export function useListQuestionDataEdgesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListQuestionDataEdgesQuery,
    ListQuestionDataEdgesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListQuestionDataEdgesQuery,
    ListQuestionDataEdgesQueryVariables
  >(ListQuestionDataEdgesDocument, baseOptions);
}
export type ListQuestionDataEdgesQueryHookResult = ReturnType<
  typeof useListQuestionDataEdgesQuery
>;
export type ListQuestionDataEdgesLazyQueryHookResult = ReturnType<
  typeof useListQuestionDataEdgesLazyQuery
>;
export type ListQuestionDataEdgesQueryResult = Apollo.QueryResult<
  ListQuestionDataEdgesQuery,
  ListQuestionDataEdgesQueryVariables
>;
export const GetTopicDocument = gql`
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      name
      description
      followerCount
      entityCount
      sortable
      createdAt
      updatedAt
      userFollows {
        nextToken
      }
    }
  }
`;

/**
 * __useGetTopicQuery__
 *
 * To run a query within a React component, call `useGetTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTopicQuery(
  baseOptions: Apollo.QueryHookOptions<GetTopicQuery, GetTopicQueryVariables>
) {
  return Apollo.useQuery<GetTopicQuery, GetTopicQueryVariables>(
    GetTopicDocument,
    baseOptions
  );
}
export function useGetTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTopicQuery, GetTopicQueryVariables>
) {
  return Apollo.useLazyQuery<GetTopicQuery, GetTopicQueryVariables>(
    GetTopicDocument,
    baseOptions
  );
}
export type GetTopicQueryHookResult = ReturnType<typeof useGetTopicQuery>;
export type GetTopicLazyQueryHookResult = ReturnType<typeof useGetTopicLazyQuery>;
export type GetTopicQueryResult = Apollo.QueryResult<
  GetTopicQuery,
  GetTopicQueryVariables
>;
export const ListTopicsDocument = gql`
  query ListTopics($filter: ModelTopicFilterInput, $limit: Int, $nextToken: String) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        followerCount
        entityCount
        sortable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListTopicsQuery__
 *
 * To run a query within a React component, call `useListTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTopicsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTopicsQuery, ListTopicsQueryVariables>
) {
  return Apollo.useQuery<ListTopicsQuery, ListTopicsQueryVariables>(
    ListTopicsDocument,
    baseOptions
  );
}
export function useListTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTopicsQuery, ListTopicsQueryVariables>
) {
  return Apollo.useLazyQuery<ListTopicsQuery, ListTopicsQueryVariables>(
    ListTopicsDocument,
    baseOptions
  );
}
export type ListTopicsQueryHookResult = ReturnType<typeof useListTopicsQuery>;
export type ListTopicsLazyQueryHookResult = ReturnType<typeof useListTopicsLazyQuery>;
export type ListTopicsQueryResult = Apollo.QueryResult<
  ListTopicsQuery,
  ListTopicsQueryVariables
>;
export const ListTopicsByFollowerCountDocument = gql`
  query ListTopicsByFollowerCount(
    $sortable: IsSortable
    $followerCount: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopicsByFollowerCount(
      sortable: $sortable
      followerCount: $followerCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        followerCount
        entityCount
        sortable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListTopicsByFollowerCountQuery__
 *
 * To run a query within a React component, call `useListTopicsByFollowerCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTopicsByFollowerCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTopicsByFollowerCountQuery({
 *   variables: {
 *      sortable: // value for 'sortable'
 *      followerCount: // value for 'followerCount'
 *      sortDirection: // value for 'sortDirection'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListTopicsByFollowerCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTopicsByFollowerCountQuery,
    ListTopicsByFollowerCountQueryVariables
  >
) {
  return Apollo.useQuery<
    ListTopicsByFollowerCountQuery,
    ListTopicsByFollowerCountQueryVariables
  >(ListTopicsByFollowerCountDocument, baseOptions);
}
export function useListTopicsByFollowerCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTopicsByFollowerCountQuery,
    ListTopicsByFollowerCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListTopicsByFollowerCountQuery,
    ListTopicsByFollowerCountQueryVariables
  >(ListTopicsByFollowerCountDocument, baseOptions);
}
export type ListTopicsByFollowerCountQueryHookResult = ReturnType<
  typeof useListTopicsByFollowerCountQuery
>;
export type ListTopicsByFollowerCountLazyQueryHookResult = ReturnType<
  typeof useListTopicsByFollowerCountLazyQuery
>;
export type ListTopicsByFollowerCountQueryResult = Apollo.QueryResult<
  ListTopicsByFollowerCountQuery,
  ListTopicsByFollowerCountQueryVariables
>;
export const ListTopicsByEntityCountDocument = gql`
  query ListTopicsByEntityCount(
    $sortable: IsSortable
    $entityCount: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopicsByEntityCount(
      sortable: $sortable
      entityCount: $entityCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        followerCount
        entityCount
        sortable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

/**
 * __useListTopicsByEntityCountQuery__
 *
 * To run a query within a React component, call `useListTopicsByEntityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTopicsByEntityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTopicsByEntityCountQuery({
 *   variables: {
 *      sortable: // value for 'sortable'
 *      entityCount: // value for 'entityCount'
 *      sortDirection: // value for 'sortDirection'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListTopicsByEntityCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTopicsByEntityCountQuery,
    ListTopicsByEntityCountQueryVariables
  >
) {
  return Apollo.useQuery<
    ListTopicsByEntityCountQuery,
    ListTopicsByEntityCountQueryVariables
  >(ListTopicsByEntityCountDocument, baseOptions);
}
export function useListTopicsByEntityCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTopicsByEntityCountQuery,
    ListTopicsByEntityCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListTopicsByEntityCountQuery,
    ListTopicsByEntityCountQueryVariables
  >(ListTopicsByEntityCountDocument, baseOptions);
}
export type ListTopicsByEntityCountQueryHookResult = ReturnType<
  typeof useListTopicsByEntityCountQuery
>;
export type ListTopicsByEntityCountLazyQueryHookResult = ReturnType<
  typeof useListTopicsByEntityCountLazyQuery
>;
export type ListTopicsByEntityCountQueryResult = Apollo.QueryResult<
  ListTopicsByEntityCountQuery,
  ListTopicsByEntityCountQueryVariables
>;
export const ListUserFollowsTopicsDocument = gql`
  query ListUserFollowsTopics(
    $filter: ModelUserFollowsTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowsTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        owner
        userID
        topicID
        createdAt
      }
      nextToken
    }
  }
`;

/**
 * __useListUserFollowsTopicsQuery__
 *
 * To run a query within a React component, call `useListUserFollowsTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsTopicsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserFollowsTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserFollowsTopicsQuery,
    ListUserFollowsTopicsQueryVariables
  >
) {
  return Apollo.useQuery<
    ListUserFollowsTopicsQuery,
    ListUserFollowsTopicsQueryVariables
  >(ListUserFollowsTopicsDocument, baseOptions);
}
export function useListUserFollowsTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserFollowsTopicsQuery,
    ListUserFollowsTopicsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListUserFollowsTopicsQuery,
    ListUserFollowsTopicsQueryVariables
  >(ListUserFollowsTopicsDocument, baseOptions);
}
export type ListUserFollowsTopicsQueryHookResult = ReturnType<
  typeof useListUserFollowsTopicsQuery
>;
export type ListUserFollowsTopicsLazyQueryHookResult = ReturnType<
  typeof useListUserFollowsTopicsLazyQuery
>;
export type ListUserFollowsTopicsQueryResult = Apollo.QueryResult<
  ListUserFollowsTopicsQuery,
  ListUserFollowsTopicsQueryVariables
>;
export const GetQuestionBasicsDocument = gql`
  query GetQuestionBasics($id: ID!) {
    getQuestion(id: $id) {
      ...QuestionBasics
    }
  }
  ${QuestionBasicsFragmentDoc}
`;

/**
 * __useGetQuestionBasicsQuery__
 *
 * To run a query within a React component, call `useGetQuestionBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionBasicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionBasicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionBasicsQuery,
    GetQuestionBasicsQueryVariables
  >
) {
  return Apollo.useQuery<GetQuestionBasicsQuery, GetQuestionBasicsQueryVariables>(
    GetQuestionBasicsDocument,
    baseOptions
  );
}
export function useGetQuestionBasicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionBasicsQuery,
    GetQuestionBasicsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetQuestionBasicsQuery, GetQuestionBasicsQueryVariables>(
    GetQuestionBasicsDocument,
    baseOptions
  );
}
export type GetQuestionBasicsQueryHookResult = ReturnType<
  typeof useGetQuestionBasicsQuery
>;
export type GetQuestionBasicsLazyQueryHookResult = ReturnType<
  typeof useGetQuestionBasicsLazyQuery
>;
export type GetQuestionBasicsQueryResult = Apollo.QueryResult<
  GetQuestionBasicsQuery,
  GetQuestionBasicsQueryVariables
>;
export const UpdateQuestionInternalDocument = gql`
  mutation UpdateQuestionInternal($id: ID!, $text: String!, $topicNames: [String!]!) {
    updateQuestion(input: { id: $id, text: $text, topicNames: $topicNames }) {
      ...QuestionBasics
    }
  }
  ${QuestionBasicsFragmentDoc}
`;
export type UpdateQuestionInternalMutationFn = Apollo.MutationFunction<
  UpdateQuestionInternalMutation,
  UpdateQuestionInternalMutationVariables
>;

/**
 * __useUpdateQuestionInternalMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionInternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionInternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionInternalMutation, { data, loading, error }] = useUpdateQuestionInternalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      topicNames: // value for 'topicNames'
 *   },
 * });
 */
export function useUpdateQuestionInternalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuestionInternalMutation,
    UpdateQuestionInternalMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateQuestionInternalMutation,
    UpdateQuestionInternalMutationVariables
  >(UpdateQuestionInternalDocument, baseOptions);
}
export type UpdateQuestionInternalMutationHookResult = ReturnType<
  typeof useUpdateQuestionInternalMutation
>;
export type UpdateQuestionInternalMutationResult = Apollo.MutationResult<UpdateQuestionInternalMutation>;
export type UpdateQuestionInternalMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuestionInternalMutation,
  UpdateQuestionInternalMutationVariables
>;
export const UpdateDataMetadataInternalDocument = gql`
  mutation UpdateDataMetadataInternal(
    $id: ID!
    $name: String!
    $topicNames: [String!]!
    $notes: String
    $references: [ReferenceInput!]
  ) {
    updateData(
      input: {
        id: $id
        name: $name
        topicNames: $topicNames
        notes: $notes
        references: $references
      }
    ) {
      ...DataCardBasics
      s3Bucket
      s3Key
      viewsCounter
    }
  }
  ${DataCardBasicsFragmentDoc}
`;
export type UpdateDataMetadataInternalMutationFn = Apollo.MutationFunction<
  UpdateDataMetadataInternalMutation,
  UpdateDataMetadataInternalMutationVariables
>;

/**
 * __useUpdateDataMetadataInternalMutation__
 *
 * To run a mutation, you first call `useUpdateDataMetadataInternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataMetadataInternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataMetadataInternalMutation, { data, loading, error }] = useUpdateDataMetadataInternalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      topicNames: // value for 'topicNames'
 *      notes: // value for 'notes'
 *      references: // value for 'references'
 *   },
 * });
 */
export function useUpdateDataMetadataInternalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDataMetadataInternalMutation,
    UpdateDataMetadataInternalMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateDataMetadataInternalMutation,
    UpdateDataMetadataInternalMutationVariables
  >(UpdateDataMetadataInternalDocument, baseOptions);
}
export type UpdateDataMetadataInternalMutationHookResult = ReturnType<
  typeof useUpdateDataMetadataInternalMutation
>;
export type UpdateDataMetadataInternalMutationResult = Apollo.MutationResult<UpdateDataMetadataInternalMutation>;
export type UpdateDataMetadataInternalMutationOptions = Apollo.BaseMutationOptions<
  UpdateDataMetadataInternalMutation,
  UpdateDataMetadataInternalMutationVariables
>;
export const ListQuestionsBasicsDocument = gql`
  query ListQuestionsBasics($limit: Int!) {
    listQuestions(limit: $limit) {
      items {
        ...QuestionBasics
      }
      nextToken
    }
  }
  ${QuestionBasicsFragmentDoc}
`;

/**
 * __useListQuestionsBasicsQuery__
 *
 * To run a query within a React component, call `useListQuestionsBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestionsBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestionsBasicsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListQuestionsBasicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListQuestionsBasicsQuery,
    ListQuestionsBasicsQueryVariables
  >
) {
  return Apollo.useQuery<ListQuestionsBasicsQuery, ListQuestionsBasicsQueryVariables>(
    ListQuestionsBasicsDocument,
    baseOptions
  );
}
export function useListQuestionsBasicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListQuestionsBasicsQuery,
    ListQuestionsBasicsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListQuestionsBasicsQuery,
    ListQuestionsBasicsQueryVariables
  >(ListQuestionsBasicsDocument, baseOptions);
}
export type ListQuestionsBasicsQueryHookResult = ReturnType<
  typeof useListQuestionsBasicsQuery
>;
export type ListQuestionsBasicsLazyQueryHookResult = ReturnType<
  typeof useListQuestionsBasicsLazyQuery
>;
export type ListQuestionsBasicsQueryResult = Apollo.QueryResult<
  ListQuestionsBasicsQuery,
  ListQuestionsBasicsQueryVariables
>;
export const ListDatasBasicsDocument = gql`
  query ListDatasBasics($limit: Int!) {
    listDatas(limit: $limit) {
      items {
        ...DataCardBasics
      }
      nextToken
    }
  }
  ${DataCardBasicsFragmentDoc}
`;

/**
 * __useListDatasBasicsQuery__
 *
 * To run a query within a React component, call `useListDatasBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDatasBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDatasBasicsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListDatasBasicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListDatasBasicsQuery,
    ListDatasBasicsQueryVariables
  >
) {
  return Apollo.useQuery<ListDatasBasicsQuery, ListDatasBasicsQueryVariables>(
    ListDatasBasicsDocument,
    baseOptions
  );
}
export function useListDatasBasicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDatasBasicsQuery,
    ListDatasBasicsQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListDatasBasicsQuery, ListDatasBasicsQueryVariables>(
    ListDatasBasicsDocument,
    baseOptions
  );
}
export type ListDatasBasicsQueryHookResult = ReturnType<typeof useListDatasBasicsQuery>;
export type ListDatasBasicsLazyQueryHookResult = ReturnType<
  typeof useListDatasBasicsLazyQuery
>;
export type ListDatasBasicsQueryResult = Apollo.QueryResult<
  ListDatasBasicsQuery,
  ListDatasBasicsQueryVariables
>;
export const GetQuestionManagedWrapperDocument = gql`
  query GetQuestionManagedWrapper($id: ID!) {
    getQuestionManaged(id: $id) {
      id
      owner
      ownerUsername
      text
      updatedAt
      neighborQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      questTrains {
        trainCars {
          id
          carType
          question {
            id
            ownerUsername
            text
            updatedAt
          }
          data {
            id
            owner
            ownerUsername
            updatedAt
            isRequest
            name
            header {
              exampleValue
              name
              columnType
              required
            }
          }
        }
      }
      amIFollower
      topicNames
    }
  }
`;

/**
 * __useGetQuestionManagedWrapperQuery__
 *
 * To run a query within a React component, call `useGetQuestionManagedWrapperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionManagedWrapperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionManagedWrapperQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionManagedWrapperQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionManagedWrapperQuery,
    GetQuestionManagedWrapperQueryVariables
  >
) {
  return Apollo.useQuery<
    GetQuestionManagedWrapperQuery,
    GetQuestionManagedWrapperQueryVariables
  >(GetQuestionManagedWrapperDocument, baseOptions);
}
export function useGetQuestionManagedWrapperLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionManagedWrapperQuery,
    GetQuestionManagedWrapperQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetQuestionManagedWrapperQuery,
    GetQuestionManagedWrapperQueryVariables
  >(GetQuestionManagedWrapperDocument, baseOptions);
}
export type GetQuestionManagedWrapperQueryHookResult = ReturnType<
  typeof useGetQuestionManagedWrapperQuery
>;
export type GetQuestionManagedWrapperLazyQueryHookResult = ReturnType<
  typeof useGetQuestionManagedWrapperLazyQuery
>;
export type GetQuestionManagedWrapperQueryResult = Apollo.QueryResult<
  GetQuestionManagedWrapperQuery,
  GetQuestionManagedWrapperQueryVariables
>;
export const GetQuestionInternalDocument = gql`
  query GetQuestionInternal(
    $id: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    getQuestion(id: $id) {
      data(sortDirection: $sortDirection) {
        items {
          id
          score
          data {
            ...DataCardBasics
          }
        }
        nextToken
      }
      userFollows(userID: $userID) {
        items {
          id
          userID
        }
      }
      ...QuestionBasics
      questionsBackward(sortDirection: $sortDirection) {
        items {
          id
          score
          questionA {
            questionsBackward(sortDirection: $sortDirection) {
              items {
                id
                score
                questionA {
                  data {
                    items {
                      id
                      data {
                        ...DataCardBasics
                      }
                    }
                    nextToken
                  }
                  ...QuestionBasics
                }
              }
              nextToken
            }
            questionsForward(sortDirection: $sortDirection) {
              items {
                id
                score
                questionB {
                  data {
                    items {
                      id
                      data {
                        ...DataCardBasics
                      }
                    }
                    nextToken
                  }
                  ...QuestionBasics
                }
              }
              nextToken
            }
            data(sortDirection: $sortDirection) {
              items {
                id
                score
                data {
                  ...DataCardBasics
                }
              }
              nextToken
            }
            ...QuestionBasics
          }
        }
        nextToken
      }
      questionsForward(sortDirection: $sortDirection) {
        items {
          id
          score
          questionB {
            questionsBackward(sortDirection: $sortDirection) {
              items {
                id
                score
                questionA {
                  data {
                    items {
                      id
                      data {
                        ...DataCardBasics
                      }
                    }
                    nextToken
                  }
                  ...QuestionBasics
                }
              }
              nextToken
            }
            questionsForward(sortDirection: $sortDirection) {
              items {
                id
                score
                questionB {
                  data {
                    items {
                      id
                      data {
                        ...DataCardBasics
                      }
                    }
                    nextToken
                  }
                  ...QuestionBasics
                }
              }
              nextToken
            }
            data(sortDirection: $sortDirection) {
              items {
                id
                score
                data {
                  ...DataCardBasics
                }
              }
              nextToken
            }
            ...QuestionBasics
          }
        }
        nextToken
      }
    }
  }
  ${DataCardBasicsFragmentDoc}
  ${QuestionBasicsFragmentDoc}
`;

/**
 * __useGetQuestionInternalQuery__
 *
 * To run a query within a React component, call `useGetQuestionInternalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionInternalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionInternalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetQuestionInternalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionInternalQuery,
    GetQuestionInternalQueryVariables
  >
) {
  return Apollo.useQuery<GetQuestionInternalQuery, GetQuestionInternalQueryVariables>(
    GetQuestionInternalDocument,
    baseOptions
  );
}
export function useGetQuestionInternalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionInternalQuery,
    GetQuestionInternalQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetQuestionInternalQuery,
    GetQuestionInternalQueryVariables
  >(GetQuestionInternalDocument, baseOptions);
}
export type GetQuestionInternalQueryHookResult = ReturnType<
  typeof useGetQuestionInternalQuery
>;
export type GetQuestionInternalLazyQueryHookResult = ReturnType<
  typeof useGetQuestionInternalLazyQuery
>;
export type GetQuestionInternalQueryResult = Apollo.QueryResult<
  GetQuestionInternalQuery,
  GetQuestionInternalQueryVariables
>;
export const ListQuestionsManagedDocument = gql`
  query ListQuestionsManaged(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...QuestionBasics
      }
      nextToken
    }
  }
  ${QuestionBasicsFragmentDoc}
`;

/**
 * __useListQuestionsManagedQuery__
 *
 * To run a query within a React component, call `useListQuestionsManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestionsManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestionsManagedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListQuestionsManagedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListQuestionsManagedQuery,
    ListQuestionsManagedQueryVariables
  >
) {
  return Apollo.useQuery<ListQuestionsManagedQuery, ListQuestionsManagedQueryVariables>(
    ListQuestionsManagedDocument,
    baseOptions
  );
}
export function useListQuestionsManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListQuestionsManagedQuery,
    ListQuestionsManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListQuestionsManagedQuery,
    ListQuestionsManagedQueryVariables
  >(ListQuestionsManagedDocument, baseOptions);
}
export type ListQuestionsManagedQueryHookResult = ReturnType<
  typeof useListQuestionsManagedQuery
>;
export type ListQuestionsManagedLazyQueryHookResult = ReturnType<
  typeof useListQuestionsManagedLazyQuery
>;
export type ListQuestionsManagedQueryResult = Apollo.QueryResult<
  ListQuestionsManagedQuery,
  ListQuestionsManagedQueryVariables
>;
export const ListDatasManagedDocument = gql`
  query ListDatasManaged(
    $filter: ModelDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ...DataCardBasics
      }
      nextToken
    }
  }
  ${DataCardBasicsFragmentDoc}
`;

/**
 * __useListDatasManagedQuery__
 *
 * To run a query within a React component, call `useListDatasManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDatasManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDatasManagedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListDatasManagedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListDatasManagedQuery,
    ListDatasManagedQueryVariables
  >
) {
  return Apollo.useQuery<ListDatasManagedQuery, ListDatasManagedQueryVariables>(
    ListDatasManagedDocument,
    baseOptions
  );
}
export function useListDatasManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDatasManagedQuery,
    ListDatasManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListDatasManagedQuery, ListDatasManagedQueryVariables>(
    ListDatasManagedDocument,
    baseOptions
  );
}
export type ListDatasManagedQueryHookResult = ReturnType<
  typeof useListDatasManagedQuery
>;
export type ListDatasManagedLazyQueryHookResult = ReturnType<
  typeof useListDatasManagedLazyQuery
>;
export type ListDatasManagedQueryResult = Apollo.QueryResult<
  ListDatasManagedQuery,
  ListDatasManagedQueryVariables
>;
export const GetQuestTrainManagedDocument = gql`
  query GetQuestTrainManaged($id: ID!) {
    getQuestTrain(id: $id) {
      id
      ownerEdge {
        id
        user {
          id
          username
        }
      }
      createdAt
      updatedAt
      carSpecs {
        id
        carType
      }
      questions {
        items {
          id
          questionID
          createdAt
          updatedAt
          questTrainID
          questTrain {
            id
            createdAt
            updatedAt
            ownerEdge {
              id
              user {
                id
                username
              }
            }
          }
          question {
            ...QuestionBasics
          }
        }
        nextToken
      }
    }
  }
  ${QuestionBasicsFragmentDoc}
`;

/**
 * __useGetQuestTrainManagedQuery__
 *
 * To run a query within a React component, call `useGetQuestTrainManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestTrainManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestTrainManagedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestTrainManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestTrainManagedQuery,
    GetQuestTrainManagedQueryVariables
  >
) {
  return Apollo.useQuery<GetQuestTrainManagedQuery, GetQuestTrainManagedQueryVariables>(
    GetQuestTrainManagedDocument,
    baseOptions
  );
}
export function useGetQuestTrainManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestTrainManagedQuery,
    GetQuestTrainManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetQuestTrainManagedQuery,
    GetQuestTrainManagedQueryVariables
  >(GetQuestTrainManagedDocument, baseOptions);
}
export type GetQuestTrainManagedQueryHookResult = ReturnType<
  typeof useGetQuestTrainManagedQuery
>;
export type GetQuestTrainManagedLazyQueryHookResult = ReturnType<
  typeof useGetQuestTrainManagedLazyQuery
>;
export type GetQuestTrainManagedQueryResult = Apollo.QueryResult<
  GetQuestTrainManagedQuery,
  GetQuestTrainManagedQueryVariables
>;
export const ListQuestTrainsManagedDocument = gql`
  query ListQuestTrainsManaged(
    $filter: ModelQuestTrainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestTrains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerEdge {
          id
          user {
            id
            username
          }
        }
        carSpecs {
          carType
          id
        }
        questions {
          items {
            id
            questionID
            question {
              ...QuestionBasics
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
  ${QuestionBasicsFragmentDoc}
`;

/**
 * __useListQuestTrainsManagedQuery__
 *
 * To run a query within a React component, call `useListQuestTrainsManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestTrainsManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestTrainsManagedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListQuestTrainsManagedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListQuestTrainsManagedQuery,
    ListQuestTrainsManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    ListQuestTrainsManagedQuery,
    ListQuestTrainsManagedQueryVariables
  >(ListQuestTrainsManagedDocument, baseOptions);
}
export function useListQuestTrainsManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListQuestTrainsManagedQuery,
    ListQuestTrainsManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListQuestTrainsManagedQuery,
    ListQuestTrainsManagedQueryVariables
  >(ListQuestTrainsManagedDocument, baseOptions);
}
export type ListQuestTrainsManagedQueryHookResult = ReturnType<
  typeof useListQuestTrainsManagedQuery
>;
export type ListQuestTrainsManagedLazyQueryHookResult = ReturnType<
  typeof useListQuestTrainsManagedLazyQuery
>;
export type ListQuestTrainsManagedQueryResult = Apollo.QueryResult<
  ListQuestTrainsManagedQuery,
  ListQuestTrainsManagedQueryVariables
>;
export const SearchVectorIndexManagedDocument = gql`
  query SearchVectorIndexManaged(
    $query: String!
    $limit: Int!
    $scoreThreshold: Float!
    $filter: SearchFilterInput
    $from: Int
  ) {
    searchVectorIndex(
      query: $query
      limit: $limit
      scoreThreshold: $scoreThreshold
      filter: $filter
      from: $from
    ) {
      from
      results {
        carType
        data {
          id
          owner
          ownerUsername
          updatedAt
          name
          header {
            exampleValue
            name
            columnType
            required
          }
          isRequest
        }
        question {
          id
          ownerUsername
          text
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useSearchVectorIndexManagedQuery__
 *
 * To run a query within a React component, call `useSearchVectorIndexManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVectorIndexManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVectorIndexManagedQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      scoreThreshold: // value for 'scoreThreshold'
 *      filter: // value for 'filter'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSearchVectorIndexManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchVectorIndexManagedQuery,
    SearchVectorIndexManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchVectorIndexManagedQuery,
    SearchVectorIndexManagedQueryVariables
  >(SearchVectorIndexManagedDocument, baseOptions);
}
export function useSearchVectorIndexManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchVectorIndexManagedQuery,
    SearchVectorIndexManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchVectorIndexManagedQuery,
    SearchVectorIndexManagedQueryVariables
  >(SearchVectorIndexManagedDocument, baseOptions);
}
export type SearchVectorIndexManagedQueryHookResult = ReturnType<
  typeof useSearchVectorIndexManagedQuery
>;
export type SearchVectorIndexManagedLazyQueryHookResult = ReturnType<
  typeof useSearchVectorIndexManagedLazyQuery
>;
export type SearchVectorIndexManagedQueryResult = Apollo.QueryResult<
  SearchVectorIndexManagedQuery,
  SearchVectorIndexManagedQueryVariables
>;
export const SearchTraditionalIndexManagedDocument = gql`
  query SearchTraditionalIndexManaged(
    $query: String!
    $limit: Int!
    $filter: SearchFilterInput
    $from: Int
  ) {
    searchTraditionalIndex(query: $query, limit: $limit, filter: $filter, from: $from) {
      from
      results {
        carType
        data {
          id
          owner
          ownerUsername
          updatedAt
          name
          header {
            exampleValue
            name
            columnType
            required
          }
          isRequest
        }
        question {
          id
          ownerUsername
          text
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useSearchTraditionalIndexManagedQuery__
 *
 * To run a query within a React component, call `useSearchTraditionalIndexManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTraditionalIndexManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTraditionalIndexManagedQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSearchTraditionalIndexManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTraditionalIndexManagedQuery,
    SearchTraditionalIndexManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchTraditionalIndexManagedQuery,
    SearchTraditionalIndexManagedQueryVariables
  >(SearchTraditionalIndexManagedDocument, baseOptions);
}
export function useSearchTraditionalIndexManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTraditionalIndexManagedQuery,
    SearchTraditionalIndexManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchTraditionalIndexManagedQuery,
    SearchTraditionalIndexManagedQueryVariables
  >(SearchTraditionalIndexManagedDocument, baseOptions);
}
export type SearchTraditionalIndexManagedQueryHookResult = ReturnType<
  typeof useSearchTraditionalIndexManagedQuery
>;
export type SearchTraditionalIndexManagedLazyQueryHookResult = ReturnType<
  typeof useSearchTraditionalIndexManagedLazyQuery
>;
export type SearchTraditionalIndexManagedQueryResult = Apollo.QueryResult<
  SearchTraditionalIndexManagedQuery,
  SearchTraditionalIndexManagedQueryVariables
>;
export const RandomSampleManagedDocument = gql`
  query RandomSampleManaged($limit: Int!, $filter: SearchFilterInput, $from: Int) {
    randomSample(limit: $limit, filter: $filter, from: $from) {
      from
      results {
        carType
        data {
          id
          owner
          ownerUsername
          name
          updatedAt
          header {
            exampleValue
            name
            columnType
            required
          }
          isRequest
        }
        question {
          id
          ownerUsername
          text
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useRandomSampleManagedQuery__
 *
 * To run a query within a React component, call `useRandomSampleManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomSampleManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomSampleManagedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useRandomSampleManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    RandomSampleManagedQuery,
    RandomSampleManagedQueryVariables
  >
) {
  return Apollo.useQuery<RandomSampleManagedQuery, RandomSampleManagedQueryVariables>(
    RandomSampleManagedDocument,
    baseOptions
  );
}
export function useRandomSampleManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RandomSampleManagedQuery,
    RandomSampleManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RandomSampleManagedQuery,
    RandomSampleManagedQueryVariables
  >(RandomSampleManagedDocument, baseOptions);
}
export type RandomSampleManagedQueryHookResult = ReturnType<
  typeof useRandomSampleManagedQuery
>;
export type RandomSampleManagedLazyQueryHookResult = ReturnType<
  typeof useRandomSampleManagedLazyQuery
>;
export type RandomSampleManagedQueryResult = Apollo.QueryResult<
  RandomSampleManagedQuery,
  RandomSampleManagedQueryVariables
>;
export const CreateUserOwnsQuestionManagedDocument = gql`
  mutation CreateUserOwnsQuestionManaged($input: CreateUserOwnsQuestionInput!) {
    createUserOwnsQuestion(input: $input) {
      id
      owner
      questionID
      userID
    }
  }
`;
export type CreateUserOwnsQuestionManagedMutationFn = Apollo.MutationFunction<
  CreateUserOwnsQuestionManagedMutation,
  CreateUserOwnsQuestionManagedMutationVariables
>;

/**
 * __useCreateUserOwnsQuestionManagedMutation__
 *
 * To run a mutation, you first call `useCreateUserOwnsQuestionManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOwnsQuestionManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOwnsQuestionManagedMutation, { data, loading, error }] = useCreateUserOwnsQuestionManagedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserOwnsQuestionManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserOwnsQuestionManagedMutation,
    CreateUserOwnsQuestionManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserOwnsQuestionManagedMutation,
    CreateUserOwnsQuestionManagedMutationVariables
  >(CreateUserOwnsQuestionManagedDocument, baseOptions);
}
export type CreateUserOwnsQuestionManagedMutationHookResult = ReturnType<
  typeof useCreateUserOwnsQuestionManagedMutation
>;
export type CreateUserOwnsQuestionManagedMutationResult = Apollo.MutationResult<CreateUserOwnsQuestionManagedMutation>;
export type CreateUserOwnsQuestionManagedMutationOptions = Apollo.BaseMutationOptions<
  CreateUserOwnsQuestionManagedMutation,
  CreateUserOwnsQuestionManagedMutationVariables
>;
export const CreateUserOwnsQuestTrainManagedDocument = gql`
  mutation CreateUserOwnsQuestTrainManaged($input: CreateUserOwnsQuestTrainInput!) {
    createUserOwnsQuestTrain(input: $input) {
      id
      owner
      questTrainID
      userID
    }
  }
`;
export type CreateUserOwnsQuestTrainManagedMutationFn = Apollo.MutationFunction<
  CreateUserOwnsQuestTrainManagedMutation,
  CreateUserOwnsQuestTrainManagedMutationVariables
>;

/**
 * __useCreateUserOwnsQuestTrainManagedMutation__
 *
 * To run a mutation, you first call `useCreateUserOwnsQuestTrainManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOwnsQuestTrainManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOwnsQuestTrainManagedMutation, { data, loading, error }] = useCreateUserOwnsQuestTrainManagedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserOwnsQuestTrainManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserOwnsQuestTrainManagedMutation,
    CreateUserOwnsQuestTrainManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserOwnsQuestTrainManagedMutation,
    CreateUserOwnsQuestTrainManagedMutationVariables
  >(CreateUserOwnsQuestTrainManagedDocument, baseOptions);
}
export type CreateUserOwnsQuestTrainManagedMutationHookResult = ReturnType<
  typeof useCreateUserOwnsQuestTrainManagedMutation
>;
export type CreateUserOwnsQuestTrainManagedMutationResult = Apollo.MutationResult<CreateUserOwnsQuestTrainManagedMutation>;
export type CreateUserOwnsQuestTrainManagedMutationOptions = Apollo.BaseMutationOptions<
  CreateUserOwnsQuestTrainManagedMutation,
  CreateUserOwnsQuestTrainManagedMutationVariables
>;
export const GetUserManagedDocument = gql`
  query GetUserManaged($id: ID!, $sortDirection: ModelSortDirection) {
    getUser(id: $id) {
      id
      owner
      username
      ownedData(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          data {
            id
            owner
            ownerEdge {
              id
              user {
                id
                username
              }
            }
            updatedAt
            name
            header {
              exampleValue
              name
              columnType
              required
            }
            isRequest
          }
        }
        nextToken
      }
      ownedQuestions(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          question {
            ...QuestionBasics
          }
        }
        nextToken
      }
      followedByUsers(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          follower {
            id
            username
          }
        }
        nextToken
      }
      followingUsers(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          followed {
            id
            username
          }
        }
        nextToken
      }
    }
  }
  ${QuestionBasicsFragmentDoc}
`;

/**
 * __useGetUserManagedQuery__
 *
 * To run a query within a React component, call `useGetUserManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserManagedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetUserManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserManagedQuery,
    GetUserManagedQueryVariables
  >
) {
  return Apollo.useQuery<GetUserManagedQuery, GetUserManagedQueryVariables>(
    GetUserManagedDocument,
    baseOptions
  );
}
export function useGetUserManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserManagedQuery,
    GetUserManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUserManagedQuery, GetUserManagedQueryVariables>(
    GetUserManagedDocument,
    baseOptions
  );
}
export type GetUserManagedQueryHookResult = ReturnType<typeof useGetUserManagedQuery>;
export type GetUserManagedLazyQueryHookResult = ReturnType<
  typeof useGetUserManagedLazyQuery
>;
export type GetUserManagedQueryResult = Apollo.QueryResult<
  GetUserManagedQuery,
  GetUserManagedQueryVariables
>;
export const GetMyUserInternalDocument = gql`
  query GetMyUserInternal($id: ID!, $sortDirection: ModelSortDirection) {
    getUser(id: $id) {
      id
      owner
      username
      name
      email
      followingUsers(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          followed {
            id
            username
          }
        }
        nextToken
      }
      followedByUsers(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          follower {
            id
            username
          }
        }
        nextToken
      }
      followedDatas(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          data {
            id
            owner
            ownerEdge {
              id
              user {
                id
                username
              }
            }
            updatedAt
            name
            header {
              exampleValue
              name
              columnType
              required
            }
            isRequest
          }
        }
        nextToken
      }
      followedQuestions(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          question {
            ...QuestionBasics
          }
        }
        nextToken
      }
      ownedData(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          data {
            id
            owner
            ownerEdge {
              id
              user {
                id
                username
              }
            }
            updatedAt
            name
            header {
              exampleValue
              name
              columnType
              required
            }
            isRequest
          }
        }
        nextToken
      }
      ownedQuestions(sortDirection: $sortDirection) {
        items {
          id
          updatedAt
          question {
            ...QuestionBasics
          }
        }
        nextToken
      }
      notifications(sortDirection: $sortDirection) {
        items {
          ...NotificationBasics
        }
        nextToken
      }
      followedTopics {
        items {
          id
          updatedAt
          topic {
            id
            name
          }
        }
        nextToken
      }
    }
  }
  ${QuestionBasicsFragmentDoc}
  ${NotificationBasicsFragmentDoc}
`;

/**
 * __useGetMyUserInternalQuery__
 *
 * To run a query within a React component, call `useGetMyUserInternalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserInternalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserInternalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetMyUserInternalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyUserInternalQuery,
    GetMyUserInternalQueryVariables
  >
) {
  return Apollo.useQuery<GetMyUserInternalQuery, GetMyUserInternalQueryVariables>(
    GetMyUserInternalDocument,
    baseOptions
  );
}
export function useGetMyUserInternalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUserInternalQuery,
    GetMyUserInternalQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetMyUserInternalQuery, GetMyUserInternalQueryVariables>(
    GetMyUserInternalDocument,
    baseOptions
  );
}
export type GetMyUserInternalQueryHookResult = ReturnType<
  typeof useGetMyUserInternalQuery
>;
export type GetMyUserInternalLazyQueryHookResult = ReturnType<
  typeof useGetMyUserInternalLazyQuery
>;
export type GetMyUserInternalQueryResult = Apollo.QueryResult<
  GetMyUserInternalQuery,
  GetMyUserInternalQueryVariables
>;
export const GetPublicUserProfileManagedDocument = gql`
  query GetPublicUserProfileManaged($username: ID!) {
    getPublicUserProfile(username: $username) {
      id
      owner
      username
      ownedQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      ownedQuestTrains {
        id
        ownerUsername
        carSpecs {
          id
          carType
        }
        questions {
          id
          ownerUsername
          text
        }
      }
      ownedDatas {
        id
        owner
        ownerUsername
        name
        updatedAt
        header {
          exampleValue
          name
          columnType
          required
        }
        isRequest
      }
      followedByUsers {
        id
        updatedAt
        ownerUsername
      }
      followingUsers {
        id
        updatedAt
        ownerUsername
      }
      amIFollower
    }
  }
`;

/**
 * __useGetPublicUserProfileManagedQuery__
 *
 * To run a query within a React component, call `useGetPublicUserProfileManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicUserProfileManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicUserProfileManagedQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetPublicUserProfileManagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicUserProfileManagedQuery,
    GetPublicUserProfileManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPublicUserProfileManagedQuery,
    GetPublicUserProfileManagedQueryVariables
  >(GetPublicUserProfileManagedDocument, baseOptions);
}
export function useGetPublicUserProfileManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicUserProfileManagedQuery,
    GetPublicUserProfileManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPublicUserProfileManagedQuery,
    GetPublicUserProfileManagedQueryVariables
  >(GetPublicUserProfileManagedDocument, baseOptions);
}
export type GetPublicUserProfileManagedQueryHookResult = ReturnType<
  typeof useGetPublicUserProfileManagedQuery
>;
export type GetPublicUserProfileManagedLazyQueryHookResult = ReturnType<
  typeof useGetPublicUserProfileManagedLazyQuery
>;
export type GetPublicUserProfileManagedQueryResult = Apollo.QueryResult<
  GetPublicUserProfileManagedQuery,
  GetPublicUserProfileManagedQueryVariables
>;
export const GetMyUserProfileManagedDocument = gql`
  query GetMyUserProfileManaged {
    getMyUserProfile {
      id
      owner
      username
      email
      name
      followedQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      followedDatas {
        id
        owner
        ownerUsername
        name
        updatedAt
        header {
          exampleValue
          name
          columnType
          required
        }
        isRequest
      }
      ownedQuestions {
        id
        ownerUsername
        text
        updatedAt
      }
      ownedQuestTrains {
        id
        ownerUsername
        carSpecs {
          id
          carType
        }
        questions {
          id
          ownerUsername
          text
        }
      }
      ownedDatas {
        id
        owner
        ownerUsername
        name
        updatedAt
        header {
          exampleValue
          name
          columnType
          required
        }
        isRequest
      }
      notifications {
        id
        owner
        read
        pushNotified
        createdAt
        updatedAt
        actorData {
          id
          owner
          ownerUsername
          name
          updatedAt
          header {
            exampleValue
            name
            columnType
            required
          }
          isRequest
        }
        actorQuestion {
          id
          ownerUsername
          text
          updatedAt
        }
        actorUserID
        actorUsername
        followedData {
          id
          owner
          ownerUsername
          name
          updatedAt
          header {
            exampleValue
            name
            columnType
            required
          }
          isRequest
        }
        followedQuestion {
          id
          ownerUsername
          text
          updatedAt
        }
      }
      followingUsers {
        id
        updatedAt
        ownerUsername
      }
      followedByUsers {
        id
        updatedAt
        ownerUsername
      }
    }
  }
`;

/**
 * __useGetMyUserProfileManagedQuery__
 *
 * To run a query within a React component, call `useGetMyUserProfileManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserProfileManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserProfileManagedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserProfileManagedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyUserProfileManagedQuery,
    GetMyUserProfileManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMyUserProfileManagedQuery,
    GetMyUserProfileManagedQueryVariables
  >(GetMyUserProfileManagedDocument, baseOptions);
}
export function useGetMyUserProfileManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUserProfileManagedQuery,
    GetMyUserProfileManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMyUserProfileManagedQuery,
    GetMyUserProfileManagedQueryVariables
  >(GetMyUserProfileManagedDocument, baseOptions);
}
export type GetMyUserProfileManagedQueryHookResult = ReturnType<
  typeof useGetMyUserProfileManagedQuery
>;
export type GetMyUserProfileManagedLazyQueryHookResult = ReturnType<
  typeof useGetMyUserProfileManagedLazyQuery
>;
export type GetMyUserProfileManagedQueryResult = Apollo.QueryResult<
  GetMyUserProfileManagedQuery,
  GetMyUserProfileManagedQueryVariables
>;
export const CreateQuestionInternalDocument = gql`
  mutation CreateQuestionInternal($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      id
      owner
      ownerEdge {
        id
        user {
          id
          username
        }
      }
      text
      updatedAt
      topicNames
      viewsCounter
    }
  }
`;
export type CreateQuestionInternalMutationFn = Apollo.MutationFunction<
  CreateQuestionInternalMutation,
  CreateQuestionInternalMutationVariables
>;

/**
 * __useCreateQuestionInternalMutation__
 *
 * To run a mutation, you first call `useCreateQuestionInternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionInternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionInternalMutation, { data, loading, error }] = useCreateQuestionInternalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuestionInternalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestionInternalMutation,
    CreateQuestionInternalMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateQuestionInternalMutation,
    CreateQuestionInternalMutationVariables
  >(CreateQuestionInternalDocument, baseOptions);
}
export type CreateQuestionInternalMutationHookResult = ReturnType<
  typeof useCreateQuestionInternalMutation
>;
export type CreateQuestionInternalMutationResult = Apollo.MutationResult<CreateQuestionInternalMutation>;
export type CreateQuestionInternalMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestionInternalMutation,
  CreateQuestionInternalMutationVariables
>;
export const CreateDataInternalDocument = gql`
  mutation CreateDataInternal($input: CreateDataInput!) {
    createData(input: $input) {
      id
      owner
      ownerEdge {
        id
        user {
          id
          username
        }
      }
      name
      header {
        exampleValue
        name
        columnType
        required
      }
      snippet
      s3Bucket
      s3Key
      viewsCounter
      isRequest
    }
  }
`;
export type CreateDataInternalMutationFn = Apollo.MutationFunction<
  CreateDataInternalMutation,
  CreateDataInternalMutationVariables
>;

/**
 * __useCreateDataInternalMutation__
 *
 * To run a mutation, you first call `useCreateDataInternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataInternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataInternalMutation, { data, loading, error }] = useCreateDataInternalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataInternalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDataInternalMutation,
    CreateDataInternalMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateDataInternalMutation,
    CreateDataInternalMutationVariables
  >(CreateDataInternalDocument, baseOptions);
}
export type CreateDataInternalMutationHookResult = ReturnType<
  typeof useCreateDataInternalMutation
>;
export type CreateDataInternalMutationResult = Apollo.MutationResult<CreateDataInternalMutation>;
export type CreateDataInternalMutationOptions = Apollo.BaseMutationOptions<
  CreateDataInternalMutation,
  CreateDataInternalMutationVariables
>;
export const CreateUserOwnsDataManagedDocument = gql`
  mutation CreateUserOwnsDataManaged($input: CreateUserOwnsDataInput!) {
    createUserOwnsData(input: $input) {
      id
      owner
      dataID
      userID
    }
  }
`;
export type CreateUserOwnsDataManagedMutationFn = Apollo.MutationFunction<
  CreateUserOwnsDataManagedMutation,
  CreateUserOwnsDataManagedMutationVariables
>;

/**
 * __useCreateUserOwnsDataManagedMutation__
 *
 * To run a mutation, you first call `useCreateUserOwnsDataManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOwnsDataManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOwnsDataManagedMutation, { data, loading, error }] = useCreateUserOwnsDataManagedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserOwnsDataManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserOwnsDataManagedMutation,
    CreateUserOwnsDataManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserOwnsDataManagedMutation,
    CreateUserOwnsDataManagedMutationVariables
  >(CreateUserOwnsDataManagedDocument, baseOptions);
}
export type CreateUserOwnsDataManagedMutationHookResult = ReturnType<
  typeof useCreateUserOwnsDataManagedMutation
>;
export type CreateUserOwnsDataManagedMutationResult = Apollo.MutationResult<CreateUserOwnsDataManagedMutation>;
export type CreateUserOwnsDataManagedMutationOptions = Apollo.BaseMutationOptions<
  CreateUserOwnsDataManagedMutation,
  CreateUserOwnsDataManagedMutationVariables
>;
export const GetDataInternalDocument = gql`
  query GetDataInternal(
    $id: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    getData(id: $id) {
      id
      updatedAt
      owner
      ownerEdge {
        id
        user {
          id
          username
        }
      }
      name
      notes
      header {
        exampleValue
        name
        columnType
        required
      }
      snippet
      numRows
      fileSizeBytes
      userFollows(userID: $userID) {
        items {
          id
          userID
        }
      }
      topicNames
      questions(sortDirection: $sortDirection) {
        items {
          id
          score
          question {
            ...QuestionBasics
            data(sortDirection: $sortDirection) {
              items {
                id
                data {
                  ...DataCardBasics
                }
              }
              nextToken
            }
          }
        }
        nextToken
      }
      datasBackward(sortDirection: $sortDirection) {
        items {
          id
          score
          dataA {
            questions {
              items {
                id
                question {
                  ...QuestionBasics
                }
              }
              nextToken
            }
            datasForward(sortDirection: $sortDirection) {
              items {
                id
                score
                dataB {
                  questions {
                    items {
                      id
                      question {
                        ...QuestionBasics
                      }
                    }
                    nextToken
                  }
                  ...DataCardBasics
                }
              }
              nextToken
            }
            datasBackward(sortDirection: $sortDirection) {
              items {
                id
                score
                dataA {
                  ...DataCardBasics
                  questions {
                    items {
                      id
                      question {
                        ...QuestionBasics
                      }
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            ...DataCardBasics
          }
        }
        nextToken
      }
      datasForward(sortDirection: $sortDirection) {
        items {
          id
          score
          dataB {
            questions {
              items {
                id
                question {
                  ...QuestionBasics
                }
              }
              nextToken
            }
            datasForward(sortDirection: $sortDirection) {
              items {
                id
                score
                dataB {
                  questions {
                    items {
                      id
                      question {
                        ...QuestionBasics
                      }
                    }
                    nextToken
                  }
                  ...DataCardBasics
                }
              }
              nextToken
            }
            datasBackward(sortDirection: $sortDirection) {
              items {
                id
                score
                dataA {
                  questions {
                    items {
                      id
                      question {
                        ...QuestionBasics
                      }
                    }
                    nextToken
                  }
                  ...DataCardBasics
                }
              }
              nextToken
            }
            ...DataCardBasics
          }
        }
        nextToken
      }
      s3Bucket
      s3Key
      s3Prefix
      s3Basename
      references {
        name
        link
      }
      isRequest
    }
  }
  ${QuestionBasicsFragmentDoc}
  ${DataCardBasicsFragmentDoc}
`;

/**
 * __useGetDataInternalQuery__
 *
 * To run a query within a React component, call `useGetDataInternalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataInternalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataInternalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetDataInternalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataInternalQuery,
    GetDataInternalQueryVariables
  >
) {
  return Apollo.useQuery<GetDataInternalQuery, GetDataInternalQueryVariables>(
    GetDataInternalDocument,
    baseOptions
  );
}
export function useGetDataInternalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataInternalQuery,
    GetDataInternalQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDataInternalQuery, GetDataInternalQueryVariables>(
    GetDataInternalDocument,
    baseOptions
  );
}
export type GetDataInternalQueryHookResult = ReturnType<typeof useGetDataInternalQuery>;
export type GetDataInternalLazyQueryHookResult = ReturnType<
  typeof useGetDataInternalLazyQuery
>;
export type GetDataInternalQueryResult = Apollo.QueryResult<
  GetDataInternalQuery,
  GetDataInternalQueryVariables
>;
export const GetDataBasicsDocument = gql`
  query GetDataBasics($id: ID!) {
    getData(id: $id) {
      fileSizeBytes
      numRows
      notes
      references {
        name
        link
      }
      snippet
      s3Bucket
      s3Basename
      s3Prefix
      s3Key
      ...DataCardBasics
    }
  }
  ${DataCardBasicsFragmentDoc}
`;

/**
 * __useGetDataBasicsQuery__
 *
 * To run a query within a React component, call `useGetDataBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataBasicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataBasicsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDataBasicsQuery, GetDataBasicsQueryVariables>
) {
  return Apollo.useQuery<GetDataBasicsQuery, GetDataBasicsQueryVariables>(
    GetDataBasicsDocument,
    baseOptions
  );
}
export function useGetDataBasicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataBasicsQuery,
    GetDataBasicsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDataBasicsQuery, GetDataBasicsQueryVariables>(
    GetDataBasicsDocument,
    baseOptions
  );
}
export type GetDataBasicsQueryHookResult = ReturnType<typeof useGetDataBasicsQuery>;
export type GetDataBasicsLazyQueryHookResult = ReturnType<
  typeof useGetDataBasicsLazyQuery
>;
export type GetDataBasicsQueryResult = Apollo.QueryResult<
  GetDataBasicsQuery,
  GetDataBasicsQueryVariables
>;
export const GetDataManagedWrapperDocument = gql`
  query GetDataManagedWrapper($id: ID!) {
    getDataManaged(id: $id) {
      id
      owner
      ownerUsername
      name
      header {
        exampleValue
        name
        columnType
        required
        exampleValue
      }
      snippet
      numRows
      fileSizeBytes
      references {
        name
        link
      }
      notes
      s3Bucket
      s3Key
      s3Prefix
      s3Basename
      isRequest
      updatedAt
      questTrains {
        trainCars {
          id
          carType
          question {
            id
            ownerUsername
            text
            updatedAt
          }
          data {
            id
            owner
            ownerUsername
            updatedAt
            isRequest
            name
            header {
              exampleValue
              name
              columnType
              required
            }
          }
        }
      }
      amIFollower
      topicNames
    }
  }
`;

/**
 * __useGetDataManagedWrapperQuery__
 *
 * To run a query within a React component, call `useGetDataManagedWrapperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataManagedWrapperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataManagedWrapperQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataManagedWrapperQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataManagedWrapperQuery,
    GetDataManagedWrapperQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDataManagedWrapperQuery,
    GetDataManagedWrapperQueryVariables
  >(GetDataManagedWrapperDocument, baseOptions);
}
export function useGetDataManagedWrapperLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataManagedWrapperQuery,
    GetDataManagedWrapperQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDataManagedWrapperQuery,
    GetDataManagedWrapperQueryVariables
  >(GetDataManagedWrapperDocument, baseOptions);
}
export type GetDataManagedWrapperQueryHookResult = ReturnType<
  typeof useGetDataManagedWrapperQuery
>;
export type GetDataManagedWrapperLazyQueryHookResult = ReturnType<
  typeof useGetDataManagedWrapperLazyQuery
>;
export type GetDataManagedWrapperQueryResult = Apollo.QueryResult<
  GetDataManagedWrapperQuery,
  GetDataManagedWrapperQueryVariables
>;
export const GetQuestionOwnerDocument = gql`
  query GetQuestionOwner($id: ID!) {
    getQuestion(id: $id) {
      id
      owner
    }
  }
`;

/**
 * __useGetQuestionOwnerQuery__
 *
 * To run a query within a React component, call `useGetQuestionOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionOwnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionOwnerQuery,
    GetQuestionOwnerQueryVariables
  >
) {
  return Apollo.useQuery<GetQuestionOwnerQuery, GetQuestionOwnerQueryVariables>(
    GetQuestionOwnerDocument,
    baseOptions
  );
}
export function useGetQuestionOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionOwnerQuery,
    GetQuestionOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetQuestionOwnerQuery, GetQuestionOwnerQueryVariables>(
    GetQuestionOwnerDocument,
    baseOptions
  );
}
export type GetQuestionOwnerQueryHookResult = ReturnType<
  typeof useGetQuestionOwnerQuery
>;
export type GetQuestionOwnerLazyQueryHookResult = ReturnType<
  typeof useGetQuestionOwnerLazyQuery
>;
export type GetQuestionOwnerQueryResult = Apollo.QueryResult<
  GetQuestionOwnerQuery,
  GetQuestionOwnerQueryVariables
>;
export const GetDataOwnerDocument = gql`
  query GetDataOwner($id: ID!) {
    getData(id: $id) {
      id
      owner
    }
  }
`;

/**
 * __useGetDataOwnerQuery__
 *
 * To run a query within a React component, call `useGetDataOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataOwnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<GetDataOwnerQuery, GetDataOwnerQueryVariables>
) {
  return Apollo.useQuery<GetDataOwnerQuery, GetDataOwnerQueryVariables>(
    GetDataOwnerDocument,
    baseOptions
  );
}
export function useGetDataOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataOwnerQuery,
    GetDataOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDataOwnerQuery, GetDataOwnerQueryVariables>(
    GetDataOwnerDocument,
    baseOptions
  );
}
export type GetDataOwnerQueryHookResult = ReturnType<typeof useGetDataOwnerQuery>;
export type GetDataOwnerLazyQueryHookResult = ReturnType<
  typeof useGetDataOwnerLazyQuery
>;
export type GetDataOwnerQueryResult = Apollo.QueryResult<
  GetDataOwnerQuery,
  GetDataOwnerQueryVariables
>;
export const GetQuestionForDeleteDocument = gql`
  query GetQuestionForDelete($id: ID!) {
    getQuestion(id: $id) {
      __typename
      id
      owner
      ownerEdge {
        __typename
        questionID
        owner
      }
      topicNames
      questionsBackward {
        items {
          __typename
          id
        }
        nextToken
      }
      questionsForward {
        items {
          __typename
          id
        }
        nextToken
      }
      questTrains {
        items {
          __typename
          id
        }
        nextToken
      }
      userFollows {
        items {
          __typename
          id
        }
        nextToken
      }
      data {
        items {
          __typename
          id
        }
        nextToken
      }
    }
  }
`;

/**
 * __useGetQuestionForDeleteQuery__
 *
 * To run a query within a React component, call `useGetQuestionForDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionForDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionForDeleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionForDeleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionForDeleteQuery,
    GetQuestionForDeleteQueryVariables
  >
) {
  return Apollo.useQuery<GetQuestionForDeleteQuery, GetQuestionForDeleteQueryVariables>(
    GetQuestionForDeleteDocument,
    baseOptions
  );
}
export function useGetQuestionForDeleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionForDeleteQuery,
    GetQuestionForDeleteQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetQuestionForDeleteQuery,
    GetQuestionForDeleteQueryVariables
  >(GetQuestionForDeleteDocument, baseOptions);
}
export type GetQuestionForDeleteQueryHookResult = ReturnType<
  typeof useGetQuestionForDeleteQuery
>;
export type GetQuestionForDeleteLazyQueryHookResult = ReturnType<
  typeof useGetQuestionForDeleteLazyQuery
>;
export type GetQuestionForDeleteQueryResult = Apollo.QueryResult<
  GetQuestionForDeleteQuery,
  GetQuestionForDeleteQueryVariables
>;
export const GetDataForDeleteDocument = gql`
  query GetDataForDelete($id: ID!) {
    getData(id: $id) {
      __typename
      id
      owner
      s3Bucket
      s3Key
      ownerEdge {
        __typename
        dataID
        owner
      }
      topicNames
      datasBackward {
        items {
          __typename
          id
        }
        nextToken
      }
      datasForward {
        items {
          __typename
          id
        }
        nextToken
      }
      questions {
        items {
          __typename
          id
        }
        nextToken
      }
      userFollows {
        items {
          __typename
          id
        }
        nextToken
      }
    }
  }
`;

/**
 * __useGetDataForDeleteQuery__
 *
 * To run a query within a React component, call `useGetDataForDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForDeleteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataForDeleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataForDeleteQuery,
    GetDataForDeleteQueryVariables
  >
) {
  return Apollo.useQuery<GetDataForDeleteQuery, GetDataForDeleteQueryVariables>(
    GetDataForDeleteDocument,
    baseOptions
  );
}
export function useGetDataForDeleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForDeleteQuery,
    GetDataForDeleteQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDataForDeleteQuery, GetDataForDeleteQueryVariables>(
    GetDataForDeleteDocument,
    baseOptions
  );
}
export type GetDataForDeleteQueryHookResult = ReturnType<
  typeof useGetDataForDeleteQuery
>;
export type GetDataForDeleteLazyQueryHookResult = ReturnType<
  typeof useGetDataForDeleteLazyQuery
>;
export type GetDataForDeleteQueryResult = Apollo.QueryResult<
  GetDataForDeleteQuery,
  GetDataForDeleteQueryVariables
>;
export const ListUserFollowsQuestionsManagedDocument = gql`
  query ListUserFollowsQuestionsManaged(
    $filter: ModelUserFollowsQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowsQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
      }
      nextToken
    }
  }
`;

/**
 * __useListUserFollowsQuestionsManagedQuery__
 *
 * To run a query within a React component, call `useListUserFollowsQuestionsManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsQuestionsManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsQuestionsManagedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserFollowsQuestionsManagedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserFollowsQuestionsManagedQuery,
    ListUserFollowsQuestionsManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    ListUserFollowsQuestionsManagedQuery,
    ListUserFollowsQuestionsManagedQueryVariables
  >(ListUserFollowsQuestionsManagedDocument, baseOptions);
}
export function useListUserFollowsQuestionsManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserFollowsQuestionsManagedQuery,
    ListUserFollowsQuestionsManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListUserFollowsQuestionsManagedQuery,
    ListUserFollowsQuestionsManagedQueryVariables
  >(ListUserFollowsQuestionsManagedDocument, baseOptions);
}
export type ListUserFollowsQuestionsManagedQueryHookResult = ReturnType<
  typeof useListUserFollowsQuestionsManagedQuery
>;
export type ListUserFollowsQuestionsManagedLazyQueryHookResult = ReturnType<
  typeof useListUserFollowsQuestionsManagedLazyQuery
>;
export type ListUserFollowsQuestionsManagedQueryResult = Apollo.QueryResult<
  ListUserFollowsQuestionsManagedQuery,
  ListUserFollowsQuestionsManagedQueryVariables
>;
export const ListUserFollowsDatasManagedDocument = gql`
  query ListUserFollowsDatasManaged(
    $filter: ModelUserFollowsDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowsDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
      }
      nextToken
    }
  }
`;

/**
 * __useListUserFollowsDatasManagedQuery__
 *
 * To run a query within a React component, call `useListUserFollowsDatasManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsDatasManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsDatasManagedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserFollowsDatasManagedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserFollowsDatasManagedQuery,
    ListUserFollowsDatasManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    ListUserFollowsDatasManagedQuery,
    ListUserFollowsDatasManagedQueryVariables
  >(ListUserFollowsDatasManagedDocument, baseOptions);
}
export function useListUserFollowsDatasManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserFollowsDatasManagedQuery,
    ListUserFollowsDatasManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListUserFollowsDatasManagedQuery,
    ListUserFollowsDatasManagedQueryVariables
  >(ListUserFollowsDatasManagedDocument, baseOptions);
}
export type ListUserFollowsDatasManagedQueryHookResult = ReturnType<
  typeof useListUserFollowsDatasManagedQuery
>;
export type ListUserFollowsDatasManagedLazyQueryHookResult = ReturnType<
  typeof useListUserFollowsDatasManagedLazyQuery
>;
export type ListUserFollowsDatasManagedQueryResult = Apollo.QueryResult<
  ListUserFollowsDatasManagedQuery,
  ListUserFollowsDatasManagedQueryVariables
>;
export const ListUserFollowsUsersManagedDocument = gql`
  query ListUserFollowsUsersManaged(
    $filter: ModelUserFollowsUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowsUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
      }
      nextToken
    }
  }
`;

/**
 * __useListUserFollowsUsersManagedQuery__
 *
 * To run a query within a React component, call `useListUserFollowsUsersManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserFollowsUsersManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserFollowsUsersManagedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserFollowsUsersManagedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserFollowsUsersManagedQuery,
    ListUserFollowsUsersManagedQueryVariables
  >
) {
  return Apollo.useQuery<
    ListUserFollowsUsersManagedQuery,
    ListUserFollowsUsersManagedQueryVariables
  >(ListUserFollowsUsersManagedDocument, baseOptions);
}
export function useListUserFollowsUsersManagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserFollowsUsersManagedQuery,
    ListUserFollowsUsersManagedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListUserFollowsUsersManagedQuery,
    ListUserFollowsUsersManagedQueryVariables
  >(ListUserFollowsUsersManagedDocument, baseOptions);
}
export type ListUserFollowsUsersManagedQueryHookResult = ReturnType<
  typeof useListUserFollowsUsersManagedQuery
>;
export type ListUserFollowsUsersManagedLazyQueryHookResult = ReturnType<
  typeof useListUserFollowsUsersManagedLazyQuery
>;
export type ListUserFollowsUsersManagedQueryResult = Apollo.QueryResult<
  ListUserFollowsUsersManagedQuery,
  ListUserFollowsUsersManagedQueryVariables
>;
export const CreateUserFollowsUserManagedDocument = gql`
  mutation CreateUserFollowsUserManaged($input: CreateUserFollowsUserInput!) {
    createUserFollowsUser(input: $input) {
      id
      owner
      followedID
      followerID
    }
  }
`;
export type CreateUserFollowsUserManagedMutationFn = Apollo.MutationFunction<
  CreateUserFollowsUserManagedMutation,
  CreateUserFollowsUserManagedMutationVariables
>;

/**
 * __useCreateUserFollowsUserManagedMutation__
 *
 * To run a mutation, you first call `useCreateUserFollowsUserManagedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFollowsUserManagedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFollowsUserManagedMutation, { data, loading, error }] = useCreateUserFollowsUserManagedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserFollowsUserManagedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFollowsUserManagedMutation,
    CreateUserFollowsUserManagedMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserFollowsUserManagedMutation,
    CreateUserFollowsUserManagedMutationVariables
  >(CreateUserFollowsUserManagedDocument, baseOptions);
}
export type CreateUserFollowsUserManagedMutationHookResult = ReturnType<
  typeof useCreateUserFollowsUserManagedMutation
>;
export type CreateUserFollowsUserManagedMutationResult = Apollo.MutationResult<CreateUserFollowsUserManagedMutation>;
export type CreateUserFollowsUserManagedMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFollowsUserManagedMutation,
  CreateUserFollowsUserManagedMutationVariables
>;
