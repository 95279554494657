import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useGeneralStyles } from 'GeneralStyle';
import React from 'react';
import { RouteHistoryProps } from 'RouterProps';

interface UserButtonProps {
  ownerUsername: string;
}

export function UserButton(props: UserButtonProps & RouteHistoryProps) {
  const { ownerUsername, history } = props;

  const classes = useGeneralStyles();

  return (
    <Tooltip title={`View ${ownerUsername}'s profile`}>
      <IconButton
        classes={{ label: classes.iconLabel }}
        onClick={(event) => {
          event.stopPropagation();
          history.push(`/user/${ownerUsername}`);
        }}
      >
        <AccountCircle color="primary" />
        {ownerUsername}
      </IconButton>
    </Tooltip>
  );
}
