import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  useGetPublicUserProfileManagedQuery,
  useGetMyUserProfileManagedQuery,
  NotificationResult,
  useUpdateUserFollowerMutation,
  SearchUserResult,
} from 'data/types';
import { DataCard, DataCardList } from 'DataCard';
import { FollowerToggle } from 'Follower';
import { useGeneralStyles } from 'GeneralStyle';
import { QuestionResults } from 'BrowseEngine';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderError, statusGateway } from 'Reportable';
import { RouteHistoryProps } from 'RouterProps';
import { UserButton } from 'UserButton';
import { QuestionCard } from 'ViewQuestion';

interface UserCardProps {
  user: SearchUserResult;
}

function UserCard(props: UserCardProps & RouteHistoryProps) {
  const { user, history } = props;

  function onSelectUser() {
    history.push(`/user/${user.ownerUsername}`);
  }

  return (
    <Card key={user.id} variant="outlined">
      <CardActionArea onClick={() => onSelectUser()}>
        <CardContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <UserButton ownerUsername={user.ownerUsername} history={history} />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

interface UserCardListProps {
  label?: JSX.Element;
  users?: Array<SearchUserResult>;
}

function UserCardList(props: UserCardListProps & RouteHistoryProps) {
  const { label, users, history } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {label ? <Grid item>{label}</Grid> : undefined}
      {users?.length ? (
        users.map((user) => (
          <Grid item>
            <UserCard user={user} history={history} />
          </Grid>
        ))
      ) : (
        <Grid item>
          No users found. To follow another user, you must be logged in. Then, you can
          click the star symbol on their profile.
        </Grid>
      )}
    </Grid>
  );
}

interface NotificationsProps {
  notifications: Array<NotificationResult>;
}

function Notifications(props: NotificationsProps & RouteHistoryProps) {
  const { notifications, history } = props;

  function renderNotification(notification: NotificationResult) {
    return (
      <Grid item>
        <Card>
          <Grid
            container
            justify="center"
            direction="row"
            alignContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <UserButton
                ownerUsername={notification.actorUsername}
                history={history}
              />
            </Grid>
            {notification.actorData || notification.actorQuestion ? (
              <Grid item>linked</Grid>
            ) : (
              <Grid item>followed</Grid>
            )}
            {notification.actorData || notification.actorQuestion ? (
              <Grid item>
                {notification.actorQuestion ? (
                  <QuestionCard
                    question={notification.actorQuestion}
                    trainCarInputs={[]}
                    history={history}
                  />
                ) : undefined}
                {notification.actorData ? (
                  <DataCard
                    trainCarInputs={[]}
                    data={notification.actorData}
                    history={history}
                  />
                ) : undefined}
              </Grid>
            ) : undefined}
            {notification.actorData || notification.actorQuestion ? (
              <Grid item>to</Grid>
            ) : undefined}
            {}
            <Grid item>
              {notification.followedQuestion ? (
                <QuestionCard
                  question={notification.followedQuestion}
                  trainCarInputs={[]}
                  history={history}
                />
              ) : undefined}
              {notification.followedData ? (
                <DataCard
                  data={notification.followedData}
                  trainCarInputs={[]}
                  history={history}
                />
              ) : undefined}
              {!notification.followedData && !notification.followedQuestion
                ? 'you'
                : undefined}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      direction="column"
      alignContent="center"
      alignItems="center"
    >
      {notifications.map((notification) => renderNotification(notification))}
      {notifications.length === 0 ? 'No notifications to report.' : undefined}
    </Grid>
  );
}

interface UserProfileProps {
  viewerUsername?: string;
  targetUsername: string;
}

enum UserProfileSlider {
  Questions = 'Questions',
  Data = 'Data',
}

export function UserProfile(props: UserProfileProps & RouteHistoryProps) {
  const { viewerUsername, targetUsername, history } = props;

  const classes = useGeneralStyles();

  const [slider, setSlider] = useState(UserProfileSlider.Questions);

  const { data, error, loading } = useGetPublicUserProfileManagedQuery({
    variables: { username: targetUsername.toLowerCase() },
  });

  const [
    updateUserFollower,
    { data: dataUserFollower },
  ] = useUpdateUserFollowerMutation();

  function changeSlider(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: UserProfileSlider
  ) {
    if (value !== null) {
      setSlider(value);
    }
  }

  return (
    statusGateway({ error, loading }) || (
      <Grid
        container
        spacing={2}
        justify="center"
        direction="column"
        alignContent="center"
        alignItems="center"
        className={classes.centeredWithTopMargin}
      >
        <Grid item>
          <Grid
            container
            spacing={2}
            justify="center"
            direction="row"
            alignContent="center"
            alignItems="center"
          >
            <Grid item>
              <AccountCircle color="primary" />
            </Grid>
            <Grid item>{targetUsername}</Grid>
          </Grid>
        </Grid>
        {viewerUsername ? (
          <Grid item>
            <FollowerToggle
              followedID={targetUsername}
              defaultFollows={!!data?.getPublicUserProfile?.amIFollower}
              updateFollower={updateUserFollower}
              linkResult={dataUserFollower?.updateUserFollower}
            />
          </Grid>
        ) : undefined}
        <Grid item>
          <ToggleButtonGroup value={slider} exclusive onChange={changeSlider}>
            <ToggleButton value={UserProfileSlider.Questions}>
              <Tooltip title="Browse by question">
                <Badge
                  color="primary"
                  badgeContent={data?.getPublicUserProfile?.ownedQuestions?.length}
                >
                  <Typography>{UserProfileSlider.Questions}&nbsp;&nbsp;</Typography>
                </Badge>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={UserProfileSlider.Data}>
              <Tooltip title="Browse by data">
                <Badge
                  color="primary"
                  badgeContent={data?.getPublicUserProfile?.ownedDatas?.length}
                >
                  <Typography>{UserProfileSlider.Data}&nbsp;&nbsp;</Typography>
                </Badge>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          {data?.getPublicUserProfile?.ownedQuestions &&
          slider === UserProfileSlider.Questions ? (
            <QuestionResults
              questions={data.getPublicUserProfile.ownedQuestions}
              trainCarInputs={[]}
              history={history}
            />
          ) : undefined}
          {data?.getPublicUserProfile?.ownedDatas &&
          slider === UserProfileSlider.Data ? (
            <DataCardList
              dataList={data.getPublicUserProfile.ownedDatas}
              trainCarInputs={[]}
              history={history}
            />
          ) : undefined}
        </Grid>
      </Grid>
    )
  );
}

enum GeneralUserProfileSlider {
  MyStuff = 'My Stuff',
  Follows = 'Follows',
}

enum MyStuffSlider {
  Notifications = 'Notifications',
  Questions = 'Questions',
  Data = 'Data',
}

enum FollowsSlider {
  Questions = 'Questions',
  Data = 'Data',
  Followers = 'Followers',
  Following = 'Following',
}

export function MyUserProfile(props: RouteHistoryProps) {
  const { history } = props;

  const classes = useGeneralStyles();

  const [myStuffSlider, setMyStuffSlider] = useState(MyStuffSlider.Notifications);
  const [followsSlider, setFollowsSlider] = useState(FollowsSlider.Questions);
  const [generalSlider, setGeneralSlider] = useState(GeneralUserProfileSlider.MyStuff);

  const { data, error, loading } = useGetMyUserProfileManagedQuery();

  const myProfile = data?.getMyUserProfile;

  function changeGeneralSlider(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: GeneralUserProfileSlider
  ) {
    if (value !== null) {
      setGeneralSlider(value);
    }
  }

  function changeMyStuffSlider(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: MyStuffSlider
  ) {
    if (value !== null) {
      setMyStuffSlider(value);
    }
  }

  function changeFollowsSlider(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: FollowsSlider
  ) {
    if (value !== null) {
      setFollowsSlider(value);
    }
  }

  return (
    statusGateway({ error, loading }) ||
    (myProfile ? (
      <Grid
        container
        spacing={2}
        justify="center"
        direction="column"
        alignContent="center"
        alignItems="center"
        className={classes.centeredWithTopMargin}
      >
        <Grid item>
          <Grid
            container
            spacing={2}
            justify="center"
            direction="row"
            alignContent="center"
            alignItems="center"
          >
            <Grid item>
              <AccountCircle color="primary" />
            </Grid>
            <Grid item>{myProfile.username}</Grid>
          </Grid>
        </Grid>
        <Grid item>{myProfile.name}</Grid>
        <Grid item>{myProfile.email}</Grid>
        <Grid item>
          <ToggleButtonGroup
            value={generalSlider}
            exclusive
            onChange={changeGeneralSlider}
          >
            <ToggleButton value={GeneralUserProfileSlider.MyStuff}>
              <Tooltip title="See my notifications">
                <Typography>{GeneralUserProfileSlider.MyStuff}</Typography>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={GeneralUserProfileSlider.Follows}>
              <Tooltip title="See my questions">
                <Typography>{GeneralUserProfileSlider.Follows}</Typography>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          {generalSlider === GeneralUserProfileSlider.MyStuff ? (
            <ToggleButtonGroup
              value={myStuffSlider}
              exclusive
              onChange={changeMyStuffSlider}
            >
              <ToggleButton value={MyStuffSlider.Notifications}>
                <Tooltip title="See my notifications">
                  <Badge color="primary" badgeContent={myProfile.notifications?.length}>
                    <Typography>{MyStuffSlider.Notifications}&nbsp;&nbsp;</Typography>
                  </Badge>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={MyStuffSlider.Questions}>
                <Tooltip title="See my questions">
                  <Badge
                    color="primary"
                    badgeContent={myProfile.ownedQuestions?.length}
                  >
                    <Typography>{MyStuffSlider.Questions}&nbsp;&nbsp;</Typography>
                  </Badge>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={MyStuffSlider.Data}>
                <Tooltip title="See my data">
                  <Badge color="primary" badgeContent={myProfile.ownedDatas?.length}>
                    <Typography>{MyStuffSlider.Data}&nbsp;&nbsp;</Typography>
                  </Badge>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          ) : undefined}
          {generalSlider === GeneralUserProfileSlider.Follows ? (
            <ToggleButtonGroup
              value={followsSlider}
              exclusive
              onChange={changeFollowsSlider}
            >
              <ToggleButton value={FollowsSlider.Questions}>
                <Tooltip title="See questions I'm following">
                  <Badge
                    color="primary"
                    badgeContent={myProfile.followedQuestions?.length}
                  >
                    <Typography>{FollowsSlider.Questions}&nbsp;&nbsp;</Typography>
                  </Badge>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={FollowsSlider.Data}>
                <Tooltip title="See data I'm following">
                  <Badge color="primary" badgeContent={myProfile.followedDatas?.length}>
                    <Typography>{FollowsSlider.Data}&nbsp;&nbsp;</Typography>
                  </Badge>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          ) : undefined}
          {generalSlider === GeneralUserProfileSlider.Follows ? <br /> : undefined}
          {generalSlider === GeneralUserProfileSlider.Follows ? (
            <ToggleButtonGroup
              value={followsSlider}
              exclusive
              onChange={changeFollowsSlider}
            >
              <ToggleButton value={FollowsSlider.Followers}>
                <Tooltip title="See the users who follow me">
                  <Badge
                    color="primary"
                    badgeContent={myProfile.followedByUsers?.length}
                  >
                    <Typography>{FollowsSlider.Followers}&nbsp;&nbsp;</Typography>
                  </Badge>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={FollowsSlider.Following}>
                <Tooltip title="See the users I'm following">
                  <Badge
                    color="primary"
                    badgeContent={myProfile.followingUsers?.length}
                  >
                    <Typography>{FollowsSlider.Following}&nbsp;&nbsp;</Typography>
                  </Badge>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          ) : undefined}
        </Grid>
        <Grid item>
          {myProfile.ownedQuestions &&
          generalSlider === GeneralUserProfileSlider.MyStuff &&
          myStuffSlider === MyStuffSlider.Questions ? (
            <QuestionResults
              questions={myProfile.ownedQuestions}
              trainCarInputs={[]}
              history={history}
            />
          ) : undefined}
          {myProfile.ownedDatas &&
          generalSlider === GeneralUserProfileSlider.MyStuff &&
          myStuffSlider === MyStuffSlider.Data ? (
            <DataCardList
              dataList={myProfile.ownedDatas}
              trainCarInputs={[]}
              history={history}
            />
          ) : undefined}
          {myProfile.notifications &&
          generalSlider === GeneralUserProfileSlider.MyStuff &&
          myStuffSlider === MyStuffSlider.Notifications ? (
            <Notifications notifications={myProfile.notifications} history={history} />
          ) : undefined}
          {myProfile.followedQuestions &&
          generalSlider === GeneralUserProfileSlider.Follows &&
          followsSlider === FollowsSlider.Questions ? (
            <QuestionResults
              questions={myProfile.followedQuestions}
              trainCarInputs={[]}
              history={history}
            />
          ) : undefined}
          {myProfile.followedDatas &&
          generalSlider === GeneralUserProfileSlider.Follows &&
          followsSlider === FollowsSlider.Data ? (
            <DataCardList
              dataList={myProfile.followedDatas}
              trainCarInputs={[]}
              history={history}
            />
          ) : undefined}
          {myProfile.followedByUsers &&
          generalSlider === GeneralUserProfileSlider.Follows &&
          followsSlider === FollowsSlider.Followers ? (
            <UserCardList users={myProfile.followedByUsers} history={history} />
          ) : undefined}
          {myProfile.followingUsers &&
          generalSlider === GeneralUserProfileSlider.Follows &&
          followsSlider === FollowsSlider.Following ? (
            <UserCardList users={myProfile.followingUsers} history={history} />
          ) : undefined}
        </Grid>
      </Grid>
    ) : (
      renderError(new Error('Could not find your profile! Are you logged in?'))
    ))
  );
}

interface UserProfileRouteProps {
  username?: string;
}

export function UserProfileRoute(props: UserProfileRouteProps & RouteHistoryProps) {
  const { username, history } = props;
  const { targetUsername } = useParams<{
    targetUsername?: string;
  }>();

  if (!targetUsername) {
    return renderError(new Error('Could not find targetUsername!'));
  }

  return (
    <UserProfile
      viewerUsername={username}
      targetUsername={targetUsername.toLowerCase()}
      history={history}
    />
  );
}

interface MyUserProfileRouteProps {
  username?: string;
}

export function MyUserProfileRoute(props: MyUserProfileRouteProps & RouteHistoryProps) {
  const { username, history } = props;

  if (!username) {
    return renderError(new Error('No user found! Maybe you are not logged in?'));
  }

  return <MyUserProfile history={history} />;
}
