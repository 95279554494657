import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useGeneralStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      textAlign: 'center',
      alignItems: 'center',
      justify: 'center',
    },
    centeredWithTopMargin: {
      textAlign: 'center',
      alignItems: 'center',
      justify: 'center',
      marginTop: '5%',
    },
    verificationCode: {
      fontFamily: 'Roboto',
      textAlign: 'center',
    },
    topMargin: {
      marginTop: '5%',
    },
    iconLabel: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
    },
    divider: {
      width: '100%',
      backgroundColor: theme.palette.grey['300'],
    },
    responsiveBar: {
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
    },
  })
);
