import React from 'react';
import { Layout } from 'Layout';
import { NotFound } from 'NotFound';

import { Amplify } from 'aws-amplify';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import awsExports from './aws-exports';

const hist = createBrowserHistory();
Amplify.configure(awsExports);

const defaultTheme = createMuiTheme({
  palette: {
    primary: { main: '#008f81' },
    secondary: { main: '#05668d', light: '#e2f6fe' },
    success: { main: '#2dce89' },
    info: { main: '#11cdef' },
    warning: { main: '#fb6340' },
    error: { main: '#f5365c' },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CoreApp />
    </ThemeProvider>
  );
}

export function CoreApp() {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/" component={Layout} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}
