import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {
  useUpdateDataFollowerMutation,
  useDeleteDataMutation,
  useGetDataManagedWrapperQuery,
  useIncrementDataViewsMutation,
  TrainCarInput,
  CarType,
  useGetDataBasicsQuery,
} from 'data/types';
import { useGeneralStyles } from 'GeneralStyle';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderError, statusGateway } from 'Reportable';
import { RouteHistoryProps } from 'RouterProps';
import { UserButton } from 'UserButton';
import { ConnectMore } from 'ViewQuestion';
import { Storage } from 'aws-amplify';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { analyticsCapture } from 'Analytics';
import Alert from '@material-ui/lab/Alert';
import Assignment from '@material-ui/icons/Assignment';
import StorageIcon from '@material-ui/icons/Storage';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { FollowerToggle } from 'Follower';
import { AnchorLocation, AnchorType, QuestTrainList } from 'QuestTrain';
import { ViewTopics } from 'Topics';
import Edit from '@material-ui/icons/Edit';
import { ContributeSlider } from './EngineRoute';

const CsvToHtmlTable = require('react-csv-to-table');

interface ViewDataRouteProps {
  username?: string;
}

export function ViewDataRoute(props: ViewDataRouteProps & RouteHistoryProps) {
  const { username, history } = props;
  const classes = useGeneralStyles();

  const { dataID } = useParams<{
    dataID?: string;
  }>();

  const [incrementDataViews] = useIncrementDataViewsMutation();

  useEffect(() => {
    if (dataID) {
      analyticsCapture('View Data', { dataID });
      incrementDataViews({ variables: { id: dataID } });
    }
  }, [incrementDataViews, dataID]);

  if (!dataID) {
    return renderError(new Error(`No data!`));
  }

  return (
    <div className={classes.centeredWithTopMargin}>
      <ViewData id={dataID} history={history} username={username} />
    </div>
  );
}

interface ViewDataProps {
  id: string;
  username?: string;
}

export function ViewData(props: ViewDataProps & RouteHistoryProps) {
  const { id, username, history } = props;

  const [downloadUrl, setDownloadUrl] = useState<string>();

  const {
    data: responseBasic,
    error: errorBasic,
    loading: loadingBasic,
  } = useGetDataBasicsQuery({
    variables: {
      id,
    },
  });

  const {
    data: responseFull,
    error: errorFull,
    loading: loadingFull,
  } = useGetDataManagedWrapperQuery({
    variables: {
      id,
    },
  });

  const [contributeSlider, setContributeSlider] = useState(ContributeSlider.Question);
  const [opened, setOpened] = useState(false);

  const dataFull = responseFull?.getDataManaged;
  const dataBasic = responseBasic?.getData;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const [updateDataFollower, { data: dataFollower }] = useUpdateDataFollowerMutation();

  const [
    deleteData,
    { data: dataDelete, error: errorDelete, loading: loadingDelete },
  ] = useDeleteDataMutation();

  useEffect(() => {
    if (dataDelete?.deleteData) {
      history.push('/');
    }
  });

  function onDelete() {
    deleteData({ variables: { id } });
  }

  useEffect(() => {
    async function getUrl() {
      if (dataBasic) {
        const url: any = await Storage.get(dataBasic.s3Basename, {
          level: dataBasic.s3Prefix,
          download: false,
          identityId: dataBasic.s3Prefix === 'public' ? undefined : dataBasic.owner,
        });
        if (typeof url === 'string') {
          setDownloadUrl(url);
        }
      }
    }
    getUrl();
  }, [dataBasic]);

  const statusBasic = statusGateway({ error: errorBasic, loading: loadingBasic });
  if (statusBasic) {
    return statusBasic;
  }

  if (!dataBasic) {
    return renderError(new Error('No data found!'));
  }

  const statusFull = statusGateway({ error: errorFull, loading: loadingFull });

  const trainCarInputs: Array<TrainCarInput> = [
    { carType: CarType.DataType, useExisting: { id: dataBasic.id } },
  ];

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      direction="column"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography>{dataBasic.name}</Typography>
      </Grid>
      <Grid item>
        {dataBasic.isRequest ? (
          <Tooltip title="This is a data request">
            <Assignment color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title="This is a data element">
            <StorageIcon color="primary" />
          </Tooltip>
        )}
      </Grid>
      {dataFull?.topicNames ? (
        <Grid item>
          <ViewTopics topicNames={dataFull.topicNames} />
        </Grid>
      ) : undefined}
      {dataBasic.isRequest ? (
        <Grid item>
          <Alert severity="info">
            User &apos;{dataBasic.ownerEdge.user.username}
            &apos; is requesting a dataset following this template. If you can get the
            data, click &apos;Extend&apos; below!
          </Alert>
        </Grid>
      ) : undefined}
      <Grid item>
        <CsvToHtmlTable.CsvToHtmlTable data={dataBasic.snippet} csvDelimiter="," />
      </Grid>
      {!dataBasic.isRequest ? (
        <Grid item>
          <Typography variant="caption">
            Total: {dataBasic.numRows} rows ({dataBasic.fileSizeBytes / 1000000} MB)
          </Typography>
        </Grid>
      ) : undefined}
      {!dataBasic.isRequest ? (
        <Grid item hidden={!downloadUrl}>
          <Link href={downloadUrl} color="primary">
            Download Full Data
          </Link>
        </Grid>
      ) : undefined}
      {dataBasic.notes ? (
        <Grid item>
          <Typography>Notes</Typography>
          {dataBasic.notes}
        </Grid>
      ) : undefined}
      {dataBasic.references?.length ? (
        <Grid item>
          <Typography variant="subtitle2">References</Typography>&nbsp;
          {dataBasic.references[0].link ? (
            <Link
              href={dataBasic.references[0].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {dataBasic.references[0].name}
            </Link>
          ) : (
            dataBasic.references[0].name
          )}
        </Grid>
      ) : undefined}
      <Grid item>
        <UserButton
          ownerUsername={dataBasic.ownerEdge.user.username}
          history={history}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          {username && dataFull ? (
            <Grid item>
              <FollowerToggle
                followedID={dataFull.id}
                defaultFollows={dataFull.amIFollower}
                updateFollower={updateDataFollower}
                linkResult={dataFollower?.updateDataFollower}
              />
            </Grid>
          ) : undefined}
          {username === dataBasic.ownerEdge.user.username ? (
            <Grid item>
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => {
                    history.push(`/edit/data/${dataBasic.id}`);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : undefined}
          {username === dataBasic.ownerEdge.user.username ? (
            <Grid item>
              <Tooltip title="Delete data">
                <IconButton onClick={handleClickOpenDeleteDialog}>
                  <Delete />
                </IconButton>
              </Tooltip>
              <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Delete Data</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this data?
                  </DialogContentText>
                </DialogContent>
                {statusGateway({ error: errorDelete, loading: loadingDelete }) || (
                  <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
                      Go Back
                    </Button>
                    <Button onClick={() => onDelete()} color="primary">
                      Delete
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            </Grid>
          ) : undefined}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">QuestTrains</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Each QuestTrain is a train of questions or data leading to this data.
        </Typography>
      </Grid>
      {statusFull ? <Grid item>{statusFull}</Grid> : undefined}
      {dataFull && !statusFull ? (
        <Grid item>
          <QuestTrainList
            questTrains={dataFull.questTrains}
            anchorLocation={AnchorLocation.Right}
            anchorType={dataBasic.isRequest ? AnchorType.DataRequest : AnchorType.Data}
            anchorText={dataBasic.name}
            history={history}
          />
        </Grid>
      ) : undefined}
      <Grid item style={{ width: '100%' }}>
        <ConnectMore
          username={username}
          history={history}
          contributeSlider={contributeSlider}
          setContributeSlider={setContributeSlider}
          trainCarInputs={trainCarInputs}
          defaultTopicNames={dataBasic.topicNames}
          opened={opened}
          setOpened={setOpened}
        />
      </Grid>
    </Grid>
  );
}
