/* eslint-disable no-console */
import React, { ReactNode, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { RouteHistoryProps } from 'RouterProps';
import { LoadingIndicator } from 'Reportable';
import { NotFound } from 'NotFound';
import { ApolloProvider } from '@apollo/client';
import { cognitoClient, iamClient } from 'Clients';

export enum AuthState {
  Pending,
  Authenticated,
  Unauthenticated,
}

export interface AuthenticatedRouteProps {
  setUsername?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIdentityID?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  authState?: AuthState;
  children: React.ReactNode;
  reasonForRedirect?: string;
  // Dummy function that gets called to set the Layout page name
  setPageName: () => void;
}

export function AuthenticatedRoute(props: AuthenticatedRouteProps & RouteHistoryProps) {
  const {
    setUsername,
    setIdentityID,
    setAuthState,
    authState,
    children,
    history,
    setPageName,
    reasonForRedirect,
  } = props;
  const [signInReturnTo, setSignInReturnTo] = useState<string>();

  useEffect(() => {
    async function checkAuth() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('AuthenticatedRoute: Authenticated');
        setAuthState(AuthState.Authenticated);
        if (setUsername && user.username) {
          setUsername(user.username);
        }
        if (setIdentityID && user.attributes?.sub) {
          setIdentityID(user.attributes.sub);
        }
      } catch (err) {
        console.log('AuthenticatedRoute: Unauthenticated');
        setAuthState(AuthState.Unauthenticated);
        if (setUsername) {
          setUsername(undefined);
        }
      }
    }

    setPageName();
    checkAuth();
  }, [setAuthState, setIdentityID, setPageName, setUsername]);

  useEffect(() => {
    if (signInReturnTo) {
      history.push('/signin', {
        returnTo: signInReturnTo,
        reasonForRedirect:
          reasonForRedirect || "We're glad you're here! Let's get you signed in first.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInReturnTo]);

  useEffect(() => {
    if (authState === AuthState.Unauthenticated) {
      const returnTo = history.location.pathname + history.location.search;
      setSignInReturnTo(returnTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  if (authState === AuthState.Unauthenticated) {
    return (
      <LoadingIndicator message="Let's get you signed in so you can view this page. Redirecting you now ..." />
    );
  }

  if (authState === AuthState.Pending) {
    return <LoadingIndicator />;
  }

  if (authState === AuthState.Authenticated) {
    return <div>{children}</div>;
  }

  return <NotFound />;
}

export function PublicOrPrivateRoute(props: AuthenticatedRouteProps) {
  const { setUsername, setIdentityID, setAuthState, children, setPageName } = props;

  useEffect(() => {
    async function checkAuth() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('PublicOrPrivateRoute: Authenticated');
        if (setUsername && user.username) {
          setUsername(user.username);
        }
        if (setIdentityID && user.attributes?.sub) {
          setIdentityID(user.attributes.sub);
        }
        setAuthState(AuthState.Authenticated);
      } catch (err) {
        console.log('PublicOrPrivateRoute: Unauthenticated');

        if (setUsername) {
          setUsername(undefined);
        }
        setAuthState(AuthState.Unauthenticated);
      }
    }

    setPageName();
    checkAuth();
  }, [setAuthState, setIdentityID, setPageName, setUsername]);

  return <div>{children}</div>;
}

export function PublicOnlyRoute(props: AuthenticatedRouteProps & RouteHistoryProps) {
  const { setUsername, children, history, setPageName } = props;

  useEffect(() => {
    async function checkAuth() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('PublicOnlyRoute: Authenticated');
        if (setUsername && user.username) {
          setUsername(user.username);
        }
        history.push('/');
      } catch (err) {
        console.log('PublicOnlyRoute: Unauthenticated');
        if (setUsername) {
          setUsername(undefined);
        }
      }
    }

    setPageName();
    checkAuth();
  }, [history, setPageName, setUsername]);

  return <div>{children}</div>;
}

export interface AuthenticatedProps {
  username?: string;
  setUsername?: React.Dispatch<React.SetStateAction<string | undefined>>;
  identityID?: string;
  setIdentityID?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface AuthSwitchRouteProps {
  authState: AuthState;
  children: ReactNode;
}

export function ClientSwitchRoute(props: AuthSwitchRouteProps) {
  const { authState, children } = props;

  if (authState === AuthState.Pending) {
    return <LoadingIndicator />;
  }

  const client = authState === AuthState.Authenticated ? cognitoClient : iamClient;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
