import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { analyticsCapture } from 'Analytics';
import {
  useGetQuestionBasicsQuery,
  useUpdateQuestionManagedMutation,
} from 'data/types';
import { PUBLIC_ID } from 'function-common/shared/constants';
import { useGeneralStyles } from 'GeneralStyle';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderError, reportNoData, statusGateway } from 'Reportable';
import { RouteHistoryProps } from 'RouterProps';
import { EditTopics } from 'Topics';
import { isQuestion, normalizeQuery } from './EngineRoute';

interface EditQuestionRouteProps {
  identityID?: string;
}

export function EditQuestionRoute(props: EditQuestionRouteProps & RouteHistoryProps) {
  const { identityID, history } = props;
  const { questionID } = useParams<{
    questionID?: string;
  }>();

  const classes = useGeneralStyles();

  useEffect(() => {
    if (questionID) {
      analyticsCapture('Edit Question', { questionID });
    }
  }, [questionID]);

  if (!questionID) {
    return reportNoData('questionID');
  }
  if (!identityID) {
    return renderError(new Error('Not authorized to edit question.'));
  }

  return (
    <div className={classes.centeredWithTopMargin}>
      <EditQuestion identityID={identityID} questionID={questionID} history={history} />
    </div>
  );
}

interface EditQuestionProps {
  identityID: string;
  questionID: string;
}

export function EditQuestion(props: EditQuestionProps & RouteHistoryProps) {
  const { identityID, questionID, history } = props;

  const [text, setText] = useState<string>();
  const [topicNames, setTopicNames] = useState<Array<string>>([]);
  const [authorized, setAuthorized] = useState(false);

  const { data, error, loading } = useGetQuestionBasicsQuery({
    variables: { id: questionID },
  });
  const question = data?.getQuestion;
  const textIsQuestion = isQuestion(text);

  const [
    updateQuestion,
    { data: dataUpdate, error: errorUpdate, loading: loadingUpdate },
  ] = useUpdateQuestionManagedMutation();

  function onClickUpdate() {
    if (text && textIsQuestion) {
      updateQuestion({ variables: { input: { id: questionID, text, topicNames } } });
    }
  }

  useEffect(() => {
    if (question) {
      if (question.owner === identityID && identityID !== PUBLIC_ID) {
        setAuthorized(true);
        setText(question.text);
        setTopicNames(question.topicNames);
      } else {
        setAuthorized(false);
      }
    }
  }, [identityID, question]);

  useEffect(() => {
    if (dataUpdate) {
      history.push(`/question/${questionID}`);
    }
  });

  const status =
    statusGateway({ error, loading }) ||
    statusGateway({ error: errorUpdate, loading: loadingUpdate });

  if (status) {
    return status;
  }

  if (!authorized) {
    return renderError(new Error('Not authorized to edit question.'));
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item>
        <TextField
          label="Question"
          value={text}
          onChange={(event) => setText(normalizeQuery(event.target.value))}
          required
          error={!text}
        />
      </Grid>
      <Grid item>
        <EditTopics topicNames={topicNames} setTopicNames={setTopicNames} />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClickUpdate()}
          disabled={!textIsQuestion}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
}
