import React, { useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { AuthGate, drawerRoutes } from 'Routes';

import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
// import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { Auth } from 'aws-amplify';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { RouteHistoryProps, PageContext } from 'RouterProps';
import Grid from '@material-ui/core/Grid';
import MaterialUILink from '@material-ui/core/Link';
import { MaterialReactRouterLink } from 'MaterialReactRouter';
import { ApolloProvider } from '@apollo/client';
import { NotFound } from 'NotFound';
import { getStage } from 'Stages';
import Tooltip from '@material-ui/core/Tooltip';
import logoWhite from 'assets/QuestTrainLogoWhite.png';
import { LoadingIndicator } from './Reportable';
import {
  AuthenticatedRoute,
  AuthState,
  ClientSwitchRoute,
  PublicOnlyRoute,
  PublicOrPrivateRoute,
} from './RouteAuth';
import { cognitoClient, iamClient } from './Clients';

const STAGE = getStage();

function BasicRoutes(props: PageContext & RouteHistoryProps) {
  const {
    username,
    setUsername,
    identityID,
    setIdentityID,
    setPageName,
    history,
  } = props;

  const [authState, setAuthState] = useState(AuthState.Pending);

  const computedRoutes: Array<JSX.Element> = [];

  drawerRoutes.forEach((prop) => {
    if (prop.auth === AuthGate.PublicOnly) {
      computedRoutes.push(
        <Route path={prop.path} key={prop.path} exact={prop.exact}>
          <ApolloProvider client={iamClient}>
            <PublicOnlyRoute
              setAuthState={setAuthState}
              setUsername={setUsername}
              history={history}
              setPageName={() => setPageName(prop.name)}
            >
              <prop.component history={history} />
            </PublicOnlyRoute>
          </ApolloProvider>
        </Route>
      );
    } else if (prop.auth === AuthGate.PublicWithPrivateOptional) {
      computedRoutes.push(
        <Route path={prop.path} key={prop.path} exact={prop.exact}>
          <PublicOrPrivateRoute
            setUsername={setUsername}
            setIdentityID={setIdentityID}
            setAuthState={setAuthState}
            setPageName={() => setPageName(prop.name)}
          >
            <ClientSwitchRoute authState={authState}>
              <prop.component
                history={history}
                username={username}
                setUsername={setUsername}
                identityID={identityID}
                setIdentityID={setIdentityID}
              />
            </ClientSwitchRoute>
          </PublicOrPrivateRoute>
        </Route>
      );
    } else if (!prop.auth || prop.auth === AuthGate.PrivateOnly) {
      computedRoutes.push(
        <Route path={prop.path} key={prop.path} exact={prop.exact}>
          <ApolloProvider client={cognitoClient}>
            <AuthenticatedRoute
              history={history}
              setUsername={setUsername}
              setIdentityID={setIdentityID}
              setAuthState={setAuthState}
              authState={authState}
              setPageName={() => setPageName(prop.name)}
              reasonForRedirect={prop.reasonForRedirect}
            >
              <prop.component
                history={history}
                username={username}
                identityID={identityID}
              />
            </AuthenticatedRoute>
          </ApolloProvider>
        </Route>
      );
    }
  });

  return (
    <Switch>
      {computedRoutes}
      <Route>
        <PublicOrPrivateRoute
          setUsername={setUsername}
          setPageName={() => setPageName('Not Found')}
          setAuthState={setAuthState}
        >
          <NotFound />
        </PublicOrPrivateRoute>
      </Route>
    </Switch>
  );
}

const headerHeight = 60;
const footerHeight = 75;
const contactHeight = 40;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      // margin: 0,
      backgroundColor: theme.palette.common.white,
    },
    root: {
      display: 'flex',
      'min-height': `calc(100vh - ${footerHeight + contactHeight}px)`,
    },
    media: {
      width: '100%',
      paddingBottom: '2%',
      clipPath: 'polygon(0 0, 100% 0%, 100% 84%, 0% 100%)',
      backgroundColor: theme.palette.secondary.main,
    },
    appBar: {
      backgroundColor: theme.palette.secondary.main,
      height: headerHeight,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: 240,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      'flex-direction': 'column',
    },
    title: {
      flexGrow: 1,
    },
    home: {
      color: theme.palette.common.white,
    },
    signIn: {
      color: theme.palette.common.white,
    },
    footerLink: {
      color: theme.palette.common.white,
    },
    footer: {
      height: footerHeight,
      backgroundColor: theme.palette.secondary.main,
      position: 'sticky',
    },
    contact: {
      textAlign: 'center',
      alignItems: 'center',
      justify: 'center',
      height: contactHeight,
    },
    footerLeft: {
      dislay: 'flex',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    footerRight: {
      dislay: 'flex',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    footerContainer: {
      paddingTop: '10px',
      [theme.breakpoints.up('sm')]: {
        paddingRight: '50px',
        paddingLeft: '50px',
      },
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  return (
    <AppBar position="static" color="primary" className={classes.footer} elevation={0}>
      <Toolbar>
        <Grid
          container
          spacing={1}
          justify="center"
          alignContent="center"
          alignItems="center"
          className={classes.footerContainer}
        >
          <Grid item xs={12} sm={6} className={classes.footerLeft}>
            <MaterialUILink
              href="https://blog.questtrain.com"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerLink}
            >
              blog
            </MaterialUILink>{' '}
            |{' '}
            <Link
              to="/community/guidelines"
              className={classes.footerLink}
              style={{ textDecoration: 'none' }}
            >
              community guidelines
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.footerRight}>
            <Typography variant="body1">
              &copy;&nbsp;{new Date().getFullYear()}&nbsp;
              <Link
                to="/"
                className={classes.footerLink}
                style={{ textDecoration: 'none' }}
              >
                {STAGE}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

interface MenuAppBarProps {
  // handleDrawerToggle: () => void;
  pageName?: string;
  username?: string;
  setSignOutLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MenuAppBar(props: MenuAppBarProps & RouteHistoryProps) {
  const { pageName, username, setSignOutLoading, history } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSignIn() {
    const returnTo = history.location.pathname + history.location.search;
    history.push('/signin', { returnTo });
  }

  async function handleSignOut() {
    setSignOutLoading(true);
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      // pass
    }
    setSignOutLoading(false);
  }

  const signedInNav = (
    <div>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        endIcon={<AccountCircle />}
      >
        {username}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(`/my/profile`)}>My Profile</MenuItem>
        <Divider />
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </div>
  );

  const notSignedInNav = (
    <div>
      <Tooltip title="Sign in or sign up">
        <Button className={classes.signIn} onClick={handleSignIn}>
          Sign In
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <Tooltip title="Go to homepage">
            <IconButton onClick={() => history.push('/')}>
              <img src={logoWhite} alt="logoWhite" style={{ height: 24 }} />
            </IconButton>
          </Tooltip>
          <Typography>{pageName}</Typography>
          <div className={classes.title} />
          <Tooltip title="Send us feedback!">
            <Button className={classes.home} onClick={() => history.push('/contact')}>
              Feedback?
            </Button>
          </Tooltip>
          {username ? signedInNav : notSignedInNav}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export function Layout(props: RouteHistoryProps) {
  const { history } = props;
  const classes = useStyles();
  // const theme = useTheme();
  // const [mobileOpen, setMobileOpen] = useState(false);
  const [pageName, setPageName] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [identityID, setIdentityID] = useState<string>();
  const [signOutLoading, setSignOutLoading] = useState(false);

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  // const routeDrawer = (
  //   <div>
  //     <div className={classes.toolbar} />
  //     <Divider />
  //     <List>
  //       {drawerRoutes.map((args) =>
  //         args.drawer ? (
  //           <NavLink
  //             to={args.path}
  //             style={{ textDecoration: 'none', color: 'inherit' }}
  //             key={args.path}
  //             onClick={() => setMobileOpen(false)}
  //           >
  //             <ListItem button key={args.path}>
  //               <ListItemText primary={args.name} />
  //             </ListItem>
  //           </NavLink>
  //         ) : undefined
  //       )}
  //     </List>
  //   </div>
  // );

  return (
    <div className={classes.base}>
      <div className={classes.root}>
        <CssBaseline />
        <MenuAppBar
          history={history}
          // handleDrawerToggle={handleDrawerToggle}
          pageName={pageName}
          username={username}
          setSignOutLoading={setSignOutLoading}
        />
        {/* <nav aria-label="actions">
        // The implementation can be swapped with js to avoid
        // SEO duplication of links.
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {routeDrawer}
          </Drawer>
        </nav> */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {signOutLoading ? (
            <LoadingIndicator message="Signing out ..." />
          ) : (
            <BasicRoutes
              setPageName={setPageName}
              history={history}
              username={username}
              setUsername={setUsername}
              identityID={identityID}
              setIdentityID={setIdentityID}
            />
          )}
        </main>
      </div>
      <footer className={classes.contact}>
        Feel free to{' '}
        <MaterialReactRouterLink to="/contact">contact us</MaterialReactRouterLink>!
      </footer>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
