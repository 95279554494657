import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {
  useCreateQuestTrainManagedMutation,
  CreateQuestTrainManagedMutationVariables,
  CarType,
  useGetQuestTrainManagedQuery,
  CarSpec,
  SearchQuestionResultQuestTrainQuestion,
  useIncrementQuestionViewsMutation,
  useIncrementQuestTrainViewsMutation,
  useDeleteQuestionMutation,
  useUpdateQuestionFollowerMutation,
  TrainCarInput,
  useProcessTrainCarsMutation,
  useGetQuestionManagedWrapperQuery,
  SearchQuestionResult,
  useGetQuestionBasicsQuery,
  TrainCarResult,
} from 'data/types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderError, reportNoData, statusGateway } from 'Reportable';
import { RouteHistoryProps } from 'RouterProps';
import { useGeneralStyles } from 'GeneralStyle';
import CardActionArea from '@material-ui/core/CardActionArea';
import Tooltip from '@material-ui/core/Tooltip';
import { Cache } from 'aws-amplify';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Badge from '@material-ui/core/Badge';
import RotateLeft from '@material-ui/icons/RotateLeft';
import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/Save';
import Share from '@material-ui/icons/Share';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import LinkIcon from '@material-ui/icons/Link';
import { ContributeSlider, Engine, EngineSlider } from 'Routes/EngineRoute';
import logoGreen from 'assets/QuestTrainLogoGreen.png';
import { analyticsCapture } from 'Analytics';
import { UserButton } from 'UserButton';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FollowerToggle } from 'Follower';
import Typography from '@material-ui/core/Typography';
import { AnchorLocation, AnchorType, QuestTrainList } from 'QuestTrain';
import { ViewTopics } from 'Topics';
import Edit from '@material-ui/icons/Edit';

export interface QuestionCardProps {
  question: SearchQuestionResult;
  trainCarInputs: Array<TrainCarInput>;
}

export function QuestionCard(props: QuestionCardProps & RouteHistoryProps) {
  const { question, trainCarInputs, history } = props;

  const [processTrainCars] = useProcessTrainCarsMutation();

  function onSelectWrapper() {
    function onSelect() {
      if (trainCarInputs.length) {
        processTrainCars({ variables: { input: { trainCarInputs } } });
      }
      history.push(`/question/${question.id}`);
    }

    return onSelect;
  }

  return (
    <Card key={question.id} variant="outlined">
      <Tooltip title="Explore question" arrow placement="top">
        <CardActionArea onClick={onSelectWrapper()}>
          <CardContent>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <img src={logoGreen} alt="logoGreen" style={{ height: 20 }} />
              </Grid>
              <Grid item>
                <RichQuestion question={question} history={history} />
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Tooltip>
    </Card>
  );
}

interface RichQuestionProps {
  question: SearchQuestionResult;
}

export function RichQuestion(props: RichQuestionProps & RouteHistoryProps) {
  const { question } = props;

  return <Typography>{question.text}</Typography>;
}

interface QuestTrainViewProps {
  id?: string;
  cars: Array<SearchQuestionResultQuestTrainQuestion>;
  carSpecs?: Array<CarSpec | null>;
  defaultSummary?: boolean;
}

export function QuestTrainView(props: QuestTrainViewProps & RouteHistoryProps) {
  const { id, cars, carSpecs, defaultSummary = false, history } = props;

  const [summary, setSummary] = useState(defaultSummary);

  const [sortedCars, setSortedCars] = useState(cars);

  useEffect(() => {
    if (cars) {
      if (carSpecs) {
        const previousQuestionIDs = cars.map((question) => question?.id);
        const carsTempSorted: Array<SearchQuestionResultQuestTrainQuestion> = [];
        carSpecs.forEach((spec) => {
          if (spec && previousQuestionIDs) {
            const index = previousQuestionIDs.indexOf(spec.id);
            if (index > -1) {
              carsTempSorted.push(cars[index]);
            }
          }
        });

        if (carsTempSorted) {
          setSortedCars(carsTempSorted);
        }
      } else {
        setSortedCars(cars);
      }
    }
  }, [carSpecs, cars]);

  function onViewQuestTrain() {
    if (id) {
      history.push(`/questtrain/${id}`);
    }
  }

  let usedCars = [...sortedCars];

  if (summary) {
    usedCars = [sortedCars[0], sortedCars[sortedCars.length - 1]];
  }

  const items: Array<JSX.Element> = [];

  usedCars.forEach((question, index) => {
    if (question) {
      items.push(
        <Grid item key={`car-${question.id}`}>
          <QuestionCard
            question={question}
            key={question.id}
            history={history}
            trainCarInputs={[]}
          />
        </Grid>
      );

      if (index < usedCars.length - 1) {
        items.push(
          <Grid item>
            <Tooltip
              title={
                summary ? 'Expand all questions' : 'Collapse intermediate questions'
              }
              arrow
              placement="top"
            >
              {summary ? (
                <Badge badgeContent={sortedCars.length} color="primary">
                  <IconButton onClick={() => setSummary(!summary)}>
                    <MoreHoriz />
                  </IconButton>
                </Badge>
              ) : (
                <IconButton onClick={() => setSummary(!summary)}>
                  <LinkIcon />
                </IconButton>
              )}
            </Tooltip>
          </Grid>
        );
      }
    }
  });

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {items}
      <Grid item>
        <Tooltip title="Explore QuestTrain" arrow placement="top">
          <IconButton disabled={!id} onClick={() => onViewQuestTrain()}>
            <img src={logoGreen} alt="logoGreen" style={{ height: 32 }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

interface QuestionHistoryProps {
  defaultHistoryEnabled?: boolean;
}

export function QuestionHistory(props: QuestionHistoryProps & RouteHistoryProps) {
  const { defaultHistoryEnabled = false, history } = props;

  const [historyEnabled, setHistoryEnabled] = useState(defaultHistoryEnabled);
  const [shareView, setShareView] = useState(false);

  const [createQuestTrain, { error, loading }] = useCreateQuestTrainManagedMutation();

  const [
    previousQuestions,
    setPreviousQuestions,
  ] = useState<Array<SearchQuestionResultQuestTrainQuestion> | null>([]);

  const [questTrainID, setQuestTrainID] = useState<string>();

  useEffect(() => {
    setPreviousQuestions(Cache.getItem('previousQuestions'));
    setQuestTrainID(Cache.getItem('questTrainID'));
  }, []);

  function onSaveQuestTrain() {
    if (previousQuestions) {
      const createQuestTrainManagedInput: CreateQuestTrainManagedMutationVariables = {
        carSpecs: previousQuestions.map((car) => ({
          id: car.id,
          carType: CarType.QuestionType,
        })),
      };
      createQuestTrain({ variables: createQuestTrainManagedInput });
    }
  }

  function onResetQuestTrain() {
    Cache.clear();
    setPreviousQuestions([]);
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      alignContent="center"
      justify="center"
    >
      <Grid item>
        <Badge badgeContent={previousQuestions?.length} color="primary">
          <Tooltip title="See your current QuestTrain" arrow placement="top">
            <ToggleButton
              selected={historyEnabled}
              onChange={() => {
                setHistoryEnabled(!historyEnabled);
              }}
            >
              <img src={logoGreen} alt="logoGreen" style={{ height: 32 }} />
            </ToggleButton>
          </Tooltip>
        </Badge>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item hidden={!historyEnabled}>
            <Tooltip title="Start a new QuestTrain" arrow placement="top">
              <IconButton onClick={() => onResetQuestTrain()}>
                <RotateLeft />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item hidden={!historyEnabled}>
            {statusGateway({ error, loading }) || (
              <Tooltip title="Save this QuestTrain" arrow placement="top">
                <IconButton onClick={() => onSaveQuestTrain()}>
                  <Save />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          {questTrainID ? (
            <Grid item hidden={!historyEnabled}>
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  <Tooltip
                    title="Share a link to this QuestTrain"
                    arrow
                    placement="top"
                  >
                    <ToggleButton
                      selected={shareView}
                      onClick={() => {
                        setShareView(!shareView);
                      }}
                      style={{ border: 0 }}
                    >
                      <Share />
                    </ToggleButton>
                  </Tooltip>
                </Grid>
                {shareView ? (
                  <Grid item>https://questtrain.com/questtrain/{questTrainID}</Grid>
                ) : undefined}
              </Grid>
            </Grid>
          ) : undefined}
        </Grid>
      </Grid>
      <Grid item hidden={!historyEnabled} style={{ width: '100%' }}>
        {previousQuestions ? (
          <QuestTrainView cars={previousQuestions} history={history} defaultSummary />
        ) : undefined}
      </Grid>
    </Grid>
  );
}

export function MainQuestion(props: QuestionCardProps & RouteHistoryProps) {
  const { question, history } = props;

  return <RichQuestion question={question} history={history} />;
}

interface ViewQuestionRouteProps {
  username?: string | null;
}

export function ViewQuestionRoute(props: ViewQuestionRouteProps & RouteHistoryProps) {
  const { username, history } = props;
  const { questionID } = useParams<{
    questionID?: string;
  }>();

  const [incrementQuestionViews] = useIncrementQuestionViewsMutation();

  useEffect(() => {
    if (questionID) {
      analyticsCapture('View Question', { questionID });
      incrementQuestionViews({ variables: { id: questionID } });
    }
  }, [incrementQuestionViews, questionID]);

  if (!questionID) return reportNoData('questionID');

  return (
    <ViewQuestion
      username={username || undefined}
      questionID={questionID}
      history={history}
    />
  );
}

interface ViewQuestionNeighborsProps {
  questions?: Array<SearchQuestionResult>;
  trainCarInputs: Array<TrainCarInput>;
}

export function ViewQuestionNeighbors(
  props: ViewQuestionNeighborsProps & RouteHistoryProps
) {
  const { questions, trainCarInputs, history } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item>Connected questions</Grid>
      {questions?.map((question) =>
        question ? (
          <Grid item key={question.id}>
            <QuestionCard
              key={question.id}
              question={question}
              trainCarInputs={trainCarInputs}
              history={history}
            />
          </Grid>
        ) : undefined
      )}
    </Grid>
  );
}

interface ConnectMoreProps {
  username?: string;
  trainCarInputs: Array<TrainCarInput>;
  defaultTopicNames: Array<string>;
  anchorQuery?: string;
  view?: EngineSlider;
  contributeSlider: ContributeSlider;
  setContributeSlider: React.Dispatch<React.SetStateAction<ContributeSlider>>;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ConnectMore(props: ConnectMoreProps & RouteHistoryProps) {
  const {
    username,
    trainCarInputs,
    defaultTopicNames,
    anchorQuery,
    view,
    contributeSlider,
    setContributeSlider,
    opened,
    setOpened,
    history,
  } = props;

  const [internalOpened, setInternalOpened] = useState(opened || !!view);

  const resolvedOpened = opened || internalOpened;
  const resolvedSetOpened = setOpened || setInternalOpened;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item>
        <Typography variant="subtitle2">
          Extend QuestTrains by connecting questions, requests, and data.
        </Typography>
      </Grid>
      {!resolvedOpened ? (
        <Grid item>
          <Tooltip title="Connect more questions and data">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                resolvedSetOpened(true);
              }}
            >
              Extend
            </Button>
          </Tooltip>
        </Grid>
      ) : undefined}
      <Grid item hidden={!resolvedOpened} style={{ width: '100%' }}>
        <Engine
          username={username}
          history={history}
          anchorQuery={anchorQuery}
          trainCarInputs={trainCarInputs}
          defaultTopicNames={defaultTopicNames}
          defaultSlider={view || EngineSlider.Add}
          contributeSlider={contributeSlider}
          setContributeSlider={setContributeSlider}
          contributePlaceholder="Did this spark a related question?"
          searchPlaceholder="Search for related questions ..."
        />
      </Grid>
    </Grid>
  );
}

export interface ViewQuestionProps {
  questionID: string;
  username?: string;
}

export function ViewQuestion(props: ViewQuestionProps & RouteHistoryProps) {
  const { questionID, username, history } = props;

  const classes = useGeneralStyles();
  const [contributeSlider, setContributeSlider] = useState(ContributeSlider.Question);
  const [opened, setOpened] = useState(false);

  const {
    data: dataFull,
    error: errorFull,
    loading: loadingFull,
  } = useGetQuestionManagedWrapperQuery({
    variables: {
      id: questionID,
    },
  });

  const {
    data: dataBasic,
    error: errorBasic,
    loading: loadingBasic,
  } = useGetQuestionBasicsQuery({
    variables: {
      id: questionID,
    },
  });

  const questionFull = dataFull?.getQuestionManaged;
  const questionBasic = dataBasic?.getQuestion;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const [
    deleteQuestion,
    { data: dataDelete, error: errorDelete, loading: loadingDelete },
  ] = useDeleteQuestionMutation();

  const [
    updateQuestionFollower,
    { data: dataFollower },
  ] = useUpdateQuestionFollowerMutation();

  useEffect(() => {
    if (dataDelete?.deleteQuestion) {
      history.push('/');
    }
  });

  function onDelete() {
    deleteQuestion({ variables: { id: questionID } });
  }

  const statusBasic = statusGateway({ error: errorBasic, loading: loadingBasic });
  if (statusBasic) {
    return statusBasic;
  }

  if (!questionBasic?.text) {
    return reportNoData('question');
  }

  const statusFull = statusGateway({ error: errorFull, loading: loadingFull });

  const trainCarInputs: Array<TrainCarInput> = [
    { carType: CarType.QuestionType, useExisting: { id: questionBasic.id } },
  ];

  const danglingTrains = questionFull?.neighborQuestions?.map((question) => {
    const danglingTrain: TrainCarResult = {
      id: question.id,
      carType: CarType.QuestionType,
      question,
    };
    return { trainCars: [danglingTrain] };
  });
  const mergedTrains = questionFull?.questTrains.concat(danglingTrains || []);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.centeredWithTopMargin}
    >
      <Grid item style={{ width: '100%' }}>
        <MainQuestion
          key={questionBasic.id}
          question={{
            ...questionBasic,
            ownerUsername: questionBasic.ownerEdge.user.username,
          }}
          trainCarInputs={trainCarInputs}
          history={history}
        />
      </Grid>
      {questionFull?.topicNames ? (
        <Grid item>
          <ViewTopics topicNames={questionFull.topicNames} />
        </Grid>
      ) : undefined}
      <Grid item>
        <UserButton
          ownerUsername={questionBasic.ownerEdge.user.username}
          history={history}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          {username && questionFull ? (
            <Grid item>
              <FollowerToggle
                followedID={questionFull.id}
                defaultFollows={questionFull.amIFollower}
                updateFollower={updateQuestionFollower}
                linkResult={dataFollower?.updateQuestionFollower}
              />
            </Grid>
          ) : undefined}
          {username === questionBasic.ownerEdge.user.username ? (
            <Grid item>
              <Tooltip title="Edit question">
                <IconButton
                  onClick={() => {
                    history.push(`/edit/question/${questionID}`);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : undefined}
          {username === questionBasic.ownerEdge.user.username ? (
            <Grid item>
              <Tooltip title="Delete question">
                <IconButton onClick={handleClickOpenDeleteDialog}>
                  <Delete />
                </IconButton>
              </Tooltip>
              <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Delete Question</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this question?
                  </DialogContentText>
                </DialogContent>
                {statusGateway({ error: errorDelete, loading: loadingDelete }) || (
                  <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
                      Go Back
                    </Button>
                    <Button onClick={() => onDelete()} color="primary">
                      Delete
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            </Grid>
          ) : undefined}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">QuestTrains</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Each QuestTrain is a train of questions leading to data or a request.
        </Typography>
      </Grid>
      {statusFull ? <Grid item>{statusFull}</Grid> : undefined}
      {questionFull && mergedTrains ? (
        <Grid item>
          <QuestTrainList
            questTrains={mergedTrains}
            anchorLocation={AnchorLocation.Left}
            anchorType={AnchorType.Question}
            anchorText={questionFull.text}
            history={history}
          />
        </Grid>
      ) : undefined}
      <Grid item style={{ width: '100%' }}>
        <ConnectMore
          username={username}
          history={history}
          contributeSlider={contributeSlider}
          setContributeSlider={setContributeSlider}
          trainCarInputs={trainCarInputs}
          defaultTopicNames={questionBasic.topicNames}
          opened={opened}
          setOpened={setOpened}
        />
      </Grid>
      <Grid item>
        <br />
      </Grid>
    </Grid>
  );
}

export function ViewQuestTrainRoute(props: RouteHistoryProps) {
  const { history } = props;
  const { questTrainID } = useParams<{
    questTrainID?: string;
  }>();

  if (!questTrainID) return reportNoData('questTrainID');

  return <ViewQuestTrain questTrainID={questTrainID} history={history} />;
}

interface ViewQuestTrainProps {
  questTrainID: string;
}

export function ViewQuestTrain(props: ViewQuestTrainProps & RouteHistoryProps) {
  const { questTrainID, history } = props;

  const { data, error, loading } = useGetQuestTrainManagedQuery({
    variables: { id: questTrainID },
  });

  const questTrain = data?.getQuestTrain?.questions?.items;
  const carSpecs = data?.getQuestTrain?.carSpecs;

  const [incrementQuestTrainViews] = useIncrementQuestTrainViewsMutation();

  useEffect(() => {
    analyticsCapture('View QuestTrain', { questTrainID });
    incrementQuestTrainViews({ variables: { id: questTrainID } });
  }, [incrementQuestTrainViews, questTrainID]);

  useEffect(() => {
    if (questTrain && carSpecs) {
      const previousQuestionIDs = questTrain.map((edge) => edge?.question.id);
      const previousQuestionsSorted = carSpecs.map((spec) => {
        if (spec) {
          return questTrain[previousQuestionIDs.indexOf(spec.id)]?.question;
        }
        return undefined;
      });
      Cache.clear();
      Cache.setItem('previousQuestions', previousQuestionsSorted);
      const index = questTrain.length - 1;
      Cache.setItem('previousQuestionsIndex', index);
      Cache.setItem('questTrainID', questTrainID);
    }
  }, [questTrain, questTrainID, carSpecs]);

  const status = statusGateway({ error, loading });
  if (status) {
    return status;
  }

  if (!questTrain) {
    return renderError(new Error(`Could not find QuestTrainID '${questTrainID}'`));
  }

  const questionID = questTrain[questTrain.length - 1]?.questionID;

  if (!questionID) {
    return renderError(new Error(`Could not find QuestionID'${questionID}'`));
  }

  return <ViewQuestion questionID={questionID} history={history} />;
}
