import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from 'aws-exports';
import { AUTH_TYPE, createAppSyncLink } from 'aws-appsync';

Amplify.configure(awsExports);

const iamHttpLink = createHttpLink({
  uri: awsExports.aws_appsync_graphqlEndpoint,
});

const cognitoHttpLink = createHttpLink({
  uri: awsExports.aws_appsync_graphqlEndpoint,
});

const awsCognitoLink = createAppSyncLink({
  url: awsExports.aws_appsync_graphqlEndpoint,
  region: awsExports.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
});

const awsIamLink = createAppSyncLink({
  url: awsExports.aws_appsync_graphqlEndpoint,
  region: awsExports.aws_appsync_region,
  auth: {
    type: 'AWS_IAM',
    credentials: () => Auth.currentCredentials(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
});

export const cognitoClient = new ApolloClient({
  // @ts-ignore
  link: awsCognitoLink.concat(cognitoHttpLink),
  cache: new InMemoryCache(),
});

export const iamClient = new ApolloClient({
  // @ts-ignore
  link: awsIamLink.concat(iamHttpLink),
  cache: new InMemoryCache(),
});
