import React from 'react';
import Link, { LinkProps } from '@material-ui/core/Link';
import { Link as ReactRouterLink } from 'react-router-dom';

export interface ReactRouterLinkProps {
  to: string;
}

export function MaterialReactRouterLink(props: LinkProps & ReactRouterLinkProps) {
  return <Link component={ReactRouterLink} {...props} />;
}
