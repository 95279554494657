import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import ChipInput from 'material-ui-chip-input';
import React from 'react';

interface EditTopicsProps {
  topicNames: Array<string>;
  setTopicNames: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export function EditTopics(props: EditTopicsProps) {
  const { topicNames, setTopicNames } = props;

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justify="center"
      alignItems="flex-end"
      alignContent="center"
    >
      <Grid item>
        <ChipInput
          defaultValue={topicNames}
          onChange={(chips: Array<string>) => {
            setTopicNames(chips.map((chip) => chip.trim().toLowerCase()));
          }}
          onDelete={(chip, index) => {
            const newTopicNames = topicNames.slice();
            newTopicNames?.splice(index, 1);
            setTopicNames(newTopicNames);
          }}
          placeholder="Hit ENTER after each tag"
          label="Tag with up to 5 topics"
        />
      </Grid>
      <Grid item>
        <Tooltip
          title="Tag with up to 5 short topic names. Hit ENTER to create each tag."
          placement="right"
        >
          <Info color="secondary" />
        </Tooltip>
      </Grid>
    </Grid>
  );
}

interface ViewTopicsProps {
  topicNames: Array<string>;
}

export function ViewTopics(props: ViewTopicsProps) {
  const { topicNames } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {topicNames.map((topicName) => (
        <Grid item id={topicName}>
          <Chip label={topicName} color="primary" />
        </Grid>
      ))}
    </Grid>
  );
}
