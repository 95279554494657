import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { analyticsCapture } from 'Analytics';
import {
  GetDataManagedResult,
  useGetDataManagedWrapperQuery,
  useUpdateDataMetadataManagedMutation,
} from 'data/types';
import { PUBLIC_ID } from 'function-common/shared/constants';
import { useGeneralStyles } from 'GeneralStyle';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderError, reportNoData, statusGateway } from 'Reportable';
import { RouteHistoryProps } from 'RouterProps';
import { EditTopics } from 'Topics';
import { AddData, AddDataSlider } from './AddDataRoute';

interface EditDataRouteProps {
  identityID?: string;
}

export function EditDataRoute(props: EditDataRouteProps & RouteHistoryProps) {
  const { identityID, history } = props;
  const { dataID } = useParams<{
    dataID?: string;
  }>();

  const classes = useGeneralStyles();

  useEffect(() => {
    if (dataID) {
      analyticsCapture('Edit Data', { dataID });
    }
  }, [dataID]);

  if (!dataID) {
    return reportNoData('dataID');
  }
  if (!identityID) {
    return renderError(new Error('Not authorized to edit data.'));
  }

  return (
    <div className={classes.centeredWithTopMargin}>
      <EditData identityID={identityID} dataID={dataID} history={history} />
    </div>
  );
}

interface EditDataProps {
  identityID: string;
  dataID: string;
}

enum EditDataSlider {
  Metadata = 'Metadata',
  CoreDataset = 'Core Dataset',
}

export function EditData(props: EditDataProps & RouteHistoryProps) {
  const { identityID, dataID, history } = props;

  const [authorized, setAuthorized] = useState(false);
  const [slider, setSlider] = useState<EditDataSlider>(EditDataSlider.Metadata);

  const { data: rawData, error, loading } = useGetDataManagedWrapperQuery({
    variables: { id: dataID },
  });
  const data = rawData?.getDataManaged;

  useEffect(() => {
    if (data) {
      if (data.owner === identityID && identityID !== PUBLIC_ID) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    }
  }, [identityID, data]);

  const status = statusGateway({ error, loading });

  if (status) {
    return status;
  }

  if (!authorized) {
    return renderError(new Error('Not authorized to edit data.'));
  }

  if (!data) {
    return reportNoData('data');
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="subtitle2">
          Would you like to update the metadata or core dataset for &apos;{data.name}
          &apos;?
        </Typography>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <ToggleButtonGroup
          value={slider}
          exclusive
          onChange={(event, value) => {
            if (value !== null) {
              setSlider(value);
            }
          }}
        >
          <ToggleButton value={EditDataSlider.Metadata}>
            <Tooltip title="Update metadata">
              <div>{EditDataSlider.Metadata}</div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={EditDataSlider.CoreDataset}>
            <Tooltip title="Update the core data or template">
              <div>{EditDataSlider.CoreDataset}</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item hidden={slider !== EditDataSlider.Metadata}>
        <EditMetadata data={data} identityID={identityID} history={history} />
      </Grid>
      <Grid item hidden={slider !== EditDataSlider.CoreDataset}>
        <AddData
          trainCarInputs={[]}
          preloadedData={data}
          defaultTopicNames={[]}
          defaultSlider={
            data.isRequest ? AddDataSlider.Request : AddDataSlider.HaveFile
          }
          history={history}
        />
      </Grid>
    </Grid>
  );
}

interface EditMetadataProps {
  identityID: string;
  data: GetDataManagedResult;
}

export function EditMetadata(props: EditMetadataProps & RouteHistoryProps) {
  const { identityID, data, history } = props;

  const [name, setName] = useState(data.name);
  const [topicNames, setTopicNames] = useState(data.topicNames);
  const [referenceName, setReferenceName] = useState(
    data.references?.length ? data.references[0].name : undefined
  );
  const [referenceLink, setReferenceLink] = useState(
    data.references?.length ? data.references[0].link : undefined
  );
  const [notes, setNotes] = useState(data.notes || '');

  const [authorized, setAuthorized] = useState(false);

  const [
    updateData,
    { data: dataUpdate, error: errorUpdate, loading: loadingUpdate },
  ] = useUpdateDataMetadataManagedMutation();

  function onClickUpdate() {
    if (name) {
      updateData({
        variables: {
          input: {
            id: data.id,
            name,
            topicNames,
            notes,
            references: referenceName
              ? [{ name: referenceName, link: referenceLink }]
              : [],
          },
        },
      });
    }
  }

  useEffect(() => {
    if (data) {
      if (data.owner === identityID && identityID !== PUBLIC_ID) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    }
  }, [identityID, data]);

  useEffect(() => {
    if (dataUpdate) {
      history.push(`/data/${data.id}`);
    }
  });

  const status = statusGateway({ error: errorUpdate, loading: loadingUpdate });

  if (status) {
    return status;
  }

  if (!authorized) {
    return renderError(new Error('Not authorized to edit data.'));
  }

  const readyToSubmit = authorized && !!name;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item>
        <TextField
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
          error={!name}
        />
      </Grid>
      <Grid item>
        <EditTopics topicNames={topicNames} setTopicNames={setTopicNames} />
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="flex-end"
          alignContent="center"
        >
          <Grid item>
            <TextField
              label="Reference Name (Optional)"
              value={referenceName}
              onChange={(event) => {
                setReferenceName(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title="(Optional) Provide a reference name, if you know where to find primary source data."
              placement="right"
            >
              <Info color="secondary" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="flex-end"
          alignContent="center"
        >
          <Grid item>
            <TextField
              label="Reference Link (Optional)"
              value={referenceLink}
              onChange={(event) => {
                setReferenceLink(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title="(Optional) Provide a reference URL, if you know where to find primary source data."
              placement="right"
            >
              <Info color="secondary" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <TextField
              label="Notes (Optional)"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              multiline
              variant="outlined"
              rows={4}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title="(Optional) Any additional notes you'd like to provide on the data?"
              placement="right"
            >
              <Info color="secondary" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClickUpdate()}
          disabled={!readyToSubmit}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
}
