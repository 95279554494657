import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useGeneralStyles } from 'GeneralStyle';
import Link from '@material-ui/core/Link';

export function ContactUsRoute() {
  const classes = useGeneralStyles();

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      className={classes.centeredWithTopMargin}
    >
      <Grid item style={{ width: '70%' }}>
        <Typography>
          QuestTrain is a small startup run by Michael Vogelsong, and is based in
          Seattle, WA. Need to reach us? Having some difficulties? Got a great joke?
          Email us at:
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          <Link href="mailto:hello@questtrain.com">hello@questtrain.com</Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          We appreciate honest, constructive feedback. And I promise we&apos;re
          friendly!
        </Typography>
      </Grid>
    </Grid>
  );
}
