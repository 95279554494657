/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { RouteHistoryProps } from 'RouterProps';
import {
  CarType,
  ElasticSearchIndexEntityType,
  SearchTraditionalIndexManagedQueryVariables,
  SearchQuestionResultQuestTrain,
  TrainCarInput,
  useProcessTrainCarsMutation,
  useSearchVectorIndexManagedLazyQuery,
  useSearchTraditionalIndexManagedLazyQuery,
} from 'data/types';
import { statusGateway } from 'Reportable';
import { useGeneralStyles } from 'GeneralStyle';
import { QuestTrainView } from 'ViewQuestion';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import logoGreen from 'assets/QuestTrainLogoGreen.png';
import { analyticsCapture } from 'Analytics';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Search from '@material-ui/icons/Search';
import Info from '@material-ui/icons/Info';
import FilterList from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityResults, BrowseEngine } from 'BrowseEngine';
import { EditTopics } from 'Topics';
import { AddData, AddDataFile, AddDataSlider, RequestData } from './AddDataRoute';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      padding: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    padded: {
      padding: '8px',
    },
    centered: {
      textAlign: 'center',
      alignItems: 'center',
      justify: 'center',
    },
    divider: {
      width: '60%',
      backgroundColor: theme.palette.grey['300'],
    },
    responsiveBar: {
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
    },
  })
);

interface ToggleQuestTrainsProps {
  questTrains: Array<SearchQuestionResultQuestTrain>;
}

export function ToggleQuestTrains(props: ToggleQuestTrainsProps & RouteHistoryProps) {
  const { questTrains, history } = props;

  const [showQuestTrains, setShowQuestTrains] = useState(false);

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item>
        <Tooltip
          title="See the QuestTrains referencing this question"
          arrow
          placement="top"
        >
          <Badge badgeContent={questTrains.length} color="primary">
            <ToggleButton
              selected={showQuestTrains}
              onChange={() => {
                setShowQuestTrains(!showQuestTrains);
              }}
            >
              <img src={logoGreen} alt="logoGreen" style={{ height: 32 }} />
            </ToggleButton>
          </Badge>
        </Tooltip>
      </Grid>
      {questTrains.map((questTrain) =>
        questTrain && showQuestTrains ? (
          <Grid item key={`questTrain-${questTrain.id}`}>
            <QuestTrainCard questTrain={questTrain} history={history} />
          </Grid>
        ) : undefined
      )}
    </Grid>
  );
}

export function normalizeQuery(rawText?: string | null) {
  if (!rawText) {
    return undefined;
  }
  const trimmed = rawText.trim();
  const capitalized = trimmed.charAt(0)?.toUpperCase() + trimmed.slice(1);
  return capitalized;
}

export function isQuestion(text?: string | null) {
  return text?.slice(-1) === '?';
}

interface QuestTrainCardProps {
  questTrain: SearchQuestionResultQuestTrain;
}

function QuestTrainCard(props: QuestTrainCardProps & RouteHistoryProps) {
  const { questTrain, history } = props;

  return (
    <Card elevation={2}>
      <CardContent>
        <QuestTrainView
          id={questTrain.id}
          cars={questTrain.questions}
          carSpecs={questTrain.carSpecs || undefined}
          history={history}
          defaultSummary
        />
      </CardContent>
    </Card>
  );
}

interface EngineRouteProps {
  username?: string;
}

export function EngineRoute(props: EngineRouteProps & RouteHistoryProps) {
  const { username, history } = props;

  const classes = useGeneralStyles();

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      direction="column"
      className={classes.topMargin}
    >
      <Grid item alignItems="center" justify="center" alignContent="center">
        <Engine
          username={username}
          history={history}
          trainCarInputs={[]}
          defaultTopicNames={[]}
        />
      </Grid>
    </Grid>
  );
}

export enum ContributeSlider {
  Question = 'Question',
  Data = 'Data',
}

enum EvaluateQuestionSlider {
  Request = 'Request Data',
  Have = 'I Have Data',
  Find = 'Find Data',
  Related = 'Ask Related Question',
}

interface AddQuestionProps {
  trainCarInputs: Array<TrainCarInput>;
  placeholder?: string;
  username?: string;
  defaultTopicNames: Array<string>;
}

export function AddQuestion(props: AddQuestionProps & RouteHistoryProps) {
  const {
    trainCarInputs,
    placeholder = "What's your question?",
    username,
    defaultTopicNames = [],
    history,
  } = props;

  const classes = useStyles();

  const [text, setText] = useState<string>();
  const [sliderEvaluate, setSliderEvaluate] = useState<EvaluateQuestionSlider>();
  const [resultsRelevant, setResultsRelevant] = useState(true);
  const [modifiedTrainCarInputs, setModifiedTrainCarInputs] = useState(trainCarInputs);
  const [disableOverride, setDisableOverride] = useState(false);
  const [connectMore, setConnectMore] = useState(false);
  const [topicNames, setTopicNames] = useState<Array<string>>(defaultTopicNames);

  const [
    search,
    { data: dataSearch, error: errorSearch, loading: loadingSearch },
  ] = useSearchVectorIndexManagedLazyQuery();

  const [
    processTrainCars,
    { data: dataProcess, error: errorProcess, loading: loadingProcess },
  ] = useProcessTrainCarsMutation();

  useEffect(() => {
    if (dataProcess?.processTrainCars.length) {
      history.push(
        `/question/${
          dataProcess.processTrainCars[dataProcess.processTrainCars.length - 1].id
        }`
      );
    }
  }, [dataProcess, history]);

  useEffect(() => {
    if (dataSearch) {
      if (!dataSearch.searchVectorIndex.results.length) {
        setResultsRelevant(false);
      } else {
        setResultsRelevant(true);
      }
    }
  }, [dataSearch]);

  function onEnter() {
    if (text) {
      if (!textIsQuestion) {
        return;
      }
      onAskQuestion();
    }
  }

  function onEnterKeyUpHandler(
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (event.key === 'Enter') {
      onEnter();
    }
  }

  function onEnterHandler() {
    onEnter();
  }

  const normalized = normalizeQuery(text);
  const textIsQuestion = isQuestion(normalized);

  function onAskQuestion() {
    if (normalized) {
      setDisableOverride(true);
      search({ variables: { limit: 8, query: normalized, scoreThreshold: 0.88 } });
      setModifiedTrainCarInputs([
        ...modifiedTrainCarInputs,
        {
          carType: CarType.QuestionType,
          createNew: { questionInput: { text: normalized, topicNames } },
        },
      ]);
    }
  }

  function onSubmitQuestion() {
    if (allowSubmitQuestion && normalized) {
      processTrainCars({
        variables: {
          input: {
            trainCarInputs: [
              ...trainCarInputs,
              {
                carType: CarType.QuestionType,
                createNew: { questionInput: { text: normalized, topicNames } },
              },
            ],
          },
        },
      });
    }
  }

  const showFollowup =
    normalized?.length && textIsQuestion && dataSearch && !resultsRelevant;

  let isAnchored = false;
  trainCarInputs.forEach((trainCarInput) => {
    if (trainCarInput.carType === CarType.DataType || trainCarInput.useExisting?.id) {
      isAnchored = true;
    }
  });
  const allowSubmitQuestion = showFollowup && isAnchored && !connectMore;

  const searchStatus = statusGateway({ error: errorSearch, loading: loadingSearch });

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item style={{ width: '100%' }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item hidden={textIsQuestion}>
            <Tooltip
              title="You must ask a question (with a '?') to submit."
              placement="left"
            >
              <Info color="primary" />
            </Tooltip>
          </Grid>
          <Grid item className={classes.responsiveBar}>
            <Paper className={classes.paper}>
              <InputBase
                className={classes.input}
                placeholder={placeholder}
                value={text}
                inputProps={{ 'aria-label': 'ask' }}
                onChange={(event) => {
                  setText(event.target.value);
                  setDisableOverride(false);
                }}
                onKeyUp={onEnterKeyUpHandler}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {textIsQuestion ? (
        <Grid item>
          <EditTopics topicNames={topicNames} setTopicNames={setTopicNames} />
        </Grid>
      ) : undefined}
      {!disableOverride ? (
        <Grid item>
          <Tooltip title="Enter your question">
            <Button
              type="submit"
              aria-label="ask"
              onClick={onEnterHandler}
              disabled={!normalized?.length || !textIsQuestion || disableOverride}
              color="primary"
              variant="contained"
            >
              Ask
            </Button>
          </Tooltip>
        </Grid>
      ) : undefined}
      {searchStatus}
      {dataSearch?.searchVectorIndex.results.length && resultsRelevant ? (
        <Grid item>
          <Typography variant="subtitle2">
            Are any of these what you&apos;re looking for?
          </Typography>
        </Grid>
      ) : undefined}
      {dataSearch?.searchVectorIndex.results.length && resultsRelevant ? (
        <Grid item style={{ width: '100%' }}>
          <EntityResults
            entities={dataSearch.searchVectorIndex.results}
            trainCarInputs={modifiedTrainCarInputs}
            history={history}
          />
        </Grid>
      ) : undefined}
      {dataSearch?.searchVectorIndex.results.length && resultsRelevant ? (
        <Grid item style={{ width: '100%' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setResultsRelevant(false)}
          >
            Mine is different
          </Button>
        </Grid>
      ) : undefined}
      {allowSubmitQuestion ? (
        <Grid item style={{ width: '100%' }}>
          {statusGateway({ error: errorProcess, loading: loadingProcess }) || (
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onSubmitQuestion()}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setConnectMore(true);
                  }}
                >
                  Connect More
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : undefined}
      {showFollowup && !allowSubmitQuestion ? (
        <Grid item style={{ width: '100%' }}>
          <Typography variant="subtitle2">
            What data would be helpful to evaluate your question or make a decision?
          </Typography>
        </Grid>
      ) : undefined}
      {showFollowup && !allowSubmitQuestion ? (
        <Grid item style={{ width: '100%' }}>
          <ToggleButtonGroup
            value={sliderEvaluate}
            exclusive
            onChange={(event, value) => {
              if (value !== null) {
                setSliderEvaluate(value);
              }
            }}
          >
            <ToggleButton value={EvaluateQuestionSlider.Request}>
              <Tooltip title="I'll request some data">
                <div>{EvaluateQuestionSlider.Request}</div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={EvaluateQuestionSlider.Have}>
              <Tooltip title="I have a CSV data file">
                <div>{EvaluateQuestionSlider.Have}</div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={EvaluateQuestionSlider.Find}>
              <Tooltip title="I'll try to find data on QuestTrain">
                <div>{EvaluateQuestionSlider.Find}</div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={EvaluateQuestionSlider.Related}>
              <Tooltip title="I'll ask a related question">
                <div>{EvaluateQuestionSlider.Related}</div>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      ) : undefined}
      {showFollowup && sliderEvaluate === EvaluateQuestionSlider.Request ? (
        <Grid item style={{ width: '100%' }}>
          <RequestData
            trainCarInputs={modifiedTrainCarInputs}
            defaultTopicNames={defaultTopicNames}
            history={history}
          />
        </Grid>
      ) : undefined}
      {showFollowup &&
      !allowSubmitQuestion &&
      sliderEvaluate === EvaluateQuestionSlider.Have ? (
        <Grid item style={{ width: '100%' }}>
          <AddDataFile
            trainCarInputs={modifiedTrainCarInputs}
            addDataType={AddDataSlider.HaveFile}
            defaultTopicNames={defaultTopicNames}
            history={history}
          />
        </Grid>
      ) : undefined}
      {showFollowup &&
      !allowSubmitQuestion &&
      sliderEvaluate === EvaluateQuestionSlider.Find ? (
        <Grid item style={{ width: '100%' }}>
          <FindEngine
            history={history}
            trainCarInputs={modifiedTrainCarInputs}
            defaultSlider={FindSlider.Search}
          />
        </Grid>
      ) : undefined}
      {showFollowup &&
      !allowSubmitQuestion &&
      sliderEvaluate === EvaluateQuestionSlider.Related ? (
        <Grid item style={{ width: '100%' }}>
          <AddQuestion
            trainCarInputs={modifiedTrainCarInputs}
            defaultTopicNames={defaultTopicNames}
            history={history}
            username={username}
          />
        </Grid>
      ) : undefined}
    </Grid>
  );
}

interface ContributeProps {
  trainCarInputs: Array<TrainCarInput>;
  slider?: ContributeSlider;
  setSlider?: React.Dispatch<React.SetStateAction<ContributeSlider>>;
  placeholder?: string;
  username?: string;
  defaultTopicNames: Array<string>;
}

export function Contribute(props: ContributeProps & RouteHistoryProps) {
  const {
    trainCarInputs,
    slider,
    setSlider,
    placeholder = "What's your question?",
    username,
    defaultTopicNames,
    history,
  } = props;

  const [internalSlider, setInternalSlider] = useState(
    slider || ContributeSlider.Question
  );
  const resolvedSlider = slider || internalSlider;
  const resolvedSetSlider = setSlider || setInternalSlider;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <ToggleButtonGroup
          value={resolvedSlider}
          exclusive
          onChange={(event, value) => {
            if (value !== null) {
              resolvedSetSlider(value);
            }
          }}
        >
          <ToggleButton value={ContributeSlider.Question}>
            <Tooltip title="Add a question.">
              <div>{ContributeSlider.Question}</div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={ContributeSlider.Data}>
            <Tooltip title="Add some data.">
              <div>{ContributeSlider.Data}</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {resolvedSlider === ContributeSlider.Question ? (
        <Grid item style={{ width: '100%' }}>
          <AddQuestion
            placeholder={placeholder}
            trainCarInputs={trainCarInputs}
            defaultTopicNames={defaultTopicNames}
            history={history}
            username={username}
          />
        </Grid>
      ) : undefined}
      {resolvedSlider === ContributeSlider.Data ? (
        <Grid item style={{ width: '100%' }}>
          <AddData
            trainCarInputs={trainCarInputs}
            defaultTopicNames={defaultTopicNames}
            history={history}
          />
        </Grid>
      ) : undefined}
    </Grid>
  );
}

interface SearchEngineProps {
  anchorQuery?: string | null;
  trainCarInputs: Array<TrainCarInput>;
  placeholder?: string;
}

export function SearchEngine(props: SearchEngineProps & RouteHistoryProps) {
  const {
    anchorQuery,
    trainCarInputs,
    placeholder = 'Search our network of data and questions ...',
    history,
  } = props;

  const [text, setText] = useState(anchorQuery);

  const [filterShow, setFilterShow] = useState(false);
  const [filterChecked, setFilterChecked] = useState({
    [ElasticSearchIndexEntityType.Question]: true,
    [ElasticSearchIndexEntityType.Data]: true,
    [ElasticSearchIndexEntityType.DataRequest]: true,
  });

  const classes = useStyles();

  const [
    search,
    { data, error, loading },
  ] = useSearchTraditionalIndexManagedLazyQuery();

  const from = data?.searchTraditionalIndex.from;

  useEffect(() => {
    if (text && anchorQuery === text) {
      onEnter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorQuery]);

  function onEnter() {
    if (text) {
      const filter: Array<ElasticSearchIndexEntityType> = [];
      Object.entries(filterChecked).forEach((entry: any) => {
        if (entry[1]) {
          filter.push(entry[0]);
        }
      });
      let searchInput: SearchTraditionalIndexManagedQueryVariables = {
        limit: 20,
        query: text,
        filter: {},
        from,
      };
      if (filter.length === 0) {
        return;
      }
      if (filter.length < 3) {
        searchInput = {
          ...searchInput,
          filter: { ...searchInput.filter, entityTypes: filter },
        };
      }
      search({ variables: searchInput });
      analyticsCapture('Entered Search', { query: text });
    }
  }

  function onEnterKeyUpHandler(
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (event.key === 'Enter') {
      onEnter();
    }
  }

  function onEnterHandler() {
    onEnter();
  }

  const results = data?.searchTraditionalIndex.results;

  const normalized = normalizeQuery(text);

  function handleCheck(name: ElasticSearchIndexEntityType, checked: boolean) {
    setFilterChecked({ ...filterChecked, [name]: checked });
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item>
        <Tooltip title="Filter results">
          <ToggleButton
            selected={filterShow}
            onClick={() => setFilterShow(!filterShow)}
          >
            <FilterList />
          </ToggleButton>
        </Tooltip>
      </Grid>
      {filterShow ? (
        <Grid item>
          <FormControl>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterChecked.Question}
                    onChange={(event) =>
                      handleCheck(
                        ElasticSearchIndexEntityType.Question,
                        event.target.checked
                      )
                    }
                    name={ElasticSearchIndexEntityType.Question}
                  />
                }
                label={ElasticSearchIndexEntityType.Question}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterChecked.Data}
                    onChange={(event) =>
                      handleCheck(
                        ElasticSearchIndexEntityType.Data,
                        event.target.checked
                      )
                    }
                    name={ElasticSearchIndexEntityType.Data}
                  />
                }
                label={ElasticSearchIndexEntityType.Data}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterChecked.DataRequest}
                    onChange={(event) =>
                      handleCheck(
                        ElasticSearchIndexEntityType.DataRequest,
                        event.target.checked
                      )
                    }
                    name={ElasticSearchIndexEntityType.DataRequest}
                  />
                }
                label={ElasticSearchIndexEntityType.DataRequest}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      ) : undefined}
      <Grid item style={{ width: '100%' }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item className={classes.responsiveBar}>
            <Paper className={classes.paper}>
              <InputBase
                className={classes.input}
                placeholder={placeholder}
                value={text}
                inputProps={{ 'aria-label': 'ask' }}
                onChange={(event) => {
                  setText(event.target.value);
                }}
                onKeyUp={onEnterKeyUpHandler}
              />
              <Tooltip title="Search">
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="ask"
                  onClick={onEnterHandler}
                  disabled={!normalized?.length}
                >
                  <Search />
                </IconButton>
              </Tooltip>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {statusGateway({ error, loading }) ? (
        <Grid item>{statusGateway({ error, loading })}</Grid>
      ) : results ? (
        results?.length > 0 ? (
          <Grid item style={{ width: '100%' }}>
            <Grid
              container
              spacing={2}
              direction="column"
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <Grid item style={{ width: '100%' }}>
                <EntityResults
                  entities={results}
                  trainCarInputs={trainCarInputs}
                  history={history}
                />
              </Grid>
              <Grid item />
              <Divider className={classes.divider} />
              <Grid item />
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            Awesome, you&apos;re pretty creative! We couldn&apos;t find any related
            questions or data, so looks like you&apos;ve found a new topic to explore.
            Click &apos;Add&apos; to contibute your question or data!
          </Grid>
        )
      ) : undefined}
    </Grid>
  );
}

export enum EngineSlider {
  Find = 'Find',
  Add = 'Add',
}

export enum FindSlider {
  Browse = 'Browse',
  Search = 'Search',
}

interface EngineProps {
  anchorQuery?: string;
  trainCarInputs: Array<TrainCarInput>;
  defaultTopicNames: Array<string>;
  username?: string;
  defaultSlider?: EngineSlider;
  contributeSlider?: ContributeSlider;
  setContributeSlider?: React.Dispatch<React.SetStateAction<ContributeSlider>>;
  searchPlaceholder?: string;
  contributePlaceholder?: string;
}

export function Engine(props: EngineProps & RouteHistoryProps) {
  const {
    anchorQuery,
    trainCarInputs,
    defaultTopicNames,
    username,
    searchPlaceholder,
    contributePlaceholder,
    defaultSlider = EngineSlider.Find,
    contributeSlider,
    setContributeSlider,
    history,
  } = props;

  const [slider, setSlider] = useState(defaultSlider);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
      style={{ textAlign: 'center', width: '100%' }}
    >
      <Grid item>
        <ToggleButtonGroup
          value={slider}
          exclusive
          onChange={(event, value) => {
            if (value !== null) {
              setSlider(value);
            }
          }}
        >
          <ToggleButton value={EngineSlider.Find}>
            <Tooltip title="Find questions, data, and requests">
              <div>{EngineSlider.Find}</div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={EngineSlider.Add}>
            <Tooltip title="Add questions, data, and requests">
              <div>{EngineSlider.Add}</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {slider === EngineSlider.Find ? (
        <Grid item style={{ width: '100%' }}>
          <FindEngine
            searchPlaceholder={searchPlaceholder}
            anchorQuery={anchorQuery}
            trainCarInputs={trainCarInputs}
            history={history}
          />
        </Grid>
      ) : undefined}
      {slider === EngineSlider.Add ? (
        <Grid item style={{ width: '100%' }}>
          <Contribute
            trainCarInputs={trainCarInputs}
            placeholder={contributePlaceholder || "What's your question?"}
            slider={contributeSlider}
            setSlider={setContributeSlider}
            defaultTopicNames={defaultTopicNames}
            history={history}
            username={username}
          />
        </Grid>
      ) : undefined}
    </Grid>
  );
}

interface FindEngineProps {
  searchPlaceholder?: string;
  anchorQuery?: string;
  trainCarInputs: Array<TrainCarInput>;
  defaultSlider?: FindSlider;
}

export function FindEngine(props: FindEngineProps & RouteHistoryProps) {
  const {
    searchPlaceholder,
    anchorQuery,
    trainCarInputs,
    defaultSlider = FindSlider.Browse,
    history,
  } = props;

  const [slider, setSlider] = useState(defaultSlider);
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignContent="center"
      alignItems="center"
      style={{ textAlign: 'center' }}
    >
      <Grid item>
        <ToggleButtonGroup
          value={slider}
          exclusive
          onChange={(event, value) => {
            if (value !== null) {
              setSlider(value);
            }
          }}
        >
          <ToggleButton value={FindSlider.Browse}>
            <Tooltip title="Browse our network">
              <div>{FindSlider.Browse}</div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={FindSlider.Search}>
            <Tooltip title="Search our network">
              <div>{FindSlider.Search}</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        {slider === FindSlider.Browse ? (
          <BrowseEngine trainCarInputs={trainCarInputs} history={history} />
        ) : undefined}
        {slider === FindSlider.Search ? (
          <SearchEngine
            placeholder={searchPlaceholder}
            anchorQuery={anchorQuery}
            trainCarInputs={trainCarInputs}
            history={history}
          />
        ) : undefined}
      </Grid>
    </Grid>
  );
}
