import React from 'react';
import {
  SearchDataResult,
  TrainCarInput,
  useProcessTrainCarsMutation,
} from 'data/types';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { RouteHistoryProps } from 'RouterProps';
import Assignment from '@material-ui/icons/Assignment';
import Storage from '@material-ui/icons/Storage';
import Typography from '@material-ui/core/Typography';

interface DataCardProps {
  data: SearchDataResult;
  trainCarInputs: Array<TrainCarInput>;
}

export function DataCard(props: DataCardProps & RouteHistoryProps) {
  const { data, trainCarInputs, history } = props;

  const [processTrainCars] = useProcessTrainCarsMutation();

  return (
    <Card key={data.id} variant="outlined">
      <Tooltip
        title={`Explore data${data.isRequest ? ' request' : ''}`}
        arrow
        placement="top"
      >
        <CardActionArea
          onClick={() => {
            if (trainCarInputs.length) {
              processTrainCars({ variables: { input: { trainCarInputs } } });
            }
            history.push(`/data/${data.id}`);
          }}
        >
          <CardContent>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                {data.isRequest ? (
                  <Tooltip title="This is a data request">
                    <Assignment color="primary" />
                  </Tooltip>
                ) : (
                  <Tooltip title="This is a data element">
                    <Storage color="primary" />
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Typography>{data.name}</Typography>
              </Grid>
              {data.header.map((column) => (
                <Grid item>
                  <Chip label={column.name} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Tooltip>
    </Card>
  );
}

interface DataCardListProps {
  dataList: Array<SearchDataResult>;
  trainCarInputs: Array<TrainCarInput>;
}

export function DataCardList(props: DataCardListProps & RouteHistoryProps) {
  const { dataList, trainCarInputs, history } = props;
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      {dataList.map((data) => (
        <Grid item>
          <DataCard
            data={data}
            history={history}
            trainCarInputs={trainCarInputs}
            key={data.id}
          />
        </Grid>
      ))}
      {dataList.length === 0 ? 'No data found.' : undefined}
    </Grid>
  );
}
