import awsExports from './aws-exports';

export function getStage() {
  const endpoint = awsExports.aws_appsync_graphqlEndpoint;

  if (
    endpoint ===
    'https://gnaoyta52fehlfldt2pc5vilq4.appsync-api.us-west-2.amazonaws.com/graphql'
  ) {
    return 'QuestTrain';
  }
  if (
    endpoint ===
    'https://afazucw56zbujnkxkpfion2rxu.appsync-api.us-west-2.amazonaws.com/graphql'
  ) {
    return 'QuestTrain (Dev)';
  }

  return 'QuestTrain (Unknown)';
}
