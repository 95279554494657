import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useGeneralStyles } from 'GeneralStyle';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { MaterialReactRouterLink } from 'MaterialReactRouter';

export function CommunityGuidelinesRoute() {
  const classes = useGeneralStyles();

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      className={classes.centeredWithTopMargin}
    >
      <Grid item xs={8}>
        <Typography variant="h5">Community Guidelines</Typography>
      </Grid>
      <Grid item xs={10}>
        We love creativity at QuestTrain. But, we gotta set some guidelines so all users
        feel safe and supported. Most of the time, it&apos;s really quite simple -&nbsp;
        <b>use good judgment, be respectful, and set a good example for others</b>. We
        take inclusion very seriously. In general, we do not support content or actions
        that deceive, exploit, disrespect, or harm others. The following are explicitly
        prohibited:
      </Grid>
      <Grid item xs={8}>
        <List>
          <ListItem>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            Content or activity that is illegal or otherwise prohibited by our Terms of
            Service or Community Guidelines.
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            Content that could be used to harass or disrespect a person or group of
            people.
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            Violent or threatening content or activity.
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            Activity that accesses, saves, or shares content that you are not authorized
            to access, save, or share.
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            Activity that allows a user or external party to access, save, or share
            content that they are not authorized to access, save, or share.
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            Excessive automated bulk activity (for example, spamming).
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ErrorIcon />
            </ListItemIcon>
            Activity that compromises other users or QuestTrain.com services.
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={8}>
        If you have any questions about the guidelines,&nbsp;
        <MaterialReactRouterLink to="/contact">reach out to us</MaterialReactRouterLink>
        .
      </Grid>
    </Grid>
  );
}
